import React from 'react';
import {
  Button,
  Input,
  Form,
} from 'semantic-ui-react';
import PropTypes from 'prop-types';

import PortalApi from '../../../services/PortalApi';
import Utils from '../../../lib/utils';

class DisciplineForm extends React.PureComponent {
  state = {
    ...this.props.discipline,
    isSaving: false,
  }

  handleSaveClick = async () => {
    const bodyParams = {
      name: this.state.name,
    };

    this.setState({
      isSaving: true,
    });

    let discipline;
    try {
      discipline = await this.editDiscipline(bodyParams);
      Utils.toastAdminGeneralSuccess(`Saved ${discipline.name} (id: ${discipline.id})`);
    } catch (error) {
      if (error instanceof PortalApi.ApiError) {
        Utils.toastAdminApiError(error);
      } else {
        Utils.toastAdminGeneralError(error);
      }
    }

    this.setState({
      isSaving: false,
    });
  }

  editDiscipline = async (bodyParams) => {
    const discipline = await PortalApi.admin.saveDiscipline(this.state.id, bodyParams);
    return discipline;
  }

  render() {
    return (
      <Form>
        <Form.Field
          control={Input}
          label="Discipline Name"
          placeholder="Discipline Name"
          value={this.state.name}
          onChange={(_, { value }) => { this.setState({ name: value }); }}
          required
        />
        <Form.Field
          control={Button}
          content="Save"
          loading={this.state.isSaving}
          onClick={this.handleSaveClick}
        />
      </Form>
    );
  }
}

DisciplineForm.DEFAULT_FORM_STATE = {
  id: null,
  name: '',
};

DisciplineForm.propTypes = {
  discipline: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string,
  }),
};

DisciplineForm.defaultProps = {
  discipline: DisciplineForm.DEFAULT_FORM_STATE,
};

export default DisciplineForm;
