import ReactGA from 'react-ga';

import config from '../config';

const { googleAnalytics: gaConfig } = config;
// TODO split environment
ReactGA.initialize(gaConfig.trackingId, {
  debug: gaConfig.debug,
});

export default {
  set: (params) => {
    ReactGA.set(params);
  },
  setUser: (id) => {
    ReactGA.set({ userId: id });
  },
  trackPageView: (pathname) => {
    ReactGA.pageview(pathname);
  },
  ReactGA,
};
