import React from 'react';
import { Button, Modal, Header } from 'semantic-ui-react';
import PropTypes from 'prop-types';

const ErrorModal = ({ title, message, close }) => (
  <Modal open>
    <Header icon="warning" content={title} />
    <Modal.Content>
      <p>
        { message }
      </p>
    </Modal.Content>
    <Modal.Actions>
      <Button color="green" onClick={close}>
        Close
      </Button>
    </Modal.Actions>
  </Modal>
);

ErrorModal.propTypes = {
  message: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  close: PropTypes.func,
};

ErrorModal.defaultProps = {
  close: () => {},
};

export default ErrorModal;
