import React from 'react';
import debounce from 'lodash.debounce';
import { Form, Confirm } from 'semantic-ui-react';
import { toast } from 'react-toastify';

import PortalApi from '../../../services/PortalApi';

class MergeEmployerForm extends React.PureComponent {
  INITIAL_STATE = {
    employerAId: null,
    employerBId: null,

    employerAOptions: [],
    employerBOptions: [],

    isConfirmOpen: false,
  }

  constructor(props) {
    super(props);

    this.searchEmployers = debounce(this.searchEmployers, 300);
    this.state = {
      ...this.INITIAL_STATE,
    };
  }

  searchEmployers = async (optionName, searchQuery) => {
    if (!searchQuery) {
      return;
    }

    const employers = await PortalApi.admin.searchEmployers({
      name: searchQuery,
      segment_id: undefined,
    });

    this.setState({
      [optionName]: this.generateEmployerOptions(employers),
    });
  }

  generateEmployerOptions = employers => (
    employers.map(e => ({
      key: e.id,
      text: `${e.id}. ${e.name}`,
      value: e.id,
    }))
  )

  submit = async () => {
    const { employerAId, employerBId } = this.state;
    if (!employerAId || !employerBId) {
      toast.error('Error: Please select a employer', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      this.setState({
        isConfirmOpen: false,
      });
      return;
    }

    let response;
    try {
      response = await PortalApi.admin.mergeEmployer({
        employerAId: this.state.employerAId,
        employerBId: this.state.employerBId,
      });
    } catch (error) {
      toast.error(`Error: ${error.message}`, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
      return;
    }

    this.setState({
      ...this.INITIAL_STATE,
    });

    toast.success(response.message, {
      position: toast.POSITION.BOTTOM_CENTER,
      autoClose: 5000,
    });
  }

  render() {
    return (
      <div>
        <Form>
          <Form.Dropdown
            label="Employer A"
            value={this.state.employerAId}
            onChange={(_, { value }) => { this.setState({ employerAId: value }); }}
            onSearchChange={(_, { searchQuery }) => { this.searchEmployers('employerAOptions', searchQuery); }}
            options={this.state.employerAOptions}
            fluid
            selection
            clearable
            search
            lazyLoad
          />
          <Form.Dropdown
            label="Employer B"
            value={this.state.employerBId}
            onChange={(_, { value }) => { this.setState({ employerBId: value }); }}
            onSearchChange={(_, { searchQuery }) => { this.searchEmployers('employerBOptions', searchQuery); }}
            options={this.state.employerBOptions}
            fluid
            selection
            clearable
            search
            lazyLoad
          />
          <Form.Button onClick={() => { this.setState({ isConfirmOpen: true }); }}>
            Merge
          </Form.Button>
        </Form>

        <Confirm
          open={this.state.isConfirmOpen}
          onCancel={() => { this.setState({ isConfirmOpen: false }); }}
          onConfirm={this.submit}
        />
      </div>
    );
  }
}

export default MergeEmployerForm;
