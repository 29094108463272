import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { Segment, Loader, Dimmer } from 'semantic-ui-react';

import EmployerLocation from './employer-location';
import EmployerSegment from './employer-segment';
import SectorModal from './modals/sector';
import PortalApi from '../services/PortalApi';

class Employer extends React.PureComponent {
  state = {
    locations: [],
    employers: [],
    selectedLocationId: null,
    isLoading: true,
    sectorModalSectorId: null,
  }

  async componentDidMount() {
    await this.loadLocations();
  }

  async componentDidUpdate(prevProps) {
    if (!this.props.app.currentProfession) {
      if (prevProps.app.currentProfession) {
        // eslint-disable-next-line react/no-did-update-set-state
        this.setState({
          locations: [],
          employers: [],
        });
      }
      return;
    }
    if (this.props.app.currentProfession.id !== (prevProps.app.currentProfession || {}).id) {
      this.loadLocations();
    }
  }

  changeSelectedLocation = async (newLocationId) => {
    this.setState({
      selectedLocationId: newLocationId,
    });
    await this.loadEmployers(newLocationId);
  }

  loadLocations = async () => {
    if (!this.props.app.currentProfession.id) {
      return;
    }

    this.setState({
      isLoading: true,
    });
    const { id: professionId } = this.props.app.currentProfession;
    const response = await PortalApi.getLocations(professionId);
    const { id: locationId } = response[0];
    this.setState({
      locations: response,
      selectedLocationId: locationId,
    }, async () => {
      await this.loadEmployers(locationId);
    });
  }

  loadEmployers = async (locationId) => {
    this.setState({
      isLoading: true,
    });
    const { id: professionId } = this.props.app.currentProfession;
    const response = await PortalApi.getEmployers(professionId, locationId);
    this.setState({
      employers: response,
      isLoading: false,
    });
  }

  renderEmployers = () => {
    const { employers } = this.state;
    const employerSegmentComponents = employers.map(employer => (
      <EmployerSegment
        key={employer.segment_id}
        sectorId={employer.sector_id}
        sectorName={employer.sector_name}
        segmentName={employer.segment_name}
        mnc={employer.mnc}
        local={employer.local}
      />
    ));
    return (
      <div className="employer__scroll-wrapper">
        { employerSegmentComponents }
      </div>
    );
  }

  showSectorModal = (sectorId) => {
    this.setState({ sectorModalSectorId: sectorId });
  }

  closeSectorModal = () => {
    this.setState({ sectorModalSectorId: null });
  }
  
  render() {
    const { className } = this.props;
    const { isLoading } = this.state;

    const sectorButtons = this.props.relatedSectors.map((sector, index) => (
      // use anchor instead of button to make display: inline possible
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      <a
        className="profession-group__button profession-group__button--related-sector"
        type="button"
        role="button"
        tabIndex={index}
        key={index}
        onClick={() => { this.showSectorModal(sector.id); }}
        onKeyDown={() => { this.showSectorModal(sector.id); }}
      >
        { sector.name }
      </a>
    ));
    
    return (
      <div className={`${className} employer`}>
        <EmployerLocation
          locations={this.state.locations}
          changeSelectedLocation={this.changeSelectedLocation}
          selectedLocationId={this.state.selectedLocationId}
        />
        <Segment className="no-style" basic>
          { isLoading && (
            <Dimmer inverted active className="loading">
              <Loader />
            </Dimmer>
          )}
          { this.props.selectedGroup==="faculty"
            ? <div className="profession-group__sector-buttons"> { sectorButtons } </div>
            : this.renderEmployers()
          }
          { this.props.selectedGroup==="faculty" && this.state.sectorModalSectorId && (
            <SectorModal
              sectorId={this.state.sectorModalSectorId}
              close={this.closeSectorModal}
            />
          )}
        </Segment>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  app: state.app,
});

Employer.propTypes = {
  app: PropTypes.shape({
    currentProfession: PropTypes.shape({
      id: PropTypes.number.isRequired,
    }),
  }).isRequired,
  
  className: PropTypes.string.isRequired,

  relatedSectors: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
  })).isRequired,

  selectedGroup: PropTypes.string.isRequired,
};

const StyledEmployer = styled(Employer)`
  display: flex;
  flex-direction: column;
  flex: 1;

  .ui.basic.segment.no-style {
    flex: 1;
    background-color: #4472c4;
    color: #ffffff;
    position: relative;
    height: 100%;
  }

  .employer__scroll-wrapper {
    position: absolute;
    width: 100%;
    top: 0;
    bottom: 0;
    overflow-y: auto;
  }
  @media only screen and (max-width: 768px) {
    .employer__scroll-wrapper {
      position: relative;
    }
  }
  .profession-group__sector-buttons {
    padding: 0 10px 10px;
  }
  .profession-group__button {
    text-align: left;
    color: #ffffff;
    float: left;
    margin: 30px 30px;
    font-size: 30px;
  }
  .profession-group__button--related-sector {
    -webkit-appearance: none;
  }
  .profession-group__button--related-sector:hover {
    text-decoration: underline;
    cursor: pointer;
    color: inherit;
  }
  .profession-group__button--related-sector:not(:last-child)::after {
    margin-right: 2px;
  }
`;

export default connect(mapStateToProps)(StyledEmployer);
