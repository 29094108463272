import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import ReactTable from 'react-table';
import { Link } from 'react-router-dom';

import { appActions } from '../../../actions';

import AdminPageLayout from '../layout';
import DisciplineGroupForm from '../discipline-group-edit/form';

import PortalApi from '../../../services/PortalApi';
import Utils from '../../../services/Utils';

class DisciplineGroupIndexPage extends React.PureComponent {
  state = {
    disciplineGroups: [],
  }

  async componentDidMount() {
    await this.loadDisciplineGroups();
    this.props.finishLoading();
  }

  loadDisciplineGroups = async () => {
    const disciplineGroups = await PortalApi.admin.getDisciplineGroups();
    this.setState({
      disciplineGroups,
    });
  }

  renderDisciplineGroups = () => {
    const { disciplineGroups } = this.state;

    const columns = [
      {
        Header: '# ID',
        accessor: row => row.id.toString(),
        id: 'id',
        width: 100,
        Cell: ({ value: id }) => (
          <Link className="edit-link" to={`/admin/discipline-groups/${id}`}>
            {id}
          </Link>
        ),
      },
      {
        Header: 'Discipline Group',
        accessor: 'name',
        width: 500,
      },
      {
        Header: 'Disciplines',
        id: 'discipline-name',
        className: 'discipline-names',
        accessor: row => row.disciplines.map(d => d.name).join(', '),
        width: 500,
        Cell: cell => (
          <ul>
            { cell.original.disciplines.map(discipline => (
              <li key={discipline.id}>
                <Link to={`/admin/disciplines/${discipline.id}`}>
                  { discipline.name }
                </Link>
              </li>
            )) }
          </ul>
        ),
      },
    ];

    return (
      <div className="disciplines-list">
        <ReactTable
          data={disciplineGroups}
          columns={columns}
          defaultPageSize={10}
          pageSizeOptions={[10, 25, 50, 100]}
          filterable
          defaultFilterMethod={Utils.reactTableFilterInsensitive}
        />
      </div>
    );
  }

  render() {
    const { className } = this.props;

    return (
      <div className={className}>
        <AdminPageLayout>
          <div className="main-content">
            <h1>Discipline Group</h1>
            { this.renderDisciplineGroups() }
            <hr />
            <div className="create-discipline">
              <h1>Create New Discipline Group</h1>
              <DisciplineGroupForm mode="create" />
            </div>
          </div>
        </AdminPageLayout>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  finishLoading: () => dispatch(appActions.finishLoading()),
});

DisciplineGroupIndexPage.propTypes = {
  finishLoading: PropTypes.func.isRequired,
  className: PropTypes.string.isRequired,
};

const StyledDisciplineGroupIndexPage = styled(DisciplineGroupIndexPage)`
.discipline-names ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
`;

export default connect(
  null,
  mapDispatchToProps,
)(StyledDisciplineGroupIndexPage);
