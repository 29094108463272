import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Modal } from 'semantic-ui-react';

import SectorModalContent from '../sector-modal-content';

const SectorModal = ({ className, close, sectorId }) => (
  <Modal
    className={className}
    size="large"
    open
    closeOnDimmerClick
    onClose={close}
  >
    <div className="modal--sector">
      <div className="sector-modal__scroll-container">
        <SectorModalContent sectorId={sectorId} />
      </div>
    </div>
  </Modal>
);

SectorModal.propTypes = {
  sectorId: PropTypes.number.isRequired,
  close: PropTypes.func.isRequired,

  className: PropTypes.string.isRequired,
};

const StyledSectorModal = styled(SectorModal)`
  width: 80% !important;
  height: 80vh !important;

  &.ui.modal {
    display: flex !important;
  }

  * {
    color: #ffffff;
  }
  .modal--sector {
    width: 100%;
    overflow-y: auto;
  }
  .sector-modal__scroll-container {
    display: flex;
    min-height: 80vh !important;
  }
`;

export default StyledSectorModal;
