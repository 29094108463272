import * as firebase from 'firebase/app';
import 'firebase/auth';

// TODO put in config
const config = {
  apiKey: 'AIzaSyDEPx9d2OJRt5o0T-ukL_HVrLcafSdeBCY',
  authDomain: 'auth.portal.fdmt.hk',
  databaseURL: 'https://portal-229702.firebaseio.com',
  projectId: 'portal-229702',
  storageBucket: 'portal-229702.appspot.com',
  messagingSenderId: '674729106254',
};

const app = firebase.initializeApp(config);

export default app;
