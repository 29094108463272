import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Button, Input, Form } from 'semantic-ui-react';

import { appActions } from '../../../actions';
import PortalApi from '../../../services/PortalApi';

import MergeEmployerForm from './merge-employer-form';
import EmployerForm from '../employer-edit/form';
import EmployerList from './employer-list';
import AdminPageLayout from '../layout';

class EmployerIndexPage extends React.PureComponent {
  state = {
    employers: [],
    segments: [],
    searchString: '',
    segmentId: null,
  }

  async componentDidMount() {
    this.props.finishLoading();
    const segments = await PortalApi.admin.getSegments();
    this.setState({
      segments,
    });
    this.handleSearchClick();
  }

  handleSearchClick = async () => {
    const { searchString, segmentId } = this.state;
    if (!searchString && !segmentId) {
      return;
    }

    const employers = await PortalApi.admin.searchEmployers({
      name: searchString,
      segment_id: segmentId || undefined, // don't pass to backend
    });

    this.setState({
      employers,
    });
  }

  render() {
    const { className } = this.props;

    const segmentOptions = this.state.segments.map(segment => ({
      key: segment.id,
      text: `${segment.sector.name} - ${segment.name}`,
      value: segment.id,
    }));

    return (
      <div className={className}>
        <AdminPageLayout>
          <div className="main-content">
            <h1>Search Employers</h1>
            <Form
              className="search-form"
            >
              <Form.Field
                control={Input}
                label="Employer Name or Employer ID"
                placeholder="Employer Name or Employer ID"
                value={this.state.searchString}
                onChange={(_, { value }) => { this.setState({ searchString: value }); }}
              />
              <Form.Dropdown
                label="Segment"
                placeholder="Segment"
                options={segmentOptions}
                clearable
                selection
                search
                value={this.state.segmentId}
                onChange={(_, { value }) => { this.setState({ segmentId: value }); }}
                lazyLoad
              />
              <Form.Field
                control={Button}
                content="Search"
                onClick={this.handleSearchClick}
              />
            </Form>
            <hr />
            <EmployerList employers={this.state.employers} />
            <h1>Create New Employer</h1>
            <EmployerForm mode="create" />
            <hr />
            <h1>Merge Employer</h1>
            <p>Merge Employer A to Employer B (i.e. Employer A will be removed)</p>
            <MergeEmployerForm />
          </div>
        </AdminPageLayout>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  finishLoading: () => dispatch(appActions.finishLoading()),
});

EmployerIndexPage.propTypes = {
  finishLoading: PropTypes.func.isRequired,
  className: PropTypes.string.isRequired,
};

const StyledEmployerIndexPage = styled(EmployerIndexPage)`
  .search-form {
    margin: 20px 0;
  }
`;

export default connect(
  null,
  mapDispatchToProps,
)(StyledEmployerIndexPage);
