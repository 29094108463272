import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Utils from '../services/Utils';
import Analytics from '../services/analytics';

const ExtraInfos = ({ extraInfos, className }) => {
  const extraInfoComponents = extraInfos.map(({ type, infos }) => {
    if (type === '__CODE__') {
      const components = infos.map(info => (
        // eslint-disable-next-line react/no-danger
        <div key={info.id} dangerouslySetInnerHTML={{ __html: info.content }} />
      ));
      return (
        <div key={type} className="extra-info__group extra-info__group--code">
          { components }
        </div>
      );
    }
    if (type === '__YOUTUBE__') {
      return (<div />);
      /*
      const components = infos.map((info) => {
        const youtubeSrc = Utils.convertYoutubeLinkToEmbededSrc(info.content);
        return (
          <div key={info.id} className="extra-info__youtube-container">
            <iframe title="youtube" src={youtubeSrc} frameBorder="0" allowFullScreen className="extra-info__youtube" />
          </div>
        );
      });
      return (
        <div key={type} className="extra-info__group extra-info__group--youtube">
          { components }
        </div>
      );
      */
    }
    const components = infos.map(info => (
      <p key={info.id} className="extra-info__link">
        { info.content ? (
          <Analytics.ReactGA.OutboundLink
            to={info.content}
            target="_blank"
            rel="noopener noreferrer"
            eventLabel={info.content}
          >
            { info.name }
          </Analytics.ReactGA.OutboundLink>
        ) : (
          info.name
        ) }
      </p>
    ));
    return (
      <div key={type} className="extra-info__group">
        <h4 className="extra-info__type">{ type }</h4>
        { components }
      </div>
    );
  });
  return (
    <div className={`extra-info ${className}`}>
      { extraInfoComponents }
    </div>
  );
};

ExtraInfos.propTypes = {
  className: PropTypes.string.isRequired,
  extraInfos: PropTypes.arrayOf(PropTypes.shape({
    type: PropTypes.string.isRequired,
    infos: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      content: PropTypes.string.isRequired,
    })).isRequired,
  }).isRequired).isRequired,
};

const StyledExtraInfos = styled(ExtraInfos)`
  .extra-info__group {
    margin-bottom: 20px;
  }
  .extra-info__youtube-container {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;
  }
  .extra-info__youtube {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .extra-info__type {
    font-size: 10px;
    margin: 0;
  }
  .extra-info__link {
    margin: 0;
  }
  .extra-info__link a {
    color: #ffffff;
    text-decoration: underline;
  }
  .extra-info__link a:hover {
    color: inherit;
  }
`;

export default StyledExtraInfos;
