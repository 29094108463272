import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import ReactTable from 'react-table';
import { Link } from 'react-router-dom';

import { appActions } from '../../../actions';

import AdminPageLayout from '../layout';

import PortalApi from '../../../services/PortalApi';
import Utils from '../../../services/Utils';

class ContactIndexPage extends React.PureComponent {
  state = {
    contacts: [],
  }

  async componentDidMount() {
    await this.loadContacts();
    this.props.finishLoading();
  }

  loadContacts = async () => {
    const contacts = await PortalApi.admin.getContacts();
    this.setState({
      contacts,
    });
  }

  renderContacts = () => {
    const { contacts } = this.state;

    const relationColumnsObjs = [
      'program',
      'profession',
      'sector',
      'segment',
    ].map((relationType) => {
      const header = `${relationType.charAt(0).toUpperCase()}${relationType.slice(1)} Relation`;
      const accessorFunction = row => (row.contact_relations[relationType] || [])
        .map(relation => `${(relation[relationType] || {}).name} - ${relation.relation}`).join('');
      const columnId = `${relationType}_relation`;
      const cellFunction = (data) => {
        const { contact_relations: contactRelations } = data.original;
        return (
          <ul className="cell-list">
            { (contactRelations[relationType] || []).map((relation) => {
              const entity = (relation[relationType] || {});
              return (
                <li key={relation.entity_id}>
                  {`- ${entity.display_name || entity.name}${relation.relation ? ` (${relation.relation})` : ''}`}
                </li>
              );
            }) }
          </ul>
        );
      };

      return {
        Header: header,
        accessor: accessorFunction,
        id: columnId,
        Cell: cellFunction,
      };
    });

    const getInstitutionColumnObj = () => {
      const relationType = 'program';

      const cellFunction = (data) => {
        const { contact_relations: contactRelations } = data.original;
        const relationInsts = (contactRelations[relationType] || []).map((relation) => {
          const entity = (relation[relationType] || { institution: { name: '' } });
          return entity.institution.name;
        });
        return (
          <ul className="cell-list">
            { [...new Set(relationInsts)].map(instName => (
              <li key={instName}>
                {`- ${instName}`}
              </li>
            )) }
          </ul>
        );
      };
      const accessorFunction = row => (row.contact_relations[relationType] || [])
        .map(relation => (relation[relationType] || { institution: { name: '' } }).institution.name).join('');

      return {
        Header: 'Institutions',
        accessor: accessorFunction,
        id: 'institutions',
        Cell: cellFunction,
      };
    };

    const columns = [
      {
        Header: '# ID',
        accessor: row => row.id.toString(),
        id: 'id',
        width: 100,
        Cell: ({ value: id }) => (
          <Link className="edit-link" to={`/admin/contacts/${id}`}>
            {id}
          </Link>
        ),
      },
      { Header: 'Name', accessor: 'name' },
      { Header: 'Salutation', accessor: 'salutation' },
      {
        Header: 'Source',
        accessor: row => row.source,
        id: 'source',
        Cell: (data) => {
          const { source_link: sourceLink, source } = data.original;
          if (sourceLink) {
            const parsedLink = /^https?:\/\//i.test(sourceLink) ? sourceLink : `http://${sourceLink}`;
            return <a href={parsedLink} target="_blank" rel="noopener noreferrer">{ source }</a>;
          }
          return source;
        },
      },
      getInstitutionColumnObj(),
      ...relationColumnsObjs,
      {
        Header: 'Tags',
        accessor: row => row.contact_tags.map(tag => tag.name).join(''),
        id: 'tags',
        Cell: data => (
          <ul className="cell-list">
            { (data.original.contact_tags).map(tag => (
              <li key={tag.id}>
                { tag.name }
              </li>
            )) }
          </ul>
        ),
      },
    ];

    return (
      <div>
        <ReactTable
          data={contacts}
          columns={columns}
          defaultPageSize={50}
          pageSizeOptions={[10, 25, 50, 100]}
          filterable
          defaultFilterMethod={Utils.reactTableFilterInsensitive}
        />
      </div>
    );
  }

  render() {
    const { className } = this.props;

    return (
      <div className={className}>
        <AdminPageLayout>
          <div className="main-content">
            <h1>Contacts</h1>
            <Link to="/admin/contacts/new">Create New Contact</Link>
            { this.renderContacts() }
          </div>
        </AdminPageLayout>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  finishLoading: () => dispatch(appActions.finishLoading()),
});

ContactIndexPage.propTypes = {
  finishLoading: PropTypes.func.isRequired,
  className: PropTypes.string.isRequired,
};

const StyledContactIndexPage = styled(ContactIndexPage)`
.cell-list {
  list-style: none;
  margin: 0;
  padding: 0;
}
.ReactTable .rt-td li {
  white-space: pre-wrap;
}
`;

export default connect(
  null,
  mapDispatchToProps,
)(StyledContactIndexPage);
