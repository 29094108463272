import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Dropdown } from 'semantic-ui-react';

import ExtraInfos from './extra-infos';
import EmployerList from './employer-list';

import PortalApi from '../services/PortalApi';


class SectorModalContent extends React.PureComponent {
  state = {
    name: null,
    sectorExtraInfosCode: null,
    sectorExtraInfosGlobal: [],
    sectorExtraInfosLocation: [],
    locations: [],
    segments: [],

    segmentExtraInfosGlobal: [],
    segmentExtraInfosLocation: [],
    employers: null,

    isSelectedSector: true,
    selectedSegmentId: null,
    selectedLocation: null,
  }

  async componentDidMount() {
    await this.loadSectorGlobal();
  }

  async componentDidUpdate(prevProps) {
    if (prevProps.sectorId !== this.props.sectorId) {
      await this.loadSectorGlobal();
    }
  }

  loadSectorGlobal = async () => {
    const { sectorId } = this.props;
    const response = await PortalApi.getSector(sectorId);
    const selectedLocation = response.locations[0];

    this.setState({
      name: response.name,
      segments: response.segments,
      locations: response.locations,
      sectorExtraInfosCode: response.extra_info_by_groups.find(group => group.type === '__CODE__'),
      sectorExtraInfosGlobal: response.extra_info_by_groups.filter(group => group.type !== '__CODE__'),
      selectedLocation,
      employers: null,
      isSelectedSector: true,
      selectedSegmentId: null,
    }, async () => {
      if (selectedLocation) {
        await this.loadSectorLocation(selectedLocation.id);
      }
    });
  }

  loadSectorLocation = async (locationId) => {
    const { sectorId } = this.props;
    const response = await PortalApi.getSector(sectorId, locationId);
    this.setState({
      sectorExtraInfosLocation: response.extra_info_by_groups,
    });
  }

  loadSegmentExtraInfosGlobal = async (segmentId) => {
    const response = await PortalApi.getSegmentExtraInfos(segmentId);
    this.setState({
      segmentExtraInfosGlobal: response.extra_infos_by_type,
    });
  }

  loadSegmentExtraInfosLocation = async (segmentId) => {
    const locationId = (this.state.selectedLocation || {}).id;
    const response = await PortalApi.getSegmentExtraInfos(segmentId, locationId);
    this.setState({
      segmentExtraInfosLocation: response.extra_infos_by_type,
    });
  }

  loadSegmentEmployers = async (segmentId) => {
    const locationId = (this.state.selectedLocation || {}).id;
    const response = await PortalApi.getSegmentEmployers(segmentId, locationId);
    this.setState({
      employers: response,
    });
  }

  renderSectorExtraInfosCode = () => {
    let components = null;
    if (this.state.sectorExtraInfosCode) {
      components = this.state.sectorExtraInfosCode.infos.map((info) => {
        let iframeClass = '';
        if (info.content.includes('<iframe')) {
          iframeClass = 'google-slides-container';
        }
        return (
          <React.Fragment key={info.id}>
            {/* eslint-disable react/no-danger */}
            <div
              className={iframeClass}
              dangerouslySetInnerHTML={{ __html: info.content }}
            />
          </React.Fragment>
        );
      });
    }
    return (
      <div className="sector-extra-info-code">
        { components }
      </div>
    );
  }

  renderSegmentOptions = () => {
    const { segments } = this.state;
    const components = segments.map((segment) => {
      const selectedClass = this.state.selectedSegmentId === segment.id ? 'button--selected' : '';
      return (
        <button
          key={segment.id}
          type="button"
          className={`location-button button--no-style ${selectedClass}`}
          onClick={() => { this.handleSegmentChange(segment.id); }}
        >
          <h3>{ segment.name }</h3>
        </button>
      );
    });
    const selectedSectorClass = this.state.isSelectedSector ? 'button--selected' : '';
    return (
      <div className="segment-options">
        <div className="sector-name">
          <button
            type="button"
            className={`location-button button--no-style ${selectedSectorClass}`}
            onClick={() => { this.handleSectorSelect(); }}
          >
            <h1>{ this.state.name }</h1>
          </button>
        </div>
        { components }
      </div>
    );
  }

  renderSectorExtraInfos = () => {
    if (!this.state.isSelectedSector) {
      return null;
    }

    const {
      sectorExtraInfosGlobal: infosGlobal,
      sectorExtraInfosLocation: infosLocation,
    } = this.state;
    const selectedLocationName = (this.state.selectedLocation || {}).name;

    if (!this.state.isSelectedSector) {
      return null;
    }
    return (
      <div className="sector-extra-info">
        <div className="extra-info-text__container">
          <div className="extra-info-text__block">
            <h3>Global</h3>
            { infosGlobal.length > 0 && (
              <ExtraInfos extraInfos={infosGlobal} />
            )}
          </div>
          <div className="extra-info-text__block">
            <h3>{ selectedLocationName }</h3>
            { infosLocation.length > 0 && (
              <ExtraInfos extraInfos={infosLocation} />
            )}
          </div>
        </div>
      </div>
    );
  }

  renderLocations = () => {
    const locationOptions = this.state.locations.map(location => ({
      key: location.id,
      text: location.name,
      value: location.id,
    }));

    const selectedLocation = this.state.locations
      .find(location => location.id === (this.state.selectedLocation || {}).id);

    return (
      <Dropdown
        className="locations-dropdown"
        fluid
        selection
        options={locationOptions}
        value={(selectedLocation || {}).id}
        onChange={this.handleChangeLocation}
      />
    );
  }

  renderSegmentExtraInfos = () => {
    if (this.state.isSelectedSector) {
      return null;
    }

    const {
      segmentExtraInfosGlobal: infosGlobal,
      segmentExtraInfosLocation: infosLocation,
    } = this.state;
    const selectedLocationName = (this.state.selectedLocation || {}).name;
    return (
      <div className="extra-info-text__container">
        <div className="extra-info-text__block">
          <h3>Global</h3>
          { infosGlobal.length > 0 && (
            <ExtraInfos extraInfos={infosGlobal} />
          )}
          { this.state.employers && (
            <div className="employers-block">
              <EmployerList employers={this.state.employers.mnc} showNotAvailableText={false} />
            </div>
          ) }
        </div>
        <div className="extra-info-text__block">
          <h3>{ selectedLocationName }</h3>
          { infosLocation.length > 0 && (
            <ExtraInfos extraInfos={infosLocation} />
          )}
          { this.state.employers && (
            <div className="employers-block">
              <EmployerList employers={this.state.employers.local} showNotAvailableText={false} />
            </div>
          ) }
        </div>
      </div>
    );
  }

  // renderEmployers = () => {
  //   if (!this.state.employers) {
  //     return null;
  //   }

  //   return (
  //     <div className="employers">
  //       <div className="employers-block">
  //         <h3>Global</h3>
  //         { <EmployerList employers={this.state.employers.mnc} /> }
  //       </div>
  //       <div className="employers-block">
  //         <h3>{ (this.state.selectedLocation || {}).name }</h3>
  //         { <EmployerList employers={this.state.employers.local} /> }
  //       </div>
  //     </div>
  //   );
  // }

  handleChangeLocation = async (_, data) => {
    const locationId = data.value;
    this.setState(prevState => ({
      selectedLocation: prevState.locations.find(location => location.id === locationId),
    }));

    await this.loadSectorLocation(locationId);

    if (this.state.isSelectedSector) {
      await this.loadSectorLocation(locationId);
    } else {
      await this.loadSegmentExtraInfosLocation(this.state.selectedSegmentId);
      await this.loadSegmentEmployers(this.state.selectedSegmentId);
    }
  }

  handleSegmentChange = async (segmentId) => {
    this.setState({
      selectedSegmentId: segmentId,
      isSelectedSector: false,
    });
    await this.loadSegmentExtraInfosGlobal(segmentId);
    await this.loadSegmentExtraInfosLocation(segmentId);
    await this.loadSegmentEmployers(segmentId);
  }

  handleSectorSelect = async () => {
    this.setState({
      selectedSegmentId: null,
      isSelectedSector: true,
      employers: null,
    });
  }

  render() {
    return (
      <div className={this.props.className}>
        { this.renderSegmentOptions() }
        { this.renderSectorExtraInfosCode() }
        { this.renderLocations() }
        { this.renderSectorExtraInfos() }
        { this.renderSegmentExtraInfos() }
      </div>
    );
  }
}

SectorModalContent.propTypes = {
  sectorId: PropTypes.number.isRequired,

  className: PropTypes.string.isRequired,
};

const StyledSectorModalContent = styled(SectorModalContent)`
background-color: #245436;
width: 100%;
padding: 20px;

.sector-extra-info-code {
  /* max-width: 1024px; */
}
.google-slides-container {
  position: relative;
  height: 0;
  padding-bottom: 56.25%;
  overflow: hidden;
}
.google-slides-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.sector-name {
  margin-bottom: 10px;
}

.button--selected {
  pointer-events: none;
}
.button--selected h1,
.button--selected h3 {
  text-decoration: underline;
}

.segment-options {
  margin: 0 -10px 10px;
}
.location-button {
  border-right: 1px solid #fff;
  padding: 0 10px;
}
.location-button:last-child {
  border-right: none;
}
.location-button h1,
.location-button h3 {
  text-align: left;
}

.locations-dropdown {
  margin-bottom: 20px;
  background: transparent;
}
.locations-dropdown * {
  color: #000000;
}

.sector-extra-info-code {
  margin-bottom: 20px;
}

.extra-info-text__container {
  display: flex;
  flex-direction: row;
}
.extra-info-text__block {
  width: 50%;
}

.employers-block {
  margin-top: -20px;
}
.employers-block .employer-list__list {
  padding-left: 0;
}
.employers-block a {
  color: #ffffff;
  text-decoration: underline;
}
.employers-block a:hover {
  color: inherit;
}
`;

export default StyledSectorModalContent;
