import React from 'react';
import {
  Button,
  Input,
  Form,
} from 'semantic-ui-react';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';

import PortalApi from '../../../services/PortalApi';
import Utils from '../../../lib/utils';


class SegmentForm extends React.PureComponent {
  state = {
    isSaving: false,
    ...this.props.segment,
    sectorOptions: [],
    availableResearchStatuses: [],
  }

  async componentDidMount() {
    await this.loadSegments();
    await this.loadAvailableResearchStatuses();
  }

  loadAvailableResearchStatuses = async () => {
    const availableResearchStatuses = await PortalApi.admin.getAvailableResearchStatuses();
    this.setState({
      availableResearchStatuses,
    });
  }

  loadSegments = async () => {
    const sectors = await PortalApi.admin.getSectors();
    this.setState({
      sectorOptions: sectors.map(sector => ({
        key: sector.id,
        text: `${sector.name} - ${sector.name}`,
        value: sector.id,
      })),
    });
  }

  handleStringFieldChange = (field, event) => {
    this.setState({ [field]: event.target.value });
  }

  handleSaveClick = async () => {
    const bodyParams = {
      name: this.state.name,
      sector_id: this.state.sector_id,
      seq_in_popup: this.state.seq_in_popup,
      last_researched_at: this.state.last_researched_at || null,
      research_status_id: this.state.research_status_id,
      peer_reviewed_by: this.state.peer_reviewed_by || '',
      presented_to: this.state.presented_to || '',
      practitioner_review_by: this.state.practitioner_review_by || '',
      responsible_practitioners: this.state.responsible_practitioners || '',
      whatsapp_id: this.state.whatsapp_id || '',
      work_in_progress_by: this.state.work_in_progress_by || '',
    };

    const toastId = toast.info('Saving...', {
      position: toast.POSITION.BOTTOM_CENTER,
    });

    this.setState({
      isSaving: true,
    });

    let segment;
    try {
      if (this.props.mode === 'create') {
        segment = await this.createSegment(bodyParams);
      } else if (this.props.mode === 'edit') {
        segment = await this.editSegment(bodyParams);
      }
    } catch (error) {
      if (error instanceof PortalApi.ApiError) {
        Utils.toastAdminApiError(error);
      } else {
        Utils.toastAdminGeneralError(error);
      }
    }

    toast.success(`Saved ${segment.name} (id: ${segment.id})`, {
      position: toast.POSITION.BOTTOM_CENTER,
      autoClose: 5000,
    });

    toast.dismiss(toastId);

    this.setState({
      isSaving: false,
    });
  }

  createSegment = async (bodyParams) => {
    const segment = await PortalApi.admin.createSegment(bodyParams);

    this.setState({
      ...SegmentForm.DEFAULT_FORM_STATE,
    });

    return segment;
  }

  editSegment = async (bodyParams) => {
    const segment = await PortalApi.admin.editSegment(this.state.id, bodyParams);
    return segment;
  }

  render() {
    const researchStatusOptions = this.state.availableResearchStatuses.map(status => ({
      key: status.id,
      text: status.status_string,
      value: status.id,
    }));

    return (
      <Form>
        <Form.Field
          control={Input}
          label="Segment Name"
          placeholder="Segment Name"
          value={this.state.name}
          onChange={(event) => { this.handleStringFieldChange('name', event); }}
          required
        />
        <Form.Dropdown
          fluid
          placeholder="Sector"
          label="Sector"
          options={this.state.sectorOptions}
          selection
          required
          lazyLoad
          search
          value={this.state.sector_id}
          onChange={(_, { value }) => { this.setState({ sector_id: value }); }}
        />
        <Form.Field
          control={Input}
          type="date"
          label="Last Researched At"
          placeholder="Last Researched At"
          value={this.state.last_researched_at || ''}
          onChange={(_, { value }) => { this.setState({ last_researched_at: value }); }}
        />
        <Form.Dropdown
          label="Research Status"
          placeholder="Research Status"
          options={researchStatusOptions}
          selection
          search
          value={this.state.research_status_id}
          required
          onChange={(_, { value }) => { this.setState({ research_status_id: value }); }}
        />
        <Form.Field
          control={Input}
          label="Peer Review By"
          placeholder="Peer Review By"
          value={this.state.peer_reviewed_by}
          onChange={(event) => { this.handleStringFieldChange('peer_reviewed_by', event); }}
        />
        <Form.Field
          control={Input}
          label="Presented To"
          placeholder="Presented To"
          value={this.state.presented_to}
          onChange={(event) => { this.handleStringFieldChange('presented_to', event); }}
        />
        <Form.Field
          control={Input}
          label="Practitioner Review By"
          placeholder="Practitioner Review By"
          value={this.state.practitioner_review_by}
          onChange={(event) => { this.handleStringFieldChange('practitioner_review_by', event); }}
        />
        <Form.Field
          control={Input}
          label="Responsible Practitioners"
          placeholder="Responsible Practitioners"
          value={this.state.responsible_practitioners}
          onChange={(event) => { this.handleStringFieldChange('responsible_practitioners', event); }}
        />
        <Form.Field
          control={Input}
          type="number"
          label="Sequence In Popup"
          placeholder="Sequence In Popup"
          value={this.state.seq_in_popup}
          onChange={(event) => { this.handleStringFieldChange('seq_in_popup', event); }}
          required
        />
        <Form.Field
          control={Input}
          label="Work In Progress By"
          placeholder="Work In Progress By"
          value={this.state.work_in_progress_by}
          onChange={(event) => { this.handleStringFieldChange('work_in_progress_by', event)}}
        />
        <Form.Field
          control={Button}
          content="Save"
          onClick={this.handleSaveClick}
          loading={this.state.isSaving}
        />
      </Form>
    );
  }
}

SegmentForm.DEFAULT_FORM_STATE = {
  name: '',
  id: null,
  sector_id: null,
  seq_in_popup: 99999,
  last_researched_at: '',
  research_status_id: null,
  peer_reviewed_by: '',
  presented_to: '',
  practitioner_review_by: '',
  responsible_practitioners: '',
  whatsapp_id: '',
  work_in_progress_by: '',
};

SegmentForm.propTypes = {
  mode: PropTypes.string.isRequired,

  // can't add isRequired to child
  // but other people can https://stackoverflow.com/a/46124192
  segment: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    sector_id: PropTypes.number,
    last_researched_at: PropTypes.string,
    seq_in_popup: PropTypes.number,
    whatsapp_id: PropTypes.string,
    work_in_progress_by: PropTypes.string,
  }),
};

SegmentForm.defaultProps = {
  segment: SegmentForm.DEFAULT_FORM_STATE,
};

export default SegmentForm;
