import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import EmployerList from './employer-list';


const EmployerListWithLocalMnc = ({ className, local, mnc }) => (
  <div className={className}>
    <div className="employer-list__mnc-container">
      <div className="employer-list__employer-block">
        <p className="employer-list__mnc-type">Local</p>
        <EmployerList employers={local} />
      </div>
      <div className="employer-list__employer-block">
        <p className="employer-list__mnc-type">MNC</p>
        <EmployerList employers={mnc} />
      </div>
    </div>
  </div>
);

EmployerListWithLocalMnc.propTypes = {
  mnc: PropTypes.shape({}).isRequired,
  local: PropTypes.shape({}).isRequired,

  className: PropTypes.string.isRequired,
};

const StyledEmployerListWithLocalMnc = styled(EmployerListWithLocalMnc)`
max-width: 900px;

a {
  color: #ffffff;
}
a:hover {
  color: #b7b7b7
}

.employer-list__mnc-container {
  display: flex;
  flex-direction: row;
}
.employer-list__employer-block {
  flex: 1;
}
@media only screen and (max-width: 768px) {
  .employer-list__mnc-container {
    display: block;
  }
  .employer-list__employer-block {
    margin-bottom: 20px;
  }
}
.employer-list__mnc-type {
  font-size: 10px;
  margin-bottom: 10px;
}
`;

export default StyledEmployerListWithLocalMnc;
