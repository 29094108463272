import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import ReactTable from 'react-table';
import { Link } from 'react-router-dom';

import { appActions } from '../../../actions';

import AdminPageLayout from '../layout';
import UserForm from '../user-edit/form';

import PortalApi from '../../../services/PortalApi';
import Utils from '../../../services/Utils';

class UserIndexPage extends React.PureComponent {
  state = {
    users: [],
  }

  async componentDidMount() {
    await this.loadUsers();
    this.props.finishLoading();
  }

  loadUsers = async () => {
    const users = await PortalApi.admin.getUsers();
    this.setState({
      users,
    });
  }

  renderProgram = (cell) => {
    if (!cell.original.program) {
      return null;
    }

    return (
      <Link to={`/admin/programs/${cell.original.program.id}`}>
        { cell.original.program.name }
      </Link>
    );
  }

  renderUserAccesses = (cell) => {
    const { user_accesses: accesses } = cell.original;
    const accessComponents = accesses.map((access) => {
      const validFrom = new Date(access.valid_from);
      const validTo = new Date(access.valid_to);
      const currentDate = new Date();

      const isBetween = currentDate >= validFrom && currentDate <= validTo;
      const activeClass = isBetween ? 'active-access' : '';
      return (
        <li key={access.id} className={activeClass}>
          {`${validFrom.toLocaleString('en-HK', { timeZone: 'Asia/Hong_Kong', timeZoneName: 'short' })} - ${validTo.toLocaleString('en-HK', { timeZone: 'Asia/Hong_Kong', timeZoneName: 'short' })}`}
        </li>
      );
    });

    return (
      <ul className="user-accesses">
        { accessComponents }
      </ul>
    );
  }

  renderUserEmails = (cell) => {
    const emailsString = cell.original.user_emails.map(email => email.email).join(', ');
    return (
      <p>{ emailsString }</p>
    );
  }

  renderUsers = () => {
    const { users } = this.state;

    const columns = [
      {
        Header: '# ID',
        accessor: row => row.id.toString(),
        id: 'id',
        width: 100,
        Cell: ({ value: id }) => (
          <Link className="edit-link" to={`/admin/users/${id}`}>
            {id}
          </Link>
        ),
      },
      {
        Header: 'Name',
        accessor: 'name',
        width: 300,
      },
      {
        Header: 'Role',
        accessor: 'role',
        width: 100,
      },
      {
        Header: 'Emails',
        accessor: row => row.user_emails.map(email => `${email.email}`).join(', '),
        id: 'user_emails',
        Cell: this.renderUserEmails,
      },
      {
        Header: 'Program',
        id: 'program',
        accessor: row => `${(row.program || {}).name}`,
        Cell: this.renderProgram,
      },
      {
        Header: 'Access Scope',
        accessor: 'access_scope',
        width: 300,
      },
      {
        Header: 'Accesses',
        accessor: row => row.user_accesses.map(access => `${access.valid_from} - ${access.valid_to}`).join(', '),
        id: 'user_accesses',
        Cell: this.renderUserAccesses,
      },
    ];

    return (
      <div className="program-list">
        <ReactTable
          data={users}
          columns={columns}
          defaultPageSize={25}
          pageSizeOptions={[10, 25, 50, 100]}
          filterable
          defaultFilterMethod={Utils.reactTableFilterInsensitive}
        />
      </div>
    );
  }

  render() {
    const { className } = this.props;

    return (
      <div className={className}>
        <AdminPageLayout>
          <div className="main-content">
            { this.renderUsers() }
            <hr />
            <div className="create-user">
              <h1>Create New User</h1>
              <UserForm mode="create" />
            </div>
          </div>
        </AdminPageLayout>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  finishLoading: () => dispatch(appActions.finishLoading()),
});

UserIndexPage.propTypes = {
  finishLoading: PropTypes.func.isRequired,
  className: PropTypes.string.isRequired,
};

const StyledUserIndexPage = styled(UserIndexPage)`
.user-accesses {
  margin: 0;
  padding: 0;
}
.active-access {
  color: green;
}
`;

export default connect(
  null,
  mapDispatchToProps,
)(StyledUserIndexPage);
