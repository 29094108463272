// TODO rename this file
import store from 'store';

import config from '../config';

const urlPrefix = config.apiUrl;

class ApiError extends Error {
  constructor({ code, message, statusCode }, ...params) {
    super(...params);

    this.code = code;
    this.message = message;
    this.statusCode = statusCode;
  }
}

const queryParams = (params) => {
  const result = Object
    .entries(params)
    .filter(([, value]) => !(Array.isArray(value) && value.length === 0))
    .filter(([, value]) => value !== undefined)
    .map(([key, value]) => {
      if (Array.isArray(value)) {
        return value
          .map(item => `${encodeURIComponent(key)}[]=${encodeURIComponent(item)}`)
          .join('&');
      }

      return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
    })
    .join('&');

  return result
    ? `?${result}`
    : '';
};

const req = async (method, uri, params = {}) => {
  const url = `${urlPrefix}${uri}`;
  const reqUrl = (method === 'GET')
    ? url + queryParams(params)
    : url;

  const body = (method === 'GET')
    ? null
    : JSON.stringify(params);

  const accessToken = store.get(config.storage.keys.accessToken);

  const fetchOptions = {
    method,
    body,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  };

  const res = await fetch(reqUrl, fetchOptions);
  let errorObj;
  try {
    const resJson = await res.json();
    if (res.ok) {
      return resJson;
    }

    if (resJson.error_code) {
      if (resJson.error_code === 'INVALID_TOKEN' || resJson.error_code === 'UNAUTHORIZED') {
        store.remove(config.storage.keys.accessToken);
        if (window.location.href.includes('/admin')) {
          window.location.replace('/');
        } else {
          window.location.reload(true);
        }
      }
      errorObj = {
        code: resJson.error_code,
        message: resJson.message,
        statusCode: res.status,
      };
    }
  } catch (err) {
    errorObj = {
      code: 'UNKNOWN_ERROR',
      message: 'UNKNOWN ERROR',
      statusCode: 500,
    };
  }
  throw new ApiError(errorObj);
};

const GET = 'GET';
const POST = 'POST';
const PATCH = 'PATCH';
const PUT = 'PUT';
const DELETE = 'DELETE';

export default {
  ApiError,

  getCvCl: async id => req(GET, `professions/${id}/cv-cl`),
  getEmployers: async (professionId, locationId) => req(GET, `professions/${professionId}/employers`, { location_id: locationId }),
  getLocations: async professionId => req(GET, 'locations', { profession_id: professionId }),
  getLocationEmployerCount: async () => req(GET, 'locations', { type: 'statistics' }),
  getProgram: async id => req(GET, `programs/${id}`),
  getProfession: async id => req(GET, `professions/${id}`),
  getSector: async (id, locationId) => req(GET, `sectors/${id}`, { location_id: locationId }),
  getSectors: async () => req(GET, 'sectors'),

  getAllowedDisciplineGroupsAndPrograms: async () => req(GET, 'discipline-groups'),

  getSegmentExtraInfos: async (id, locationId) => req(GET, `segments/${id}/extra-infos`, { location_id: locationId }),
  getSegmentEmployers: async (id, locationId) => req(GET, `segments/${id}/employers`, { location_id: locationId }),

  getSettings: async params => req(GET, 'settings', params),
  getTestAccess: async params => req(GET, 'user/access', params),

  postToken: async params => req(POST, 'user/token', params),

  admin: {
    getDisciplineGroups: async () => req(GET, 'admin/discipline-groups'),
    getDisciplineGroup: async id => req(GET, `admin/discipline-groups/${id}`),
    createDisciplineGroup: async params => req(POST, 'admin/discipline-groups', params),
    saveDisciplineGroup: async (id, params) => req(PATCH, `admin/discipline-groups/${id}`, params),

    getDisciplines: async () => req(GET, 'admin/disciplines'),
    getDiscipline: async id => req(GET, `admin/disciplines/${id}`),
    getDisciplineProfessions: async params => req(POST, 'admin/disciplines/profession-assignments', params),
    getSectorsOfDisciplines: async params => req(POST, 'admin/disciplines/sector-assignments', params),
    getTagsOfDisciplines: async params => req(POST, 'admin/disciplines/tags', params),
    saveDiscipline: async (id, params) => req(PATCH, `admin/disciplines/${id}`, params),
    saveDisciplineProfessionAssignments: async (id, params) => req(PUT, `admin/disciplines/${id}/profession-assignments`, params),
    saveDisciplines: async (disciplineGroupId, params) => req(PATCH, `admin/discipline-groups/${disciplineGroupId}/disciplines`, params),
    saveDisciplineTags: async (id, params) => req(PUT, `admin/disciplines/${id}/discipline-tags`, params),

    getDisciplineRelatedSectors: async id => req(GET, `admin/disciplines/${id}/related-sectors`),
    saveDisciplineRelatedSectors: async (id, params) => req(PUT, `admin/disciplines/${id}/related-sectors`, params),

    getPrograms: async () => req(GET, 'admin/programs'),
    getProgram: async id => req(GET, `admin/programs/${id}`),
    createProgram: async params => req(POST, 'admin/programs/', params),
    saveProgram: async (id, params) => req(PATCH, `admin/programs/${id}`, params),
    duplicateProgram: async (id, params) => req(POST, `admin/programs/${id}/duplicate`, params),

    saveProgramProfessionAssignments: async (id, params) => req(PUT, `admin/programs/${id}/profession-assignments`, params),
    saveProgramTags: async (id, params) => req(PUT, `admin/programs/${id}/program-tags`, params),

    saveProgramQuestions: async (id, params) => req(PUT, `admin/programs/${id}/questions`, params),
    getRelatedSectors: async id => req(GET, `admin/programs/${id}/related-sectors`),
    saveRelatedSectors: async (id, params) => req(PUT, `admin/programs/${id}/related-sectors`, params),

    saveProgramAdmissions: async (id, params) => req(PUT, `admin/programs/${id}/program-admissions`, params),

    getProgramRelatedContacts: async id => req(GET, `admin/programs/${id}/related-contacts`),
    saveProgramRelatedContacts: async (id, params) => req(PUT, `admin/programs/${id}/related-contacts`, params),
    getProfessionRelatedContacts: async id => req(GET, `admin/professions/${id}/related-contacts`),
    saveProfessionRelatedContacts: async (id, params) => req(PUT, `admin/professions/${id}/related-contacts`, params),
    getSectorRelatedContacts: async id => req(GET, `admin/sectors/${id}/related-contacts`),
    saveSectorRelatedContacts: async (id, params) => req(PUT, `admin/sectors/${id}/related-contacts`, params),
    getSegmentRelatedContacts: async id => req(GET, `admin/segments/${id}/related-contacts`),
    saveSegmentRelatedContacts: async (id, params) => req(PUT, `admin/segments/${id}/related-contacts`, params),

    getProfessions: async () => req(GET, 'admin/professions'),
    getProfession: async id => req(GET, `admin/professions/${id}`),
    createProfession: async params => req(POST, 'admin/professions', params),
    saveProfession: async (id, params) => req(PATCH, `admin/professions/${id}`, params),
    saveProfessionRelatedSegments: async (id, params) => req(PUT, `admin/professions/${id}/related-segments`, params),
    saveProfessionTabAssignments: async (id, params) => req(PUT, `admin/professions/${id}/profession-tabs`, params),

    getProfessionTabs: async () => req(GET, 'admin/profession-tabs'),
    createProfessionTab: async params => req(POST, 'admin/profession-tabs', params),

    getPositions: async postDate => req(GET, 'admin/positions', { post_date: postDate }),
    patchPosition: async (id, params) => req('PATCH', `admin/positions/${id}`, params),

    getSegment: async id => req(GET, `admin/segments/${id}`),
    getSegments: async () => req(GET, 'admin/segments'),
    createSegment: async params => req(POST, 'admin/segments', params),
    editSegment: async (id, params) => req(PATCH, `admin/segments/${id}`, params),
    deleteSegment: async id => req(DELETE, `admin/segments/${id}`),

    getSector: async id => req(GET, `admin/sectors/${id}`),
    getSectors: async () => req(GET, 'admin/sectors'),
    createSector: async params => req(POST, 'admin/sectors', params),
    editSector: async (id, params) => req(PATCH, `admin/sectors/${id}`, params),

    createEmployerAlias: async (employerId, params) => req(POST, `admin/employers/${employerId}/aliases`, params),
    createEmployer: async params => req(POST, 'admin/employers', params),
    searchEmployers: async params => req(GET, 'admin/employers', params),
    getEmployer: async id => req(GET, `admin/employers/${id}`),
    saveEmployer: async (id, params) => req(PATCH, `admin/employers/${id}`, params),
    saveEmployerLocations: async (id, params) => req(PUT, `admin/employers/${id}/locations`, params),
    mergeEmployer: async params => req(POST, 'admin/employers/merge', params),

    getDailyEmailInfo: async postDate => req(GET, 'admin/positions/daily-email-info', { post_date: postDate }),
    sendDailyEmail: async params => req(POST, 'admin/positions/daily-email', params),

    getAvailableLocations: async () => req(GET, 'admin/locations'),

    saveExtraInfos: async (id, params) => req(PUT, `admin/extra-info-groups/${id}/extra-infos`, params),

    getUsers: async () => req(GET, 'admin/users'),
    createUser: async params => req(POST, 'admin/users', params),
    getUser: async id => req(GET, `admin/users/${id}`),
    editUser: async (id, params) => req(PUT, `admin/users/${id}`, params),
    grantUserAccesses: async (id, params) => req(POST, `admin/users/${id}/user-accesses`, params),
    revokeAllUserAccesses: async id => req(DELETE, `admin/users/${id}/user-accesses`),

    getAvailableResearchStatuses: async () => req(GET, 'admin/research-statuses'),

    getInstitution: async id => req(GET, `admin/institutions/${id}`),
    getInstitutions: async () => req(GET, 'admin/institutions'),
    createInstitution: async params => req(POST, 'admin/institutions', params),
    editInstitution: async (id, params) => req(PATCH, `admin/institutions/${id}`, params),

    getContacts: async () => req(GET, 'admin/contacts'),
    getContact: async id => req(GET, `admin/contacts/${id}`),
    createContact: async params => req(POST, 'admin/contacts', params),
    updateContact: async (id, params) => req(PATCH, `admin/contacts/${id}`, params),
    getContactRelationEntityOptions: async () => req(GET, 'admin/contact-relations/entity-options'),
    getContactRelationRelationOptions: async () => req(GET, 'admin/contact-relations/relation-options'),
    getContactRelationCompanyOptions: async () => req(GET, 'admin/contact-relations/company-options'),

    getContactTags: async () => req(GET, 'admin/contact-tags'),
    getContactTag: async id => req(GET, `admin/contact-tags/${id}`),
    createContactTag: async params => req(POST, 'admin/contact-tags', params),
    updateContactTag: async (id, params) => req(PATCH, `admin/contact-tags/${id}`, params),
  },
};
