import React from 'react';
import PropTypes from 'prop-types';
import ReactTable from 'react-table';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

class EmployerList extends React.PureComponent {
  render() {
    const { employers } = this.props;
    const columns = [
      {
        Header: '# ID',
        accessor: row => row.id.toString(),
        id: 'id',
        width: 100,
        Cell: ({ value: id }) => (
          <Link className="edit-link" to={`/admin/employers/${id}`}>
            {id}
          </Link>
        ),
      },
      {
        Header: 'Name',
        accessor: 'name',
        width: 500,
      },
      {
        Header: 'Aliases',
        accessor: 'employer_name_aliases',
        width: 500,
        Cell: (props) => {
          const { value: aliases } = props;
          const aliasComponents = aliases.map(alias => (
            <p key={alias.id}>{ alias.name }</p>
          ));
          return <div className="employer-aliases">{ aliasComponents }</div>;
        },
      },
      {
        id: 'sector-segment',
        Header: 'Sector & Segment',
        width: 300,
        Cell: (data) => {
          const { segment = {} } = data.original;
          const { sector = {} } = segment;
          const text = `${sector.name} - ${segment.name}`;
          return <p>{ text }</p>;
        },
      },
      {
        Header: 'Company URL',
        accessor: 'company_url',
        width: 300,
        Cell: props => (
          <a href={props.value} target="_blank" rel="noopener noreferrer">
            { props.value }
          </a>
        ),
      },
      {
        Header: 'Brand Name',
        accessor: 'brand_name',
        width: 100,
      },
      {
        Header: 'Show On Portal',
        accessor: 'show_on_portal_type',
        width: 200,
      },
      {
        Header: 'Location',
        accessor: 'employer_location_assignments',
        width: 300,
        Cell: (props) => {
          const { value: locationAssignments } = props;
          const locationString = locationAssignments.map(assignment => assignment.location.name).join(', ');
          return <p className="location-names">{ locationString }</p>;
        },
      },
      {
        Header: 'Is MNC?',
        accessor: 'is_mnc',
        width: 100,
        Cell: props => props.value.toString(),
      },
      {
        Header: 'Is Deadlink?',
        accessor: 'company_url_status',
        width: 100,
        Cell: props => props.value.toString(),
      },
      {
        Header: 'Status Last Updated At',
        accessor: 'status_last_updated',
        width: 200,
      },
      {
        Header: 'Sequence',
        accessor: 'seq',
        width: 100,
      },
    ];

    return (
      <ReactTable
        data={employers}
        columns={columns}
        defaultPageSize={25}
        pageSizeOptions={[10, 25, 50, 100]}
        className={this.props.className}
      />
    );
  }
}

EmployerList.propTypes = {
  employers: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    brand_name: PropTypes.string.isRequired,
    company_url: PropTypes.string.isRequired,
    is_mnc: PropTypes.bool.isRequired,
    employer_name_aliases: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    company_url_status: PropTypes.bool.isRequired,
    status_last_updated: PropTypes.instanceOf(Date).isRequired,
    segment: PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      sector: PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
      }).isRequired,
    }),
  }).isRequired).isRequired,

  className: PropTypes.string.isRequired,
};

const StyledEmployerList = styled(EmployerList)`
.employer-aliases p {
  margin-bottom: 0;
}
.location-names {
  white-space: break-spaces;
}
`;

export default StyledEmployerList;
