import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import AdminNav from './components/admin-nav';

const AdminPageLayout = ({ children, className }) => (
  <div className={`admin-page ${className}`}>
    <AdminNav />
    <div>{ children }</div>
  </div>
);

AdminPageLayout.propTypes = {
  className: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

const StyledAdminPageLayout = styled(AdminPageLayout)`
  margin: 20px 0;
`;

export default StyledAdminPageLayout;
