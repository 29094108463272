import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Tab } from 'semantic-ui-react';

import { appActions } from '../../../actions';

import AdminPageLayout from '../layout';
import ProgramForm from './form';
import ProgramProfessions from './professions';
import ProgramTags from './program-tags';
import ProgramQuestions from './question';
import ProgramAdmissions from './admission';
import ProgramDuplicateModal from './duplicate-modal';

import PortalApi from '../../../services/PortalApi';
import ProgramRelatedSectors from './related-sectors';
import ProgramRelatedContacts from './related-contacts';

class ProgramEditPage extends React.PureComponent {
  state = {
    program: null,
    programDisciplines: [],
  }

  constructor(props) {
    super(props);
    this.updateProgramDisciplines = this.updateProgramDisciplines.bind(this);
  }

  async componentDidMount() {
    this.props.finishLoading();
    await this.loadProgram();
  }

  loadProgram = async () => {
    const program = await PortalApi.admin.getProgram(this.props.match.params.id);
    this.setState({
      program,
      programDisciplines: program.disciplines,
    });
  }

  updateProgramDisciplines(newDisciplines) {
    this.setState({
      programDisciplines: newDisciplines,
    });
  }

  render() {
    const { className } = this.props;

    if (!this.state.program) {
      return (
        <div className={className}>
          <AdminPageLayout>loading...</AdminPageLayout>
        </div>
      );
    }

    const tabPanes = [
      {
        menuItem: 'Contacts',
        render: () => (
          <ProgramRelatedContacts
            programId={this.state.program.id}
          />
        ),
      },
      {
        menuItem: 'Professions',
        render: () => (
          <ProgramProfessions
            programId={this.state.program.id}
            professionAssignments={this.state.program.profession_assignments}
            programDisciplines={this.state.programDisciplines}
          />
        ),
      },
      {
        menuItem: 'Program-specific Tags',
        render: () => (
          <ProgramTags
            programId={this.state.program.id}
            programTags={this.state.program.program_tags}
            programDisciplines={this.state.programDisciplines}
          />
        ),
      },
      {
        menuItem: 'Questions',
        render: () => (
          <ProgramQuestions
            programId={this.state.program.id}
            programQuestions={this.state.program.program_questions}
          />
        ),
      },
      {
        menuItem: 'Admissions',
        render: () => (
          <ProgramAdmissions
            programId={this.state.program.id}
            programAdmissions={this.state.program.program_admissions}
          />
        ),
      },
      {
        menuItem: 'Related Sectors',
        render: () => (
          <ProgramRelatedSectors
            programId={this.state.program.id}
            programDisciplines={this.state.programDisciplines}
          />
        ),
      },
    ];

    return (
      <div className={className}>
        <AdminPageLayout>
          <div className="main-content">
            <ProgramDuplicateModal id={this.state.program.id} />
            <h1>{ this.state.program.name }</h1>
            <ProgramForm
              mode="edit"
              program={this.state.program}
              updateProgramDisciplines={this.updateProgramDisciplines}
            />
            <hr />
            <Tab panes={tabPanes} />
          </div>
        </AdminPageLayout>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  finishLoading: () => dispatch(appActions.finishLoading()),
});

ProgramEditPage.propTypes = {
  finishLoading: PropTypes.func.isRequired,
  className: PropTypes.string.isRequired,

  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }).isRequired,
};

const StyledProgramEditPage = styled(ProgramEditPage)`
`;

export default connect(
  null,
  mapDispatchToProps,
)(StyledProgramEditPage);
