import React from 'react';
import {
  Button,
  Input,
  Form,
  Dropdown,
  TextArea,
} from 'semantic-ui-react';
import PropTypes from 'prop-types';
import uuidv1 from 'uuid/v1';

import PortalApi from '../../../services/PortalApi';
import Utils from '../../../lib/utils';

class ProgramQuestions extends React.PureComponent {
  state = {
    programQuestions: this.props.programQuestions.map(question => ({
      id: question.id,
      question: question.question,
      program_question_options: question.program_question_options.map(option => ({
        id: option.id,
        seq: option.seq,
        option: option.option,
        profession_id: option.profession_id,
        uuid: uuidv1(),
      })),
      seq: question.seq,
      uuid: uuidv1(),
    })),

    professionOptions: [],

    isSaving: false,
  }

  async componentDidMount() {
    this.loadProfessions();
  }

  loadProfessions = async () => {
    const professions = await PortalApi.admin.getProfessions();
    this.setState({
      professionOptions: professions.map(profession => ({
        key: profession.id,
        value: profession.id,
        text: profession.name,
      })),
    });
  }

  save = async () => {
    const body = this.state.programQuestions.map(question => ({
      id: question.id,
      question: question.question,
      seq: question.seq,
      program_question_options: question.program_question_options.map(option => ({
        id: option.id,
        option: option.option,
        seq: option.seq,
        profession_id: option.profession_id || null,
      })),
    }));

    const isValid = body.every(({ question, seq, program_question_options: options }) => {
      if (!question || !seq) {
        return false;
      }

      // eslint-disable-next-line no-restricted-syntax
      for (const option of options) {
        if (!option.option || !option.seq) {
          return false;
        }
      }

      return true;
    });

    if (!isValid) {
      Utils.toastAdminGeneralError(new Error('Some fields are not completed.'));
      return;
    }

    this.setState({
      isSaving: true,
    });

    try {
      const response = await PortalApi.admin.saveProgramQuestions(this.props.programId, body);
      Utils.toastAdminGeneralSuccess('Saved Questions & Options!');

      this.setState({
        programQuestions: response.map(question => ({
          id: question.id,
          question: question.question,
          program_question_options: question.program_question_options.map(option => ({
            id: option.id,
            seq: option.seq,
            option: option.option,
            profession_id: option.profession_id,
            uuid: uuidv1(),
          })),
          seq: question.seq,
          uuid: uuidv1(),
        })),
      });
    } catch (error) {
      if (error instanceof PortalApi.ApiError) {
        Utils.toastAdminApiError(error);
      } else {
        Utils.toastAdminGeneralError(error);
      }
    }

    this.setState({
      isSaving: false,
    });
  }

  handleQuestionChange = (uuid, key, value) => {
    this.setState(prevState => ({
      programQuestions: prevState.programQuestions
        .map(question => (question.uuid === uuid
          ? Object.assign({}, question, { [key]: value })
          : question)),
    }));
  }

  handleOptionChange = (questionUuid, optionUuid, key, value) => {
    this.setState(prevState => ({
      programQuestions: prevState.programQuestions
        .map((question) => {
          if (question.uuid !== questionUuid) {
            return question;
          }
          return {
            ...question,
            program_question_options: question.program_question_options
              .map(option => (option.uuid === optionUuid
                ? Object.assign({}, option, { [key]: value })
                : option)),
          };
        }),
    }));
  }

  removeQuestion = (uuid) => {
    this.setState(prevState => ({
      programQuestions: prevState.programQuestions.filter(q => q.uuid !== uuid),
    }));
  }

  addQuestion = () => {
    this.setState(prevState => ({
      programQuestions: [
        ...prevState.programQuestions,
        {
          id: null,
          question: '',
          program_question_options: [],
          seq: 99999,
          uuid: uuidv1(),
        },
      ],
    }));
  }

  removeOption = (questionUuid, optionUuid) => {
    this.setState(prevState => ({
      programQuestions: prevState.programQuestions
        .map((question) => {
          if (question.uuid !== questionUuid) {
            return question;
          }
          return {
            ...question,
            program_question_options: question.program_question_options
              .filter(option => (option.uuid !== optionUuid)),
          };
        }),
    }));
  }

  addOption = (questionUuid) => {
    this.setState(prevState => ({
      programQuestions: prevState.programQuestions
        .map((question) => {
          if (question.uuid !== questionUuid) {
            return question;
          }
          return {
            ...question,
            program_question_options: [
              ...question.program_question_options,
              {
                id: null,
                profession: null,
                option: '',
                seq: 99999,
                uuid: uuidv1(),
              },
            ],
          };
        }),
    }));
  }

  renderOptions = question => (
    <div style={{ marginLeft: '50px' }}>
      { question.program_question_options.map(option => (
        <Form.Group key={option.uuid}>
          <Form.Field width={4}>
            <TextArea
              required
              placeholder="Option"
              rows={1}
              onChange={(_, { value }) => { this.handleOptionChange(question.uuid, option.uuid, 'option', value); }}
              value={option.option}
            />
          </Form.Field>
          <Form.Field width={3}>
            <Dropdown
              fluid
              placeholder="Profession"
              options={this.state.professionOptions}
              selection
              lazyLoad
              search
              clearable
              onChange={(_, { value }) => { this.handleOptionChange(question.uuid, option.uuid, 'profession_id', value); }}
              value={option.profession_id}
            />
          </Form.Field>
          <Form.Field width={2}>
            <Input
              required
              fluid
              placeholder="Sequence (Ordering)"
              label="Sequence"
              labelPosition="left"
              onChange={(_, { value }) => { this.handleOptionChange(question.uuid, option.uuid, 'seq', value); }}
              type="number"
              value={option.seq}
            />
          </Form.Field>
          <Button
            negative
            onClick={() => { this.removeOption(question.uuid, option.uuid); }}
            type="button"
            content="Remove"
          />
        </Form.Group>
      )) }
      <Form.Group>
        <Button
          positive
          onClick={() => { this.addOption(question.uuid); }}
          type="button"
          content="Add Option"
        />
      </Form.Group>
    </div>
  )

  render() {
    return (
      <div>
        <h1>Questions &amp; Option</h1>
        <Form>
          { this.state.programQuestions.map(question => (
            <div key={question.uuid}>
              <Form.Group>
                <Form.Field width={8}>
                  <TextArea
                    required
                    placeholder="Question"
                    rows={1}
                    onChange={(_, { value }) => { this.handleQuestionChange(question.uuid, 'question', value); }}
                    value={question.question}
                  />
                </Form.Field>
                <Form.Field width={2}>
                  <Input
                    required
                    fluid
                    placeholder="Sequence (Ordering)"
                    label="Sequence"
                    labelPosition="left"
                    onChange={(_, { value }) => { this.handleQuestionChange(question.uuid, 'seq', value); }}
                    type="number"
                    value={question.seq}
                  />
                </Form.Field>
                <Button
                  negative
                  onClick={() => { this.removeQuestion(question.uuid); }}
                  type="button"
                  content="Remove"
                />
              </Form.Group>
              { this.renderOptions(question) }
            </div>
          )) }
          <div>
            <Button
              positive
              onClick={this.addQuestion}
              type="button"
              content="Add Question"
            />
          </div>
          <Button
            style={{ marginTop: '10px' }}
            content="Save All Questions"
            type="submit"
            onClick={this.save}
            loading={this.state.isSaving}
          />
        </Form>
      </div>
    );
  }
}

ProgramQuestions.propTypes = {
  programId: PropTypes.number.isRequired,

  programQuestions: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    question: PropTypes.string.isRequired,
    seq: PropTypes.number.isRequired,
    program_question_options: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number.isRequired,
      seq: PropTypes.number.isRequired,
      profession_id: PropTypes.number,
      option: PropTypes.string.isRequired,
    }).isRequired).isRequired,
  }).isRequired).isRequired,
};

export default ProgramQuestions;
