import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import ReactTable from 'react-table';
import { Link } from 'react-router-dom';

import { appActions } from '../../../actions';

import AdminPageLayout from '../layout';

import PortalApi from '../../../services/PortalApi';
import Utils from '../../../services/Utils';

class DisciplineIndexPage extends React.PureComponent {
  state = {
    disciplines: [],
  }

  async componentDidMount() {
    await this.loadDisciplines();
    this.props.finishLoading();
  }

  loadDisciplines = async () => {
    const disciplines = await PortalApi.admin.getDisciplines();
    this.setState({
      disciplines,
    });
  }

  renderDisciplines = () => {
    const { disciplines } = this.state;

    const columns = [
      {
        Header: '# ID',
        accessor: row => row.id.toString(),
        id: 'id',
        width: 100,
        Cell: ({ value: id }) => (
          <Link className="edit-link" to={`/admin/disciplines/${id}`}>
            {id}
          </Link>
        ),
      },
      {
        Header: 'Discipline',
        accessor: 'name',
        width: 500,
      },
      {
        Header: 'Discipline Group',
        id: 'discipline-group',
        accessor: row => row.discipline_group.name,
        width: 300,
        Cell: row => (
          <Link to={`/admin/discipline-groups/${row.original.discipline_group.id}`}>
            {row.original.discipline_group.name}
          </Link>
        ),
      },
      {
        Header: 'Default Professions',
        id: 'default',
        accessor: row => row.discipline_profession_assignments.filter(dpa => dpa.type === 'default').length,
        width: 200,
      },
      {
        Header: 'Professions in Related Sectors',
        id: 'faculty',
        accessor: row => row.discipline_profession_assignments.filter(dpa => dpa.type === 'faculty').length,
        width: 300,
      },
    ];

    return (
      <div className="disciplines-list">
        <ReactTable
          data={disciplines}
          columns={columns}
          defaultPageSize={20}
          pageSizeOptions={[10, 25, 50, 100]}
          filterable
          defaultFilterMethod={Utils.reactTableFilterInsensitive}
        />
      </div>
    );
  }

  render() {
    const { className } = this.props;

    return (
      <div className={className}>
        <AdminPageLayout>
          <div className="main-content">
            <h1>Disciplines</h1>
            { this.renderDisciplines() }
          </div>
        </AdminPageLayout>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  finishLoading: () => dispatch(appActions.finishLoading()),
});

DisciplineIndexPage.propTypes = {
  finishLoading: PropTypes.func.isRequired,
  className: PropTypes.string.isRequired,
};

const StyledDisciplineIndexPage = styled(DisciplineIndexPage)`
.discipline-names ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
`;

export default connect(
  null,
  mapDispatchToProps,
)(StyledDisciplineIndexPage);
