import React from 'react';
import {
  Button,
  Input,
  Form,
  Checkbox,
  Table,
} from 'semantic-ui-react';
import PropTypes from 'prop-types';
import uuidv1 from 'uuid/v1';
import * as moment from 'moment';

import PortalApi from '../../../services/PortalApi';
import Utils from '../../../lib/utils';

const scoringSchemeOptions = [
  { key: '4C+1X', text: '4C+1X', value: '4C+1X' },
  { key: '4C+2X', text: '4C+2X', value: '4C+2X' },
  { key: 'Best 5', text: 'Best 5', value: 'Best 5' },
  { key: 'Best 6', text: 'Best 6', value: 'Best 6' },
  { key: 'CE+3X', text: 'CE+3X', value: 'CE+3X' },
];

const interviewTypeOptions = [
  { key: 'For special cases only', text: 'For special cases only', value: 'For special cases only' },
  { key: 'May require', text: 'May require', value: 'May require' },
  { key: 'Not required', text: 'Not required', value: 'Not required' },
  { key: 'Selective', text: 'Selective', value: 'Selective' },
  { key: 'To be confirmed', text: 'To be confirmed', value: 'To be confirmed' },
  { key: 'Yes', text: 'Yes', value: 'Yes' },
];

const electiveRequirementOptions = [
  { key: '1 of the following subjects', text: '1 of the following subjects', value: '1 of the following subjects' },
  { key: '2 of the following subjects', text: '2 of the following subjects', value: '2 of the following subjects' },
  { key: 'Any 1 subject2', text: 'Any 1 subject2', value: 'Any 1 subject2' },
  { key: 'Any 1 subject3', text: 'Any 1 subject3', value: 'Any 1 subject3' },
  { key: 'Any 1 subjectAny 1 subject or or or 2', text: 'Any 1 subjectAny 1 subject or or or 2', value: 'Any 1 subjectAny 1 subject or or or 2' },
  { key: 'Any 1 subjectAny 1 subject or or or 3', text: 'Any 1 subjectAny 1 subject or or or 3', value: 'Any 1 subjectAny 1 subject or or or 3' },
  { key: 'Any 2 subjects3', text: 'Any 2 subjects3', value: 'Any 2 subjects3' },
  { key: 'Any 2 subjectsAny 2 subjects or or or 2', text: 'Any 2 subjectsAny 2 subjects or or or 2', value: 'Any 2 subjectsAny 2 subjects or or or 2' },
  { key: 'Any 2 subjectsAny 2 subjects or or or 3', text: 'Any 2 subjectsAny 2 subjects or or or 3', value: 'Any 2 subjectsAny 2 subjects or or or 3' },
  { key: 'Any 3 subjects2', text: 'Any 3 subjects2', value: 'Any 3 subjects2' },
  { key: 'Any 3 subjectsAny 3 subjects or or or 2', text: 'Any 3 subjectsAny 3 subjects or or or 2', value: 'Any 3 subjectsAny 3 subjects or or or 2' },
  { key: 'At least 15 points in best 5 subjects including 3 core subjects and all subjects at Level 2 or above', text: 'At least 15 points in best 5 subjects including 3 core subjects and all subjects at Level 2 or above', value: 'At least 15 points in best 5 subjects including 3 core subjects and all subjects at Level 2 or above' },
  { key: 'At least 15 points in best 5 subjects including English and all subjects (except Chinese) at Level 2 or above', text: 'At least 15 points in best 5 subjects including English and all subjects (except Chinese) at Level 2 or above', value: 'At least 15 points in best 5 subjects including English and all subjects (except Chinese) at Level 2 or above' },
  { key: 'At least 15 points in best 5 subjects with English and Mathematics at Level 3 or above, and all subjects at Level 2 or above', text: 'At least 15 points in best 5 subjects with English and Mathematics at Level 3 or above, and all subjects at Level 2 or above', value: 'At least 15 points in best 5 subjects with English and Mathematics at Level 3 or above, and all subjects at Level 2 or above' },
  { key: 'Biology or Chemistry or Physics or Com. Sc. or ICT or Design & Applied Tech.3', text: 'Biology or Chemistry or Physics or Com. Sc. or ICT or Design & Applied Tech.3', value: 'Biology or Chemistry or Physics or Com. Sc. or ICT or Design & Applied Tech.3' },
  { key: 'Biology or Chemistry or Physics or Com. Sc. or ICT3', text: 'Biology or Chemistry or Physics or Com. Sc. or ICT3', value: 'Biology or Chemistry or Physics or Com. Sc. or ICT3' },
  { key: 'Biology or Chemistry or Physics or Com. Sc. or Integrated Sc.3', text: 'Biology or Chemistry or Physics or Com. Sc. or Integrated Sc.3', value: 'Biology or Chemistry or Physics or Com. Sc. or Integrated Sc.3' },
  { key: 'Biology or Chemistry or Physics or Com. Sc. or Integrated Sc.4', text: 'Biology or Chemistry or Physics or Com. Sc. or Integrated Sc.4', value: 'Biology or Chemistry or Physics or Com. Sc. or Integrated Sc.4' },
  { key: 'Biology or Chemistry or Physics or Com. Sc.3', text: 'Biology or Chemistry or Physics or Com. Sc.3', value: 'Biology or Chemistry or Physics or Com. Sc.3' },
  { key: 'Biology or Chemistry or Physics or Integrated Sc. or Com. Sc. or M1/M22', text: 'Biology or Chemistry or Physics or Integrated Sc. or Com. Sc. or M1/M22', value: 'Biology or Chemistry or Physics or Integrated Sc. or Com. Sc. or M1/M22' },
  { key: 'Biology3', text: 'Biology3', value: 'Biology3' },
  { key: 'BiologyBiology or or or 2', text: 'BiologyBiology or or or 2', value: 'BiologyBiology or or or 2' },
  { key: 'ICT2', text: 'ICT2', value: 'ICT2' },
  { key: 'M1/M22', text: 'M1/M22', value: 'M1/M22' },
  { key: 'M1/M23', text: 'M1/M23', value: 'M1/M23' },
  { key: 'M1/M24', text: 'M1/M24', value: 'M1/M24' },
  { key: 'null', text: 'null', value: 'null' },
  { key: 'or Biology or Chemistry or Physics or M1 or M22', text: 'or Biology or Chemistry or Physics or M1 or M22', value: 'or Biology or Chemistry or Physics or M1 or M22' },
  { key: 'or Biology or Chemistry2', text: 'or Biology or Chemistry2', value: 'or Biology or Chemistry2' },
  { key: 'or Maths. (comp.) or Physics or Chemistry or Biology or Com. Sc. or Integrated Sc.2', text: 'or Maths. (comp.) or Physics or Chemistry or Biology or Com. Sc. or Integrated Sc.2', value: 'or Maths. (comp.) or Physics or Chemistry or Biology or Com. Sc. or Integrated Sc.2' },
  { key: 'or Physics or Chemistry or Biology or Com. Sc. or Integrated Sc.2', text: 'or Physics or Chemistry or Biology or Com. Sc. or Integrated Sc.2', value: 'or Physics or Chemistry or Biology or Com. Sc. or Integrated Sc.2' },
  { key: 'or Physics or Chemistry or Biology or Com. Sc. or Integrated Sc.3', text: 'or Physics or Chemistry or Biology or Com. Sc. or Integrated Sc.3', value: 'or Physics or Chemistry or Biology or Com. Sc. or Integrated Sc.3' },
  { key: 'Physics4', text: 'Physics4', value: 'Physics4' },
  { key: 'Preferable elective', text: 'Preferable elective', value: 'Preferable elective' },
];

class ProgramAdmissions extends React.PureComponent {
  state = {
    programAdmissions: this.props.programAdmissions.map(admission => ({
      ...admission,
      uuid: uuidv1(),
    })),

    isSaving: false,
  }

  save = async () => {
    const body = this.state.programAdmissions.map(admission => ({
      id: admission.id,
      program_id: admission.program_id,
      academic_year: admission.academic_year,
      asi_median: admission.asi_median,
      asi_median_scaled: admission.asi_median_scaled,
      asi_lower_quartile: admission.asi_lower_quartile,
      asi_lower_quartile_scaled: admission.asi_lower_quartile_scaled,
      asi_upper_quartile: admission.asi_upper_quartile,
      asi_upper_quartile_scaled: admission.asi_upper_quartile_scaled,
      asi_lower_bound: admission.asi_lower_bound,
      asi_lower_bound_scaled: admission.asi_lower_bound_scaled,
      asi_upper_bound: admission.asi_upper_bound,
      asi_upper_bound_scaled: admission.asi_upper_bound_scaled,
      asi_average: admission.asi_average,
      asi_average_scaled: admission.asi_average_scaled,
      number_of_places: admission.number_of_places,
      number_of_band_a_offers: admission.number_of_band_a_offers,
      scoring_scheme: admission.scoring_scheme || null,
      interview_type: admission.interview_type || null,
      elective_requirement: admission.elective_requirement || null,
      is_elective_m1m2: admission.is_elective_m1m2,
      is_elective_alp: admission.is_elective_alp,
      is_elective_other_lang: admission.is_elective_other_lang,
      application_period_starts_at: admission.application_period_starts_at || null,
      application_period_ends_at: admission.application_period_ends_at || null,
      is_eapp: admission.is_eapp,
      is_non_jupas: admission.is_non_jupas,
      is_vpet: admission.is_vpet,
      created_at: admission.created_at,
      updated_at: admission.updated_at,
      band_a_comp_ratio: admission.band_a_comp_ratio,
      band_a_ratio: admission.band_a_ratio,
    }));

    this.setState({
      isSaving: true,
    });

    try {
      const response = await PortalApi.admin.saveProgramAdmissions(this.props.programId, body);
      Utils.toastAdminGeneralSuccess('Saved Admission!');

      this.setState({
        programAdmissions: response.map(admission => ({
          ...admission,
          uuid: uuidv1(),
        })),
      });
    } catch (error) {
      if (error instanceof PortalApi.ApiError) {
        Utils.toastAdminApiError(error);
      } else {
        Utils.toastAdminGeneralError(error);
      }
    }

    this.setState({
      isSaving: false,
    });
  }

  handleAdmissionChange = (uuid, key, value) => {
    this.setState(prevState => ({
      programAdmissions: prevState.programAdmissions
        .map(admission => (admission.uuid === uuid
          ? Object.assign({}, admission, { [key]: value })
          : admission)),
    }));
  }

  removeAdmission = (uuid) => {
    this.setState(prevState => ({
      programAdmissions: prevState.programAdmissions.filter(q => q.uuid !== uuid),
    }));
  }

  addAdmission = () => {
    this.setState(prevState => ({
      programAdmissions: [
        ...prevState.programAdmissions,
        {
          id: null,
          program_id: null,
          academic_year: '',
          asi_median: 0.0,
          asi_median_scaled: 0.0,
          asi_lower_quartile: 0.0,
          asi_lower_quartile_scaled: 0.0,
          asi_upper_quartile: 0.0,
          asi_upper_quartile_scaled: 0.0,
          asi_lower_bound: 0.0,
          asi_lower_bound_scaled: 0.0,
          asi_upper_bound: 0.0,
          asi_upper_bound_scaled: 0.0,
          asi_average: 0.0,
          asi_average_scaled: 0.0,
          number_of_places: 0,
          number_of_band_a_offers: 0,
          scoring_scheme: '',
          interview_type: '',
          elective_requirement: '',
          is_elective_m1m2: false,
          is_elective_alp: false,
          is_elective_other_lang: false,
          application_period_starts_at: '',
          application_period_ends_at: '',
          is_eapp: false,
          is_non_jupas: false,
          is_vpet: false,
          band_a_comp_ratio: 0.0,
          band_a_ratio: 0.0,
          created_at: '',
          updated_at: '',
          uuid: uuidv1(),
        },
      ],
    }));
  }

  getAdmissionFormField = (admission, field) => {
    const getOptions = (arr, newValue) => {
      if (arr.find(opt => opt.value === newValue)) return arr;
      return arr.concat({ text: newValue, value: newValue });
    };

    if (['asi_median', 'asi_median_scaled', 'asi_lower_quartile', 'asi_lower_quartile_scaled', 'asi_upper_quartile', 'asi_upper_quartile_scaled', 'asi_lower_bound', 'asi_lower_bound_scaled', 'asi_upper_bound', 'asi_upper_bound_scaled', 'asi_average', 'asi_average_scaled', 'number_of_places', 'number_of_band_a_offers', 'band_a_comp_ratio', 'band_a_ratio'].includes(field)) {
      return (
        <Form.Field
          size="mini"
          fluid
          control={Input}
          placeholder={field}
          type="text"
          value={admission[field] || ''}
          onChange={(_, { value }) => { this.handleAdmissionChange(admission.uuid, field, value); }}
        />
      );
    }
    if (['is_elective_m1m2', 'is_elective_alp', 'is_elective_other_lang', 'is_eapp', 'is_non_jupas', 'is_vpet'].includes(field)) {
      return (
        <Form.Field
          control={Checkbox}
          checked={admission[field]}
          onChange={(_, { checked }) => { this.handleAdmissionChange(admission.uuid, field, checked); }}
        />
      );
    }
    if (['application_period_starts_at', 'application_period_ends_at'].includes(field)) {
      const dateVal = admission[field] ? moment(admission[field]).format('YYYY-MM-DD') : '';
      return (
        <Form.Field
          placeholder={field}
          control={Input}
          type="date"
          value={dateVal}
          onChange={(_, { value }) => { this.handleAdmissionChange(admission.uuid, field, value); }}
        />
      );
    }
    if (field === 'scoring_scheme') {
      return (
        <Form.Dropdown
          options={getOptions(scoringSchemeOptions, admission[field])}
          selection
          search
          allowAdditions
          value={admission[field] || ''}
          onChange={(_, { value }) => { this.handleAdmissionChange(admission.uuid, field, value); }}
        />
      );
    }
    if (field === 'interview_type') {
      return (
        <Form.Dropdown
          options={getOptions(interviewTypeOptions, admission[field])}
          selection
          search
          allowAdditions
          value={admission[field] || ''}
          onChange={(_, { value }) => { this.handleAdmissionChange(admission.uuid, field, value); }}
        />
      );
    }
    if (field === 'elective_requirement') {
      return (
        <Form.Dropdown
          options={getOptions(electiveRequirementOptions, admission[field])}
          selection
          search
          allowAdditions
          value={admission[field] || ''}
          onChange={(_, { value }) => { this.handleAdmissionChange(admission.uuid, field, value); }}
        />
      );
    }
    return (
      <Form.Field
        fluid
        size="mini"
        placeholder={field}
        control={Input}
        value={admission[field] || ''}
        onChange={(_, { value }) => { this.handleAdmissionChange(admission.uuid, field, value); }}
      />
    );
  }

  render() {
    return (
      <div>
        <h1>Admissions</h1>
        <div style={{ overflowX: 'scroll' }}>
          <Table compact celled structured unstackable>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell width={1} rowSpan="2"></Table.HeaderCell>
                <Table.HeaderCell width={3} rowSpan="2">Academic Year</Table.HeaderCell>
                <Table.HeaderCell width={12} colSpan="12">ASI (Admission score index, created and used by bigexam, scaled means times the number of subjects listed in the scoring scheme, default number of subjects for ipass programs is 5)</Table.HeaderCell>
                <Table.HeaderCell rowSpan="2">Number of Places</Table.HeaderCell>
                <Table.HeaderCell rowSpan="2">Number of Band A Offers</Table.HeaderCell>
                <Table.HeaderCell rowSpan="2">Scoring Scheme</Table.HeaderCell>
                <Table.HeaderCell rowSpan="2">Interview Type</Table.HeaderCell>
                <Table.HeaderCell rowSpan="2">Elective Requirement</Table.HeaderCell>
                <Table.HeaderCell rowSpan="2">Is m1m2 elective?</Table.HeaderCell>
                <Table.HeaderCell rowSpan="2">Are alp (The Category B, Applied Learning Subjects) electives?</Table.HeaderCell>
                <Table.HeaderCell rowSpan="2">Are The Category C, Other Language subjects electives?</Table.HeaderCell>
                <Table.HeaderCell rowSpan="2">Application Period Starts At</Table.HeaderCell>
                <Table.HeaderCell rowSpan="2">Application Period Ends At</Table.HeaderCell>
                <Table.HeaderCell rowSpan="2">Is E-APP?</Table.HeaderCell>
                <Table.HeaderCell rowSpan="2">Is non-JUPAS?</Table.HeaderCell>
                <Table.HeaderCell rowSpan="2">Is VPET?</Table.HeaderCell>
                <Table.HeaderCell rowSpan="2">Band A Comp Ratio</Table.HeaderCell>
                <Table.HeaderCell rowSpan="2">Band A Ratio</Table.HeaderCell>
              </Table.Row>
              <Table.Row>
                <Table.HeaderCell>Median</Table.HeaderCell>
                <Table.HeaderCell>Med scaled</Table.HeaderCell>
                <Table.HeaderCell>Lower Q</Table.HeaderCell>
                <Table.HeaderCell>Q1 scaled</Table.HeaderCell>
                <Table.HeaderCell>Upper Q</Table.HeaderCell>
                <Table.HeaderCell>Q3 scaled</Table.HeaderCell>
                <Table.HeaderCell>Lower B</Table.HeaderCell>
                <Table.HeaderCell>LB scaled</Table.HeaderCell>
                <Table.HeaderCell>Upper B</Table.HeaderCell>
                <Table.HeaderCell>UB scaled</Table.HeaderCell>
                <Table.HeaderCell>Average</Table.HeaderCell>
                <Table.HeaderCell>AVG scaled</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              { this.state.programAdmissions.map(admission => (
                <Table.Row key={admission.uuid}>
                  <Table.Cell>
                    <Button icon="trash" negative onClick={() => { this.removeAdmission(admission.uuid); }} />
                  </Table.Cell>
                  { Object.keys(admission).filter(field => !['id', 'program_id', 'uuid', 'created_at', 'updated_at'].includes(field)).map(field => (
                    <Table.Cell key={field}>
                      {this.getAdmissionFormField(admission, field)}
                    </Table.Cell>
                  ))}
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </div>
        <div>
          <Button
            positive
            onClick={() => { this.addAdmission(); }}
            icon="add"
            type="button"
            content="Add Admission"
          />
          <Button
            icon="save"
            type="button"
            content="Save All"
            onClick={this.save}
            loading={this.state.isSaving}
          />
        </div>
      </div>
    );
  }
}

ProgramAdmissions.propTypes = {
  programId: PropTypes.number.isRequired,

  programAdmissions: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    program_id: PropTypes.number.isRequired,
    academic_year: PropTypes.string,
    asi_median: PropTypes.number,
    asi_median_scaled: PropTypes.number,
    asi_lower_quartile: PropTypes.number,
    asi_lower_quartile_scaled: PropTypes.number,
    asi_upper_quartile: PropTypes.number,
    asi_upper_quartile_scaled: PropTypes.number,
    asi_lower_bound: PropTypes.number,
    asi_lower_bound_scaled: PropTypes.number,
    asi_upper_bound: PropTypes.number,
    asi_upper_bound_scaled: PropTypes.number,
    asi_average: PropTypes.number,
    asi_average_scaled: PropTypes.number,
    number_of_places: PropTypes.number,
    number_of_band_a_offers: PropTypes.number,
    scoring_scheme: PropTypes.string,
    interview_type: PropTypes.string,
    elective_requirement: PropTypes.string,
    is_elective_m1m2: PropTypes.bool,
    is_elective_alp: PropTypes.bool,
    is_elective_other_lang: PropTypes.bool,
    application_period_starts_at: PropTypes.string,
    application_period_ends_at: PropTypes.string,
    is_eapp: PropTypes.bool.isRequired,
    is_non_jupas: PropTypes.bool.isRequired,
    is_vpet: PropTypes.bool.isRequired,
    band_a_comp_ratio: PropTypes.number,
    band_a_ratio: PropTypes.number,
  }).isRequired).isRequired,
};

export default ProgramAdmissions;
