/* eslint-disable jsx-a11y/label-has-for */
import React from 'react';
import {
  Button,
  Form,
  Input,
  TextArea,
  Checkbox,
  Icon,
  Label,
  Segment,
} from 'semantic-ui-react';
import { Formik, Field, FieldArray } from 'formik';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/semantic-ui.css';

import SelectField from '../components/select-field';

import PortalApi from '../../../services/PortalApi';
import Utils from '../../../lib/utils';


class ContactForm extends React.PureComponent {
  state = {
    entityOptions: null,
    isInitializing: true,
    contactTags: [],
    selectedContactTagIds: new Set(this.props.contactData.contact_tags.map(tag => tag.id)),
    relationOptions: {
      program: [], sector: [], segment: [], profession: [],
    },
    companyOptions: [],
  }

  async componentDidMount() {
    await this.loadEntityOptions();
    await this.loadAllContactTags();
    await this.loadContactRelationOptions();
    await this.loadCompanyOptions();
    this.setState({ isInitializing: false });
  }

  loadAllContactTags = async () => {
    const contactTags = await PortalApi.admin.getContactTags();
    this.setState({
      contactTags,
    });
  }

  loadEntityOptions = async () => {
    const entityOptions = await PortalApi.admin.getContactRelationEntityOptions();
    // insert a blank option
    // although formik UI auto select the first item
    // it does not auto set the state
    Object.keys(entityOptions).forEach((key) => {
      entityOptions[key].unshift({ id: '', name: '' });
    });
    this.setState({ entityOptions });
  }

  loadContactRelationOptions = async () => {
    const existingRelationOptions = await PortalApi.admin.getContactRelationRelationOptions();
    this.setState({ relationOptions: existingRelationOptions });
  }

  loadCompanyOptions = async () => {
    const companies = await PortalApi.admin.getContactRelationCompanyOptions();
    this.setState({ companyOptions: companies.map(c => ({ key: c, text: c, value: c })) });
  }

  handleSubmit = async (values, { setSubmitting }) => {
    setSubmitting(true);

    const params = {
      name: values.name,
      salutation: values.salutation,
      email: values.email,
      source: values.source || '',
      source_link: values.source_link || '',
      whatsapp_number: values.whatsapp_number || '',
      phone_number: values.phone_number || '',
      is_bluebird_partner: values.is_bluebird_partner,
      remarks: values.remarks || '',

      contact_relations: values.contact_relations.map(relationItem => ({
        entity_id: Number(relationItem.entity_id),
        entity_type: relationItem.entity_type,
        relation: relationItem.relation,
        org: relationItem.org,
        whatsapp_id: relationItem.whatsapp_id,
      })),

      contact_tags: [...this.state.selectedContactTagIds].map(id => ({
        id,
      })),
    };

    try {
      if (this.props.mode === 'create') {
        await this.createContact(params);
      } else if (this.props.mode === 'edit') {
        await this.updateContact(params);
      }
    } catch (error) {
      if (error instanceof PortalApi.ApiError) {
        Utils.toastAdminApiError(error);
      } else {
        Utils.toastAdminGeneralError(error);
      }
    }

    setSubmitting(false);
  }

  createContact = async (params) => {
    const contact = await PortalApi.admin.createContact(params);
    Utils.toastAdminGeneralSuccess(`Created contact id: ${contact.id}`);
    await Utils.sleep(1);
    this.props.history.push('/admin/contacts');
  }

  updateContact = async (params) => {
    const contact = await PortalApi.admin.updateContact(this.props.id, params);
    Utils.toastAdminGeneralSuccess(`Updated contact id: ${contact.id}`);
  }

  handleAddRelation = (entityType, value) => {
    this.setState(prevState => ({
      relationOptions: {
        [entityType]: [{ text: value, value }, ...(prevState.relationOptions[entityType] || [])],
      },
    }));
  }

  handleAddCompany = (value) => {
    this.setState(prevState => ({
      companyOptions: [{ text: value, value }, ...(prevState.companyOptions || [])],
    }));
  }

  render() {
    if (this.state.isInitializing) {
      return null;
    }

    const inputConfigs = [
      { name: 'name', label: 'Name', isRequired: true },
      { name: 'salutation', label: 'Salutation', isRequired: true },
      {
        name: 'email', label: 'Email', isRequired: true,
      },
      { name: 'source', label: 'Source Name' },
      { name: 'source_link', label: 'Source Link' },
      { name: 'whatsapp_number', label: 'Whatsapp Number' },
      { name: 'phone_number', label: 'Phone Number' },
      { name: 'is_bluebird_partner', label: 'Is Bluebird Partner?'},
      {
        name: 'remarks', label: 'Remarks', control: TextArea, fluid: null,
      },
    ];

    const entityTypeOptions = Object.keys(this.state.entityOptions).map(entityType => ({
      key: entityType,
      text: entityType,
      value: entityType,
    }));

    const relationValueOptions = Object.keys(this.state.entityOptions).reduce((acc, entityType) => {
      acc[entityType] = this.state.entityOptions[entityType].map(entityItem => ({
        key: entityItem.id.toString(),
        text: entityItem.display_name || entityItem.name,
        value: entityItem.id.toString(),
      }));
      return acc;
    }, {});

    const defaultNewRelationObj = {
      entity_id: '',
      entity_type: Object.keys(this.state.entityOptions)[0],
      relation: '',
      org: '',
    };

    return (
      <Formik
        initialValues={this.props.contactData}
        onSubmit={this.handleSubmit}
      >
        {({ values, handleSubmit, isSubmitting }) => (
          <Form className={this.props.className}>
            { inputConfigs.map(inputConfig => (
              <React.Fragment key={inputConfig.name}>
                <Field name={inputConfig.name}>
                  {({
                    field, form,
                  }) => (
                    ['is_bluebird_partner'].includes(inputConfig.name)
                    ? (
                      <Form.Field
                        control={Checkbox}
                        label={inputConfig.label}
                        checked={form.values[inputConfig.name] }
                        onChange={(_, { checked }) => { form.setFieldValue( field.name, checked ); }}                        
                      />
                      ) : ( ['phone_number', 'whatsapp_number'].includes(inputConfig.name)
                          ? (
                              <Form.Field key={inputConfig.name}>
                                <label htmlFor={inputConfig.name}>{inputConfig.label}</label>
                                <PhoneInput
                                  placeholder={inputConfig.label}
                                  inputProps={{ id: inputConfig.name }}
                                  inputStyle={{ paddingLeft: '45px' }}
                                  onlyCountries={['hk', 'gb', 'us', 'cn', 'sg']}
                                  country="hk"
                                  value={form.values[inputConfig.name] || ''}
                                  onChange={(phone) => { form.setFieldValue(field.name, phone); }}
                                />
                              </Form.Field>
                            ) : (
                              <Form.Field
                                type={inputConfig.type || 'text'}
                                id={inputConfig.name}
                                label={inputConfig.label}
                                placeholder={inputConfig.label}
                                control={inputConfig.control || Input}
                                required={!!inputConfig.isRequired}
                                fluid={inputConfig.fluid === null ? undefined : true}
                                rows={5}
                                {...field}
                              />
                            )
                         )
                  )}
                </Field>
              </React.Fragment>
            ))}
            <hr />

            <h2>Contact Relations</h2>
            <FieldArray
              name="contact_relations"
              render={arrayHelpers => (
                <div>
                  { values.contact_relations.map((relation, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <Segment padded="very">
                      <Form.Group widths="equal" key={index}>
                        <div className="field">
                          <SelectField
                            fieldName={`contact_relations.${index}.entity_type`}
                            options={entityTypeOptions}
                          />
                        </div>

                        <Field name={`contact_relations.${index}.entity_id`}>
                          {({
                            field, form,
                          }) => (
                            <Form.Dropdown
                              options={relationValueOptions[relation.entity_type]}
                              placeholder={relation.entity_type}
                              search
                              selection
                              fluid
                              value={form.values.contact_relations[index].entity_id || ''}
                              onChange={(_, { value }) => { form.setFieldValue(field.name, value); }}
                            />
                          )}
                        </Field>

                        <Field name={`contact_relations.${index}.org`}>
                          {({
                            field, form,
                          }) => (
                            <Form.Dropdown
                              options={this.state.companyOptions || []}
                              placeholder="Organization / Company"
                              search
                              selection
                              fluid
                              allowAdditions
                              value={form.values.contact_relations[index].org || ''}
                              onAddItem={(_, { value }) => { this.handleAddCompany(value); }}
                              onChange={(_, { value }) => { form.setFieldValue(field.name, value); }}
                            />
                          )}
                        </Field>
                        <Field name={`contact_relations.${index}.relation`}>
                          {({
                            field, form,
                          }) => (
                            <Form.Dropdown
                              options={this.state.relationOptions[relation.entity_type] || []}
                              placeholder="Relation / Title"
                              search
                              selection
                              fluid
                              allowAdditions
                              value={form.values.contact_relations[index].relation || ''}
                              onAddItem={(_, { value }) => { this.handleAddRelation(relation.entity_type, value); }}
                              onChange={(_, { value }) => { form.setFieldValue(field.name, value); }}
                            />
                          )}
                        </Field>
                        <Field name={`contact_relations.${index}.whatsapp_id`}>
                          {({
                            field,
                          }) => (
                            <Form.Field
                              type="text"
                              id={`contact_relations.${index}.whatsapp_id`}
                              placeholder="WhatsApp Group ID"
                              control={Input}
                              fluid
                              {...field}
                            />
                          )}
                        </Field>

                        { !!relation.whatsapp_group_name && (
                          <Label attached="top left" as="a" color="green" href={relation.whatsapp_group_invite_link} target="_blank">
                            <Icon name="whatsapp" />
                            { relation.whatsapp_group_name }
                          </Label>
                        )}
                        { !!relation.entity_id && (
                          <Label attached="top right" as="a" color="blue" href={`/admin/${relation.entity_type}s/${relation.entity_id}`} target="_blank">
                            <Icon name="external" />
                            { `${relation.entity_type}s/${relation.entity_id}` }
                          </Label>
                        )}
                        { !relation.whatsapp_id && (
                          <Form.Field
                            control={Button}
                            type="button"
                            negative
                            onClick={() => arrayHelpers.remove(index)}
                          >
                            Remove
                          </Form.Field>
                        )}
                      </Form.Group>
                    </Segment>
                  )) }

                  <Form.Field
                    control={Button}
                    type="button"
                    positive
                    onClick={() => { arrayHelpers.push({ ...defaultNewRelationObj }); }}
                  >
                    Add
                  </Form.Field>
                </div>
              )}
            />
            <hr />
            <h2>Contact Tags</h2>
            { this.state.contactTags.map(contactTag => (
              <Checkbox
                key={contactTag.id}
                name="contact_tags"
                label={contactTag.name}
                value={contactTag.id}
                className="contact-tag-checkbox"
                checked={this.state.selectedContactTagIds.has(contactTag.id)}
                onChange={(_, { checked }) => {
                  this.setState((prevState) => {
                    const newSet = new Set(prevState.selectedContactTagIds);
                    if (checked) {
                      newSet.add(contactTag.id);
                    } else {
                      newSet.delete(contactTag.id);
                    }
                    return { selectedContactTagIds: newSet };
                  });
                }}
              />
            ))}
            <hr />
            <Form.Field control={Button} type="submit" disabled={isSubmitting} loading={isSubmitting} onClick={handleSubmit} content="Save" />
          </Form>
        )}
      </Formik>
    );
  }
}

ContactForm.propTypes = {
  className: PropTypes.string.isRequired,

  mode: PropTypes.string.isRequired,
  contactData: PropTypes.shape({
    contact_tags: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
    })),
  }),
  id: PropTypes.number,

  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

ContactForm.defaultProps = {
  contactData: {
    name: '',
    salutation: '',
    email: '',
    source: '',
    source_link: '',
    whatsapp_number: '',
    phone_number: '',
    is_bluebird_partner: false,
    remarks: '',
    contact_relations: [],
    contact_tags: [],
  },
  id: null,
};

const StyledContactForm = styled(ContactForm)`
.contact-tag-checkbox {
  margin-right: 20px;
}
`;

export default withRouter(StyledContactForm);
