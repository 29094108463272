import React from 'react';
import {
  Button,
  Input,
  Form,
} from 'semantic-ui-react';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';

import PortalApi from '../../../services/PortalApi';

class DisciplineGroupForm extends React.PureComponent {
  state = {
    ...this.props.disciplineGroup,
    isSaving: false,
  }

  validateParams = params => (
    ['name', 'seq'].every(key => Boolean(params[key]))
  )

  handleSaveClick = async () => {
    const bodyParams = {
      name: this.state.name,
      seq: this.state.seq,
    };

    if (!this.validateParams(bodyParams)) {
      toast.error('Please complete the information', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 2000,
      });
      return;
    }

    this.setState({
      isSaving: true,
    });

    let disciplineGroup;
    if (this.props.mode === 'create') {
      disciplineGroup = await this.createDisciplineGroup(bodyParams);
    } else if (this.props.mode === 'edit') {
      disciplineGroup = await this.editDisciplineGroup(bodyParams);
    }

    this.setState({
      isSaving: false,
    });

    toast.success(`Saved ${disciplineGroup.name} (id: ${disciplineGroup.id})`, {
      position: toast.POSITION.BOTTOM_CENTER,
      autoClose: 5000,
    });
  }

  createDisciplineGroup = async (bodyParams) => {
    const disciplineGroup = await PortalApi.admin.createDisciplineGroup(bodyParams);

    this.setState({
      ...DisciplineGroupForm.DEFAULT_FORM_STATE,
    });
    return disciplineGroup;
  }

  editDisciplineGroup = async (bodyParams) => {
    const disciplineGroup = await PortalApi.admin.saveDisciplineGroup(this.state.id, bodyParams);
    return disciplineGroup;
  }

  render() {
    return (
      <Form>
        <Form.Field
          control={Input}
          label="Discipline Group Name"
          placeholder="Discipline Group Name"
          value={this.state.name}
          onChange={(_, { value }) => { this.setState({ name: value }); }}
          required
        />
        <Form.Field
          control={Input}
          label="Sequence (Ordering)"
          placeholder="Sequence (Ordering)"
          value={this.state.seq}
          onChange={(_, { value }) => { this.setState({ seq: value }); }}
          type="number"
          required
        />
        <Form.Field
          control={Button}
          content="Save"
          loading={this.state.isSaving}
          onClick={this.handleSaveClick}
        />
      </Form>
    );
  }
}

DisciplineGroupForm.DEFAULT_FORM_STATE = {
  id: null,
  name: '',
  seq: 9999,
};

DisciplineGroupForm.propTypes = {
  mode: PropTypes.string.isRequired,
  disciplineGroup: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    seq: PropTypes.number,
  }),
};

DisciplineGroupForm.defaultProps = {
  disciplineGroup: DisciplineGroupForm.DEFAULT_FORM_STATE,
};

export default DisciplineGroupForm;
