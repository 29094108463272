import React from 'react';
import {
  Button,
  Input,
  Form,
  Dropdown,
} from 'semantic-ui-react';
import PropTypes from 'prop-types';
import uuidv1 from 'uuid/v1';

import PortalApi from '../../../services/PortalApi';
import Utils from '../../../lib/utils';

class DisciplineProfessions extends React.PureComponent {
  state = {
    professionOptions: [],

    professionAssignments: this.props.professionAssignments.map(assignment => ({
      profession_id: assignment.profession_id,
      seq: assignment.seq,
      type: assignment.type,
      uuid: uuidv1(),
    })),

    isSaving: false,
  }

  async componentDidMount() {
    await this.loadProfessions();
  }

  loadProfessions = async () => {
    const professions = await PortalApi.admin.getProfessions();
    this.setState({
      professionOptions: professions.map(profession => ({
        key: profession.id,
        value: profession.id,
        text: profession.name,
      })),
    });
  }

  handleProfessionAssignmentChange = (uuid, key, value) => {
    this.setState(prevState => ({
      professionAssignments: prevState.professionAssignments.map((assignment) => {
        if (assignment.uuid === uuid) {
          return {
            ...assignment,
            [key]: value,
          };
        }
        return { ...assignment };
      }),
    }));
  }

  removeItem = (uuid) => {
    this.setState(prevState => ({
      professionAssignments: prevState.professionAssignments
        .filter(assignment => assignment.uuid !== uuid),
    }));
  }

  addItem = (type) => {
    this.setState(prevState => ({
      professionAssignments: [
        ...prevState.professionAssignments,
        {
          profession_id: null,
          seq: 9999,
          uuid: uuidv1(),
          type,
        },
      ],
    }));
  }

  save = async () => {
    const body = this.state.professionAssignments.map(assignment => ({
      profession_id: assignment.profession_id,
      seq: assignment.seq,
      type: assignment.type,
    }));

    const isValid = body.every(assignment => !!assignment.profession_id && !!assignment.seq);
    if (!isValid) {
      Utils.toastAdminGeneralError(new Error('Profession and Sequence are required'));
      return;
    }

    this.setState({
      isSaving: true,
    });

    try {
      await PortalApi.admin.saveDisciplineProfessionAssignments(this.props.disciplineId, body);
      Utils.toastAdminGeneralSuccess('Saved Professions!');
    } catch (error) {
      if (error instanceof PortalApi.ApiError) {
        Utils.toastAdminApiError(error);
      } else {
        Utils.toastAdminGeneralError(error);
      }
    }

    this.setState({
      isSaving: false,
    });
  }

  renderProfessionAssignments = (type) => {
    const components = this.state.professionAssignments
      .filter(assignment => assignment.type === type)
      .map(assignment => (
        <Form.Group key={assignment.uuid}>
          <Form.Field
            control={Dropdown}
            inline
            width={4}
            fluid
            options={this.state.professionOptions}
            placeholder="Profession"
            selection
            required
            lazyLoad
            search
            value={assignment.profession_id}
            onChange={(_, { value }) => { this.handleProfessionAssignmentChange(assignment.uuid, 'profession_id', value); }}
          />
          <Form.Field
            control={Input}
            inline
            width={2}
            defaultValue={assignment.seq}
            required
            placeholder="Sequence (Ordering)"
            type="number"
            onChange={(_, { value }) => { this.handleProfessionAssignmentChange(assignment.uuid, 'seq', value); }}
          />
          <Button
            negative
            onClick={() => { this.removeItem(assignment.uuid); }}
            type="button"
            content="Remove"
          />
        </Form.Group>
      ));

    return (
      <div>
        { components }
        <Button
          positive
          onClick={() => { this.addItem(type); }}
          type="button"
          content="Add More"
        />
      </div>
    );
  }

  render() {
    return (
      <Form>
        <h1>Professions</h1>
        <h3>Default Professions</h3>
        { this.renderProfessionAssignments('default') }

        <h3>Professions in Related Sectors</h3>
        { this.renderProfessionAssignments('faculty') }
        <Button
          style={{ marginTop: '10px' }}
          content="Save"
          type="submit"
          onClick={this.save}
          loading={this.state.isSaving}
        />
      </Form>
    );
  }
}

DisciplineProfessions.propTypes = {
  disciplineId: PropTypes.number.isRequired,

  professionAssignments: PropTypes.arrayOf(PropTypes.shape({
    profession_id: PropTypes.number.isRequired,
    seq: PropTypes.number.isRequired,
    type: PropTypes.string.isRequired,
  }).isRequired).isRequired,
};

export default DisciplineProfessions;
