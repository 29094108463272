import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { appActions } from '../actions';

import ProgramRow from '../components/program-row';
import ProfessionColumn from '../components/profession-column';
import ContentColumn from '../components/content-column';

import PortalApi from '../services/PortalApi';
import Utils from '../services/Utils';

class MainApp extends React.PureComponent {
  state = {
    statistics: {
      total: {
        profession: '...',
        employer: '...',
      },
      program: {
        profession: '...',
        employer: '...',
      },
    },
    // program:
    professionsByGroupAndTag: {},
    relatedSectors: [],
    selectedGroup: 'default',
  }

  changeSelectedGroup = (selectedGroup) => {
    this.setState({
      selectedGroup,
    });
  }

  async componentDidMount() {
    await this.loadProgram();
  }

  async componentDidUpdate(prevProps) {
    if (this.props.app.currentProgram.id !== prevProps.app.currentProgram.id) {
      await this.loadProgram();
    }
  }

  loadProgram = async () => {
    this.props.startLoading();
    const {
      statistics,
      related_sectors: relatedSectors,
      professions_by_group: professionsByGroupAndTag,
    } = await PortalApi.getProgram(this.props.app.currentProgram.id);
    this.setState({
      statistics,
      relatedSectors,
      professionsByGroupAndTag: {
        default: [
          {
            tagName: null,
            professions: professionsByGroupAndTag.default,
          },
        ],
        faculty: [
          {
            tagName: null,
            professions: professionsByGroupAndTag.faculty,
          },
        ],
        all_by_tags: professionsByGroupAndTag.all_by_tags.map(tag => ({
          tagName: tag.name,
          professions: tag.professions,
        })),
      },
    });


    if (!this.props.app.currentProfession) {
      let isChange = false;
      if (professionsByGroupAndTag.default.length) {
        this.props.setCurrentProfession(professionsByGroupAndTag.default[0]);
        isChange = true;
      } else if (professionsByGroupAndTag.faculty.length) {
        this.props.setCurrentProfession(professionsByGroupAndTag.faculty[0]);
        isChange = true;
      } else if (professionsByGroupAndTag.all_by_tags.length) {
        this.props.setCurrentProfession(professionsByGroupAndTag.all_by_tags[0].professions[0]);
        isChange = true;
      }

      if (isChange) {
        const { app } = this.props;

        const url = Utils.convertToUrl({
          programId: app.currentProgram.id,
          programSlug: app.currentProgram.slug,
          professionId: app.currentProfession.id,
          professionSlug: app.currentProfession.slug,
        });

        const { history } = this.props;
        history.replace(url);
      }
    }

    this.props.finishLoading();
  }

  render() {
    return (
      <div className="app">
        <ProgramRow
          statistics={this.state.statistics}
        />
        <div className="content-row">
          <ProfessionColumn
            relatedSectors={this.state.relatedSectors}
            professionsByGroupAndTag={this.state.professionsByGroupAndTag}
            changeSelectedGroup={this.changeSelectedGroup}
            selectedGroup={this.state.selectedGroup}
          />
          <ContentColumn
            relatedSectors={this.state.relatedSectors}
            selectedGroup={this.state.selectedGroup}      
          />
        </div>
      </div>
    );
  }
}

MainApp.propTypes = {
  app: PropTypes.shape({
    currentProgram: PropTypes.shape({
      id: PropTypes.number.isRequired,
    }).isRequired,
    currentProfession: PropTypes.shape({}),
  }).isRequired,

  setCurrentProfession: PropTypes.func.isRequired,
  startLoading: PropTypes.func.isRequired,
  finishLoading: PropTypes.func.isRequired,

  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

const mapStateToProps = state => ({
  app: state.app,
});

const mapDispatchToProps = dispatch => ({
  startLoading: () => dispatch(appActions.startLoading()),
  finishLoading: () => dispatch(appActions.finishLoading()),
  setCurrentProfession: profession => dispatch(appActions.setCurrentProfession(profession)),
});

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(MainApp));
