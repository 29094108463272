const sharedConfig = {
  storage: {
    keys: {
      accessToken: 'accessToken',
    },
  },
};

const configs = {
  development: {
    env: 'development',
    apiUrl: 'http://api.portal.fdmt.hk.local/',
    defaultLoginBanner: '/static/images/login-banners/default.jpg',
    googleAnalytics: {
      debug: true,
      trackingId: 'UA-114413428-2',
    },
  },
  production: {
    env: 'production',
    apiUrl: 'https://api.portal.fdmt.hk/',
    defaultLoginBanner: '/static/images/login-banners/default.jpg',
    googleAnalytics: {
      debug: false,
      trackingId: 'UA-114413428-2',
    },
  },
};

export default {
  ...configs[process.env.NODE_ENV],
  ...sharedConfig,
};
