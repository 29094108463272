import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { appActions } from '../../../actions';

import AdminPageLayout from '../layout';
import ProfessionForm from './form';
import RelatedSegmentsForm from './related-segments-form';
import ProfessionTabForm from './profession-tab-form';
import ExtraInfoForm from '../components/extra-info-form/index';
import RelatedContacts from './related-contacts';

import PortalApi from '../../../services/PortalApi';

class ProfessionEditPage extends React.PureComponent {
  state = {
    profession: null,
  }

  assignedSegmentRefObjs = []

  async componentDidMount() {
    this.props.finishLoading();
    await this.loadProfessions();
  }

  loadProfessions = async () => {
    const profession = await PortalApi.admin.getProfession(this.props.match.params.id);
    this.setState({
      profession,
    });
  }

  render() {
    const { className } = this.props;

    if (!this.state.profession) {
      return (
        <div className={className}>
          <AdminPageLayout>loading...</AdminPageLayout>
        </div>
      );
    }

    return (
      <div className={className}>
        <AdminPageLayout>
          <div className="main-content">
            <h1>{ this.state.profession.name }</h1>
            <ProfessionForm
              mode="edit"
              profession={this.state.profession}
            />
            <hr />
            <RelatedContacts
              professionId={this.state.profession.id}
            />
            <hr />
            <RelatedSegmentsForm
              professionId={this.state.profession.id}
              relatedSegmentAssignments={this.state.profession.related_segment_assignments}
            />
            <hr />
            <ExtraInfoForm
              extraInfoGroupId={this.state.profession.extra_info_group_id}
              extraInfos={this.state.profession.extra_infos}
            />
            <hr />
            <ProfessionTabForm
              professionId={this.state.profession.id}
              professionTabAssignments={this.state.profession.profession_tab_assignments}
            />
          </div>
        </AdminPageLayout>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  finishLoading: () => dispatch(appActions.finishLoading()),
});

ProfessionEditPage.propTypes = {
  finishLoading: PropTypes.func.isRequired,
  className: PropTypes.string.isRequired,

  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }).isRequired,
};

const StyledProfessionEditPage = styled(ProfessionEditPage)`
`;

export default connect(
  null,
  mapDispatchToProps,
)(StyledProfessionEditPage);
