import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Modal, Dimmer, Loader, Checkbox, Form } from 'semantic-ui-react';

import PortalApi from '../../services/PortalApi';
import ExtraInfos from '../extra-infos';

class ProfessionModal extends React.PureComponent {
  state = {
    isLoading: true,
    careerInfos: null,
    extraInfos: [],
    professionName: ' ',
    secondary_school: false,
    higher_diploma_and_associate: false,
    bachelor: false,
    master: false,
    doctorate: false,
  }

  async componentDidMount() {
    await this.loadProfession();
  }

  loadProfession = async () => {
    const profession = await PortalApi.getProfession(this.props.professionId);
    this.setState({
      careerInfos: profession.career_infos,
      extraInfos: profession.extra_infos,
      professionName: profession.name,
      isLoading: false,
      secondary_school: profession.requires_secondary_school,
      higher_diploma_and_associate: profession.requires_higher_diploma_and_associate,
      bachelor: profession.requires_bachelor,
      master: profession.requires_master,
      doctorate: profession.requires_doctorate,
    });
  }

  renderCareerInfos = () => {
    const { careerInfos } = this.state;
    if (!careerInfos) {
      return null;
    }

    const {
      outputs = [],
      work_withs: workWiths = [],
      demotivators = [],
      motivators = [],
      career_paths: careerPaths = [],
      salaries = [],
      qualifications = [],

    } = careerInfos;

    const outputComponents = outputs.map(output => (
      <li key={output.id}>{ output.content }</li>
    ));
    const workWithComponents = workWiths.map(workWith => (
      <li key={workWith.id}>{ workWith.content }</li>
    ));
    const demotivatorComponents = demotivators.map(demotivator => (
      <li key={demotivator.id}>{ demotivator.content }</li>
    ));
    const motivatorComponents = motivators.map(motivator => (
      <li key={motivator.id}>{ motivator.content }</li>
    ));

    const salaryComponents = salaries.map(salary => (
      <p key={salary.id}>{ salary.content }</p>
    ));
    const qualificationComponents = qualifications.map(qualification => (
      <p key={qualification.id}>{ qualification.content }</p>
    ));
    const careerPathComponents = careerPaths.map(careerPath => (
      <p key={careerPath.id}>{ careerPath.content }</p>
    ));

    return (
      <div className="profession-modal__career-infos">
        <div className="profession-modal__career-info-box profession-modal__career-info-box--output">
          <h2>... produces ...</h2>
          <ul className="profession-modal__career-info-list profession-modal__career-info-list--blue">
            { outputComponents }
          </ul>
        </div>
        <div className="profession-modal__career-info-box profession-modal__career-info-box--works-with">
          <h2>... works with ...</h2>
          <ul className="profession-modal__career-info-list profession-modal__career-info-list--blue">
            { workWithComponents }
          </ul>
        </div>
        <div className="profession-modal__career-info-box profession-modal__career-info-box--feelings">
          <h2>... feels ...</h2>
          <ul className="profession-modal__career-info-list profession-modal__career-info-list--motivators">
            { motivatorComponents }
          </ul>
          <ul className="profession-modal__career-info-list profession-modal__career-info-list--demotivators">
            { demotivatorComponents }
          </ul>
        </div>
        <div className="profession-modal__career-info-box profession-modal__career-info-box--qualification">
          <h2>Qualification / license</h2>
          { qualificationComponents }
        </div>
        <div className="profession-modal__career-info-box profession-modal__career-info-box--career-path">
          <h2>Career Path</h2>
          { careerPathComponents }
        </div>
        <div className="profession-modal__career-info-box profession-modal__career-info-box--salary">
          <h2>Salary</h2>
          { salaryComponents }
        </div>
      </div>
    );
  }

  render() {
    const { className } = this.props;
    return (
      <Modal
        className={className}
        size="large"
        open
        closeOnDimmerClick
        onClose={this.props.close}
      >
        <div className="modal--profession">
          { this.state.isLoading && (
            <Dimmer inverted active>
              <Loader />
            </Dimmer>
          )}
          <h1>{ this.state.professionName }</h1>
          <div className="profession-modal__scroll-container">
            <ExtraInfos
              extraInfos={this.state.extraInfos}
            />
            <div>
              <p style={{'fontSize': '10px'}}>
                Jobs with these entry qualification found
              </p>
              <Form.Field
                control={Checkbox}
                disabled = {true}
                label="Secondary School"
                checked={this.state.secondary_school}
              />
              <Form.Field
                control={Checkbox}
                disabled = {true}
                label="Higher Diploma And Associate"
                checked={this.state.higher_diploma_and_associate}
              />
              <Form.Field
                control={Checkbox}
                disabled = {true}
                label="Bachelor"
                checked={this.state.bachelor}
              />
              <Form.Field
                control={Checkbox}
                disabled = {true}
                label="Master"
                checked={this.state.master}
              />
              <Form.Field
                control={Checkbox}
                disabled = {true}
                label="Ph.D"
                checked={this.state.doctorate}
              />
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

ProfessionModal.propTypes = {
  professionId: PropTypes.number.isRequired,
  close: PropTypes.func.isRequired,

  className: PropTypes.string.isRequired,
};

const StyledProfessionModal = styled(ProfessionModal)`
  width: 80% !important;
  height: 80vh !important;
  padding: 15px;

  .modal--profession {
    height: 100%;
    overflow-y: auto;
  }
  .profession-modal__scroll-container {
    display: flex;
    /* min-height: 80vh !important; */
    flex-direction: column;
    float:left;
  }

  h1 {
    font-size: 24px;
    text-align: center;
  }
  h2 {
    font-size: 20px;
  }
  .profession-modal__career-infos {
    background-color: rgb(31, 48, 94);
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 20px;
  }
  .profession-modal__career-info-box {
    background-size: cover;
    width: 32%;
    padding: 30px 20px;
    margin-bottom: 50px;
  }
  .profession-modal__career-info-box * {
    color: #ffffff;
  }
  .profession-modal__career-info-box:nth-child(3n-1),
  .profession-modal__career-info-box:nth-child(3n-2) {
    margin-right: 2%;
  }

  .extra-info__link a {
    color: #000000;
  }
  .profession-modal__career-info-list--blue {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  .profession-modal__career-info-list--blue li {
    background: #1F497D;
    padding: 5px 10px;
    margin-bottom: 10px;
  }

  .profession-modal__career-info-list--motivators,
  .profession-modal__career-info-list--demotivators {
    position: relative;
    padding-left: 70px;
    margin-bottom: 20px;
    min-height: 60px;
  }
  .profession-modal__career-info-list--motivators::before,
  .profession-modal__career-info-list--demotivators::before {
    content: ' ';
    width: 49px;
    height: 51px;
    background: url('/static/images/profession-modal-feelings.png');
    background-position-x: 0;
    position: absolute;
    left: 0;
  }
  .profession-modal__career-info-list--motivators::before {
    background-position-x: 49px;
  }
  .profession-modal__career-info-list--demotivators::before {
    background-position-x: 0;
  }
  .profession-modal__career-info-list--motivators li,
  .profession-modal__career-info-list--demotivators li {
    line-height: 1.5;
  }
`;

export default StyledProfessionModal;
