import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  Button,
  Form,
} from 'semantic-ui-react';
import uuidv1 from 'uuid/v1';

import PortalApi from '../../../../services/PortalApi';
import Utils from '../../../../lib/utils';

class ExtraInformationForm extends React.PureComponent {
  state = {
    extraInfos: this.props.extraInfos.map(extraInfo => ({
      ...extraInfo,
      uuid: uuidv1(), // use as key to render
    })),
    locationOptions: [],
    isSaving: false,
  }

  async componentDidMount() {
    await this.loadAvailableLocations();
  }

  loadAvailableLocations = async () => {
    const locations = await PortalApi.admin.getAvailableLocations();
    this.setState({
      locationOptions: locations.map(location => ({
        key: location.id,
        value: location.id,
        text: location.name,
      })),
    });
  }

  handleChange = (uuid, key, value) => {
    this.setState(prevState => ({
      extraInfos: prevState.extraInfos.map((extraInfo) => {
        if (extraInfo.uuid === uuid) {
          return {
            ...extraInfo,
            [key]: value,
          };
        }
        return { ...extraInfo };
      }),
    }));
  }

  add = () => {
    this.setState(prevState => ({
      extraInfos: [
        ...prevState.extraInfos,
        {
          type: '',
          name: '',
          content: '',
          seq: '',
          location_id: null,
          uuid: uuidv1(),
        },
      ],
    }));
  }

  remove = (uuid) => {
    this.setState(prevState => ({
      extraInfos: prevState.extraInfos.filter(extraInfo => extraInfo.uuid !== uuid),
    }));
  }

  saveExtraInfos = async () => {
    const body = this.state.extraInfos.map((extraInfo => ({
      type: extraInfo.type,
      name: extraInfo.name,
      content: extraInfo.content,
      location_id: extraInfo.location_id || null,
      seq: extraInfo.seq,
    })));


    const isValid = body.every(extraInfo => Utils.validateRequiredParams(extraInfo, ['type', 'name', 'seq']));
    if (!isValid) {
      Utils.toastAdminGeneralError(new Error('Name, type and sequence are required.'));
      return;
    }

    this.setState({
      isSaving: true,
    });

    try {
      const response = await PortalApi.admin.saveExtraInfos(this.props.extraInfoGroupId, body);
      Utils.toastAdminGeneralSuccess('Saved Extra Information!');

      this.setState({
        extraInfos: response.map(extraInfo => ({
          uuid: uuidv1(),
          type: extraInfo.type,
          seq: extraInfo.seq,
          content: extraInfo.content,
          location_id: extraInfo.location_id,
          name: extraInfo.name,
        })),
      });
    } catch (error) {
      if (error instanceof PortalApi.ApiError) {
        Utils.toastAdminApiError(error);
      } else {
        Utils.toastAdminGeneralError(error);
      }
    }

    this.setState({
      isSaving: false,
    });
  }

  render() {
    const segmentComponents = this.state.extraInfos.map(extraInfo => (
      <Form.Group key={extraInfo.uuid}>
        <Form.Input
          width={2}
          placeholder="Type"
          value={extraInfo.type}
          onChange={(_, { value }) => { this.handleChange(extraInfo.uuid, 'type', value); }}
        />
        <Form.Input
          width={2}
          placeholder="Name"
          value={extraInfo.name}
          onChange={(_, { value }) => { this.handleChange(extraInfo.uuid, 'name', value); }}
        />
        <Form.Input
          width={4}
          placeholder="Content"
          value={extraInfo.content}
          onChange={(_, { value }) => { this.handleChange(extraInfo.uuid, 'content', value); }}
        />
        <Form.Input
          width={2}
          placeholder="Sequence (Ordering)"
          value={extraInfo.seq}
          type="number"
          onChange={(_, { value }) => { this.handleChange(extraInfo.uuid, 'seq', value); }}
        />
        <Form.Dropdown
          width={2}
          placeholder="Location"
          value={extraInfo.location_id}
          options={this.state.locationOptions}
          search
          lazyLoad
          selection
          clearable
          onChange={(_, { value }) => { this.handleChange(extraInfo.uuid, 'location_id', value); }}
        />
        <Button
          negative
          onClick={() => { this.remove(extraInfo.uuid); }}
          type="button"
        >
          Remove
        </Button>
      </Form.Group>
    ));

    return (
      <div className={`${this.props.className} profession-segments`}>
        <h1>Extra Information</h1>
        <details>
          <div className="special-types-explanation">
            <span>There are some special types to generate special content.</span>
            <ul>
              <li>YouTube: __YOUTUBE__</li>
              <li>HTML Code (e.g. display custom image): __CODE__</li>
            </ul>

            <span>
              {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
              For <strong>profession</strong>, there are another types of special content:
            </span>
            <ul>
              <li>__DESCRIPTION__</li>
              <li>__WORK_WITH__</li>
              <li>__OUTPUT__</li>
              <li>__MOTIVATOR__</li>
              <li>__DEMOTIVATOR__</li>
              <li>__QUALIFICATION__</li>
              <li>__CAREER_PATH__</li>
              <li>__SALARY__</li>
              <li>__POPUP_BACKGROUND__</li>
            </ul>
            <span>You can see Accountant/Auditor for references.</span>
          </div>

          {/* eslint-disable react/jsx-one-expression-per-line */}
          <div>
            <strong>Location</strong><br />
            <ul>
              <li>Location is not applicable to <strong>profession</strong>.</li>
              <li>If location is not set, it is treated as global.</li>
            </ul>
          </div>
          {/* eslint-enable react/jsx-one-expression-per-line */}
        </details>
        <Form>
          { segmentComponents }
          <Form.Group>
            <Form.Field
              inline
              control={Button}
              positive
              onClick={this.add}
              type="button"
            >
              Add More
            </Form.Field>
            <Form.Field
              inline
              control={Button}
              onClick={this.saveExtraInfos}
              loading={this.state.isSaving}
              type="submit"
              content="Save"
            />
          </Form.Group>
        </Form>
      </div>
    );
  }
}

ExtraInformationForm.propTypes = {
  extraInfoGroupId: PropTypes.number.isRequired,
  extraInfos: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequire,
  }).isRequired).isRequired,

  className: PropTypes.string.isRequired,
};

const StyledExtraInformationForm = styled(ExtraInformationForm)`
details {
  margin-bottom: 20px;
}
.special-types-explanation {
  margin-bottom: 20px;
}
`;

export default StyledExtraInformationForm;
