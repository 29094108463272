import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import ReactTable from 'react-table';
import { Link } from 'react-router-dom';
import { Button, Dropdown } from 'semantic-ui-react';

import { appActions } from '../../../actions';

import AdminPageLayout from '../layout';
import ProgramForm from '../program-edit/form';

import PortalApi from '../../../services/PortalApi';
import Utils from '../../../services/Utils';

class ProgramIndexPage extends React.PureComponent {
  state = {
    programs: [],
    filteredPrograms: [],
  }

  async componentDidMount() {
    await this.loadPrograms();
    this.props.finishLoading();
  }

  loadPrograms = async () => {
    const programs = await PortalApi.admin.getPrograms();
    this.setState({
      programs,
      filteredPrograms: programs,
    });
  }

  filterPrograms = (filterValue) => {
    const { programs } = this.state;
    if (filterValue === 'active jupas') {
      this.setState({
        filteredPrograms: programs.filter(p => p.program_type === 'jupas' && !!p.jupas_url && p.is_active),
      });
    } else if (filterValue === 'inactive jupas') {
      this.setState({
        filteredPrograms: programs.filter(p => p.program_type === 'jupas' && !p.jupas_url),
      });
    } else if (filterValue === 'ipass') {
      this.setState({
        filteredPrograms: programs.filter(p => p.program_type === 'ipass' && p.is_active),
      });
    } else {
      this.setState({ filteredPrograms: programs });
    }
  }

  renderPrograms = () => {
    const { filteredPrograms } = this.state;

    const columns = [
      {
        Header: 'Active?',
        accessor: 'is_active',
        width: 100,
        Cell: row => <div style={{ textAlign: 'center' }}>{row.value ? 'Yes' : 'No'}</div>,
      },
      {
        Header: 'Type',
        accessor: 'program_type',
        width: 100,
        Cell: row => <div style={{ textAlign: 'center' }}>{row.value}</div>,
      },
      {
        Header: '# ID',
        accessor: row => row.id.toString(),
        id: 'id',
        width: 100,
        Cell: ({ value: id }) => (
          <Link className="edit-link" to={`/admin/programs/${id}`}>
            {id}
          </Link>
        ),
      },
      {
        Header: 'Name',
        accessor: 'name',
        width: 500,
      },
      {
        Header: 'Institution',
        id: 'institution-name',
        accessor: row => (row.institution || {}).name,
        width: 500,
        Cell: cell => (
          cell.original.institution
            ? (
              <Link to={`/admin/institutions/${cell.original.institution.id}`}>
                {cell.original.institution.name}
              </Link>
            ) : ''
        ),
      },
      {
        Header: 'Name (Chinese)',
        accessor: 'name_chi',
        width: 500,
      },
      {
        Header: 'JUPAS Code',
        accessor: 'jupas_code',
        width: 100,
        Cell: row => <div style={{ textAlign: 'center' }}>{row.value === 'null' ? 'N/A' : row.value}</div>,
      },
      {
        Header: 'JUPAS URL',
        accessor: 'jupas_url',
        width: 100,
        Cell: row => (
          row.value
            ? (
              <a href={row.value} target="_blank" rel="noopener noreferrer">{row.value}</a>
            ) : 'N/A'
        ),
      },
      {
        Header: 'Name (short)',
        accessor: 'name_short',
        width: 500,
      },
      {
        Header: 'Degree Type',
        accessor: 'degree_type',
        width: 300,
      },
      {
        Header: 'Parent Course Type',
        accessor: 'parent_course_type',
        width: 300,
      },
      {
        Header: 'Duration Of Study',
        accessor: 'duration_of_study',
        width: 100,
      },
      {
        Header: 'Funding Type',
        accessor: 'funding_type',
        width: 300,
      },
      {
        Header: 'Overseas Institution Name',
        accessor: 'overseas_institution_name',
        width: 500,
      },
      {
        Header: 'Faculty',
        accessor: 'faculty',
        width: 500,
      },
      {
        Header: 'Department',
        accessor: 'department',
        width: 500,
      },
      {
        Header: 'Admission Model',
        accessor: 'admission_model',
        width: 300,
      },
    ];

    return (
      <div className="program-list">
        <Button.Group>
          <Button onClick={() => this.filterPrograms('all')}>All</Button>
          <Button onClick={() => this.filterPrograms('active jupas')}>JUPAS (Active)</Button>
          <Button onClick={() => this.filterPrograms('ipass')}>iPASS</Button>
        </Button.Group>

        <ReactTable
          data={filteredPrograms}
          columns={columns}
          defaultPageSize={25}
          pageSizeOptions={[10, 25, 50, 100]}
          filterable
          defaultFilterMethod={Utils.reactTableFilterInsensitive}
        />
      </div>
    );
  }

  render() {
    const { className } = this.props;

    return (
      <div className={className}>
        <AdminPageLayout>
          <div className="main-content">
            { this.renderPrograms() }
            <hr />
            <div className="create-program">
              <h1>Create New Program</h1>
              <ProgramForm mode="create" />
            </div>
          </div>
        </AdminPageLayout>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  finishLoading: () => dispatch(appActions.finishLoading()),
});

ProgramIndexPage.propTypes = {
  finishLoading: PropTypes.func.isRequired,
  className: PropTypes.string.isRequired,
};

const StyledProgramIndexPage = styled(ProgramIndexPage)`
`;

export default connect(
  null,
  mapDispatchToProps,
)(StyledProgramIndexPage);
