import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import store from 'store';

import AcknowledgementModal from './modals/acknowledgement';
import AboutThisPortalModal from './modals/about-this-portal';
import ProgramModal from './modals/program';
import SectorSegmentModal from './modals/sector-segment';
import MapModal from './modals/map';

import config from '../config';
import PortalApi from '../services/PortalApi';

class ProgramRow extends React.PureComponent {
  state = {
    disciplineGroups: [],
    isShowingProgramModal: false,
    isShowingAboutThisPortalModal: false,
    isShowingAcknowledgementModal: false,
    isShowingSectorSegmentModal: false,
    isShowingMapModal: false,
    logoutCounter: 0,
  }

  async componentDidMount() {
    if (this.props.app.userRole === 'admin') {
      await this.loadAllowedDisciplineGroupsAndPrograms();
    }
  }

  loadAllowedDisciplineGroupsAndPrograms = async () => {
    const disciplineGroups = await PortalApi.getAllowedDisciplineGroupsAndPrograms();
    this.setState({
      disciplineGroups,
    });
  }

  showProgramModal = () => {
    this.setState({ isShowingProgramModal: true });
  }

  closeProgramModal = () => {
    this.setState({ isShowingProgramModal: false });
  }

  showAboutThisPortalModal = () => {
    this.setState({ isShowingAboutThisPortalModal: true });
  }

  closeAboutThisPortalModal = () => {
    this.setState({ isShowingAboutThisPortalModal: false });
  }

  showAcknowledgementModal = () => {
    this.setState({ isShowingAcknowledgementModal: true });
  }

  closeAcknowledgementModal = () => {
    this.setState({ isShowingAcknowledgementModal: false });
  }

  showSectorSegmentModal = () => {
    this.setState({ isShowingSectorSegmentModal: true });
  }

  closeSectorSegmentModal = () => {
    this.setState({ isShowingSectorSegmentModal: false });
  }

  showMapModal = () => {
    this.setState({ isShowingMapModal: true });
  }

  closeMapModal = () => {
    this.setState({ isShowingMapModal: false });
  }

  incrementLogoutCounter = () => {
    if (this.state.logoutCounter >= 10) {
      store.remove(config.storage.keys.accessToken);
      window.location.reload();
    } else {
      this.setState(prevState => ({
        logoutCounter: prevState.logoutCounter + 1,
      }));
    }
  }

  renderStatistic = () => (
    <div className="program-row__program-stat-container">
      <table className="program__stat-table">
        <thead>
          <tr className="program__stat-row">
            <th className="program__stat-cell" />
            <th onClick={this.incrementLogoutCounter} className="program__stat-cell">Profession</th>
            <th className="program__stat-cell">Employer</th>
            <th className="program__stat-cell">
              <button
                type="button"
                className="button--no-style"
                onClick={this.showAboutThisPortalModal}
              >
                About this portal
              </button>
              {' '}
              <button
                type="button"
                className="button--no-style"
                onClick={this.showMapModal}
              >
                <span
                  role="img"
                  aria-label="earth"
                >
                  🌍
                </span>
              </button>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr className="program__stat-row">
            <td className="program__stat-cell program__stat-cell--align-right">Total</td>
            <td className="program__stat-cell">{ this.props.statistics.total.profession }</td>
            <td className="program__stat-cell">{ this.props.statistics.total.employer }</td>
            <td className="program__stat-cell">
              <button
                type="button"
                className="button--no-style"
                onClick={this.showSectorSegmentModal}
              >
                Sectors &amp; Segments
              </button>
            </td>
          </tr>
          <tr className="program__stat-row">
            <td className="program__stat-cell program__stat-cell--align-right">This program</td>
            <td className="program__stat-cell">{ this.props.statistics.program.profession }</td>
            <td className="program__stat-cell">{ this.props.statistics.program.employer }</td>
            <td className="program__stat-cell">
              <button
                type="button"
                className="button--no-style"
                onClick={this.showAcknowledgementModal}
              >
                Acknowledgement
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  )

  render() {
    const { className } = this.props;
    return (
      <header className={className}>
        <div className="program-row__program-name-container">
          { this.props.app.userRole === 'admin' && (
            <button
              className="button--no-style"
              type="button"
              onClick={this.showProgramModal}
            >
              <h2 className="program-row__program-name">
                { this.props.app.currentProgram.display_name }
              </h2>
            </button>
          )}
          { this.props.app.userRole !== 'admin' && (
            <h2 className="program-row__program-name">
              { this.props.app.currentProgram.display_name }
            </h2>
          )}
        </div>
        { this.renderStatistic() }
        { this.state.isShowingProgramModal && (
          <ProgramModal
            disciplineGroups={this.state.disciplineGroups}
            close={this.closeProgramModal}
          />
        )}
        { this.state.isShowingAboutThisPortalModal && (
          <AboutThisPortalModal
            close={this.closeAboutThisPortalModal}
          />
        )}
        { this.state.isShowingAcknowledgementModal && (
          <AcknowledgementModal
            close={this.closeAcknowledgementModal}
          />
        )}
        { this.state.isShowingSectorSegmentModal && (
          <SectorSegmentModal
            close={this.closeSectorSegmentModal}
          />
        )}
        { this.state.isShowingMapModal && (
          <MapModal
            close={this.closeMapModal}
          />
        )}
      </header>
    );
  }
}

const mapStateToProps = state => ({
  app: state.app,
});

ProgramRow.propTypes = {
  app: PropTypes.shape({
    currentProgram: PropTypes.shape({
      id: PropTypes.number.isRequired,
      display_name: PropTypes.string.isRequired,
    }).isRequired,
    userRole: PropTypes.string.isRequired,
  }).isRequired,

  statistics: PropTypes.shape({
    total: PropTypes.shape({
      profession: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      employer: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    }).isRequired,
    program: PropTypes.shape({
      profession: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      employer: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    }).isRequired,
  }).isRequired,

  className: PropTypes.string.isRequired,
};

const StyledProgramRow = styled(ProgramRow)`
  background-color: #233e6b;
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
  * {
    color: #ffffff;
  }
  .program-row__program-name-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .program-row__program-name-container > button {
    height: 100%;
  }

  .program-row__program-stat-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .program-row__program-name {
    font-size: 14px;
  }
  .program__stat-table {
    margin-left: auto;
  }
  .program__stat-row {
    line-height: 1;
  }
  .program__stat-cell {
    font-size: 10px;
  }
  .program__stat-cell {
    padding: 2px 5px;
    text-align: center;
  }
  .program__stat-cell--align-right {
    text-align: right;
  }

  @media only screen and (max-width: 768px) {
    display: block;
    padding: 10px 5px;

    .program-row__program-stat-container {
      margin-top: 10px;
    }
    .program-row__program-name {
      text-align: center;
    }
    .program__stat-table {
      margin: 0 auto;
    }
  }
`;

export default connect(
  mapStateToProps,
  // mapDispatchToProps,
)(StyledProgramRow);
