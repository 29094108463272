import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';
import { Dimmer, Loader } from 'semantic-ui-react';
import { createBrowserHistory } from 'history';

import { appActions } from './actions';
import Analytics from './services/analytics';

import './style.css';
import 'react-table/react-table.css';
import 'react-toastify/dist/ReactToastify.min.css';

import AppPage from './pages/app';
import ContactIndexPage from './pages/admin/contact/index-page';
import ContactNewPage from './pages/admin/contact/new-page';
import ContactEditPage from './pages/admin/contact/edit-page';
import ContactTagIndexPage from './pages/admin/contact-tag/index-page';
import ContactTagNewPage from './pages/admin/contact-tag/new-page';
import ContactTagEditPage from './pages/admin/contact-tag/edit-page';
import DisciplineGroupIndexPage from './pages/admin/discipline-group-index/index';
import DisciplineGroupEditPage from './pages/admin/discipline-group-edit/index';
import DisciplineIndexPage from './pages/admin/discipline-index/index';
import DisciplineEditPage from './pages/admin/discipline-edit/index';
import EmployerIndexPage from './pages/admin/employer-index/index';
import EmployerEditPage from './pages/admin/employer-edit/index';
import InstitutionIndexPage from './pages/admin/institution-index/index';
import InstitutionEditPage from './pages/admin/institution-edit/index';
import PositionIndexPage from './pages/admin/position-index/index';
import ProgramIndexPage from './pages/admin/program-index/index';
import ProgramEditPage from './pages/admin/program-edit/index';
import ProfessionIndexPage from './pages/admin/profession-index/index';
import ProfessionEditPage from './pages/admin/profession-edit/index';
import SectorIndexPage from './pages/admin/sector-index/index';
import SectorEditPage from './pages/admin/sector-edit/index';
import SegmentIndexPage from './pages/admin/segment-index/index';
import SegmentEditPage from './pages/admin/segment-edit/index';
import UserIndexPage from './pages/admin/user-index/index';
import UserEditPage from './pages/admin/user-edit/index';

import NotFoundPage from './pages/not-found';
import ErrorModal from './components/modals/error';

const history = createBrowserHistory();

class App extends React.Component {
  backCounter = 0;

  async componentDidMount() {
    history.listen((location, action) => {
      Analytics.set({ page: location.pathname });
      Analytics.trackPageView(location.pathname);

      if (action === 'POP') {
        this.backCounter += 1;
        this.forceUpdate();
      }
    });
  }

  render() {
    const { app, setErrorModal } = this.props;
    return (
      // force component remount when path change
      <React.Fragment key={this.backCounter}>
        { app.isLoading && (
          <Dimmer inverted active>
            <Loader />
          </Dimmer>
        )}
        { app.error && (
          <ErrorModal
            title={app.error.title}
            message={app.error.message}
            close={() => setErrorModal(null)}
          />
        )}
        <Router history={history}>
          <Switch>
            <Route exact path="/" component={AppPage} />
            <Route exact path="/programs/:programId" component={AppPage} />
            <Route exact path="/programs/:programId/:programSlug" component={AppPage} />
            <Route exact path="/programs/:programId/:programSlug/professions/:professionId" component={AppPage} />
            <Route exact path="/programs/:programId/:programSlug/professions/:professionId/:professionSlug" component={AppPage} />
            <Route exact path="/admin/positions" component={PositionIndexPage} />
            <Route exact path="/admin/contacts" component={ContactIndexPage} />
            <Route exact path="/admin/contacts/new" component={ContactNewPage} />
            <Route exact path="/admin/contacts/:id" component={ContactEditPage} />
            <Route exact path="/admin/contact-tags" component={ContactTagIndexPage} />
            <Route exact path="/admin/contact-tags/new" component={ContactTagNewPage} />
            <Route exact path="/admin/contact-tags/:id" component={ContactTagEditPage} />
            <Route exact path="/admin/discipline-groups" component={DisciplineGroupIndexPage} />
            <Route exact path="/admin/discipline-groups/:id" component={DisciplineGroupEditPage} />
            <Route exact path="/admin/disciplines" component={DisciplineIndexPage} />
            <Route exact path="/admin/disciplines/:id" component={DisciplineEditPage} />
            <Route exact path="/admin/employers" component={EmployerIndexPage} />
            <Route exact path="/admin/employers/:id" component={EmployerEditPage} />
            <Route exact path="/admin/institutions" component={InstitutionIndexPage} />
            <Route exact path="/admin/institutions/:id" component={InstitutionEditPage} />
            <Route exact path="/admin/programs" component={ProgramIndexPage} />
            <Route exact path="/admin/programs/:id" component={ProgramEditPage} />
            <Route exact path="/admin/professions" component={ProfessionIndexPage} />
            <Route exact path="/admin/professions/:id" component={ProfessionEditPage} />
            <Route exact path="/admin/sectors" component={SectorIndexPage} />
            <Route exact path="/admin/sectors/:id" component={SectorEditPage} />
            <Route exact path="/admin/segments" component={SegmentIndexPage} />
            <Route exact path="/admin/segments/:id" component={SegmentEditPage} />
            <Route exact path="/admin/users" component={UserIndexPage} />
            <Route exact path="/admin/users/:id" component={UserEditPage} />
            <Redirect from="/admin" to="/admin/employers" />
            <Route component={NotFoundPage} />
          </Switch>
        </Router>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  app: state.app,
});

const mapDispatchToProps = dispatch => ({
  setErrorModal: error => dispatch(appActions.setErrorModal(error)),
});

App.propTypes = {
  app: PropTypes.shape({
    isLoading: PropTypes.bool.isRequired,
    error: PropTypes.shape({
      title: PropTypes.string.isRequired,
      message: PropTypes.string.isRequired,
    }),
  }).isRequired,
  setErrorModal: PropTypes.func.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(App);
