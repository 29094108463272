/* eslint-disable import/prefer-default-export */

export const appActions = {
  logIn: (userId, role) => ({
    type: 'LOG_IN',
    userId,
    role,
  }),
  startLoading: () => ({
    type: 'START_LOADING',
  }),
  finishLoading: () => ({
    type: 'FINISH_LOADING',
  }),
  setErrorModal: error => ({
    type: 'SET_ERROR_MODAL',
    error,
  }),
  setCurrentProgram: program => ({
    type: 'SET_CURRENT_PROGRAM',
    program,
  }),
  setCurrentProfession: profession => ({
    type: 'SET_CURRENT_PROFESSION',
    profession,
  }),
};
