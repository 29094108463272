// TODO rename this file

export default {
  convertToUrl: ({
    programId, programSlug, professionId, professionSlug,
  }) => {
    if (professionId) {
      return `/programs/${programId}/${programSlug}/professions/${professionId}/${professionSlug}`;
    }
    return `/programs/${programId}/${programSlug}`;
  },

  convertYoutubeLinkToEmbededSrc: (link) => {
    // eslint-disable-next-line no-useless-escape
    const regex = /^(?:http(?:s)?:\/\/)?(?:www\.)?(?:m\.)?(?:youtu\.be\/|youtube\.com\/(?:(?:watch)?\?(?:.*&)?v(?:i)?=|(?:embed|v|vi|user)\/))([^\?&\"'>]+)/;
    const matches = regex.exec(link);
    const youtubeId = matches[1];
    const embededSrc = `https://www.youtube.com/embed/${youtubeId}`;
    return embededSrc;
  },

  // https://github.com/tannerlinsley/react-table/issues/335#issuecomment-307540879
  reactTableFilterInsensitive: (filter, row) => {
    const id = filter.pivotId || filter.id;
    let rowVal = row[id];
    if (typeof rowVal === 'boolean') {
      rowVal = row[id] ? 'Yes' : 'No';
    }
    return (
      rowVal !== undefined
        ? String(rowVal.toLowerCase()).includes(filter.value.toLowerCase())
        : true
    );
  },

  // one level object only
  validateRequiredParams: (paramsObj, requiredFields) => (
    requiredFields.every(key => Boolean(paramsObj[key]))
  ),
};
