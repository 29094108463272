import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Icon } from 'semantic-ui-react';

import EmailModal from './email-modal';
import PositionEditModal from './edit-modal';
import PositionTable from './position-table';
import { ProfessionsContext } from './index';

import PortalApi from '../../../services/PortalApi';

class PositionDateRow extends React.PureComponent {
  state = {
    // isLoadedToday: false,
    isLoading: false,
    isLoaded: false,
    positions: [],
    editPositionData: null,
    showEmailModal: false,
  }

  handleRowClick = async () => {
    if (this.state.isLoading) {
      return;
    }
    if (this.state.positions.length) {
      this.setState({
        positions: [],
        isLoaded: false,
      });
      return;
    }
    this.setState({
      isLoading: true,
      isLoaded: false,
    });
    const positions = await PortalApi.admin.getPositions(this.props.position.post_date);
    this.setState({
      positions,
      isLoading: false,
      isLoaded: true,
    });
  }

  handleSendEmailClick = (event) => {
    event.stopPropagation();
    this.setState({
      showEmailModal: true,
    });
  }

  closeEmailModal = () => {
    this.setState({
      showEmailModal: false,
    });
  }

  handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      this.handleRowClick();
    }
  }

  showPositionModal = (position) => {
    this.setState({
      editPositionData: position,
    });
  }

  closePositionModal = (updatedPosition) => {
    let { positions: updatedPositions } = this.state;
    if (updatedPosition) {
      updatedPositions = [
        ...updatedPositions,
      ];
      const index = updatedPositions.findIndex(position => position.id === updatedPosition.id);
      updatedPositions[index] = updatedPosition;
    }
    this.setState({
      editPositionData: null,
      positions: updatedPositions,
    });
  }

  render() {
    const { position, className } = this.props;
    return (
      <div className={className}>
        <div
          className="post-date-row__stat"
          onClick={this.handleRowClick}
          onKeyDown={this.handleKeyDown}
          role="button"
          tabIndex={0}
        >
          <button
            className="no-style post-date-row__post-email"
            onClick={this.handleSendEmailClick}
            type="button"
          >
            <Icon name="mail" />
          </button>
          <div className="post-date-row__post-date">
            { position.post_date }
          </div>
          <div className="post-date-row__count post-date-row__count--in">
            <span>In: </span>
            <span>{ position.in_count }</span>
          </div>
          <div className="post-date-row__count post-date-row__count--out">
            <span>Out: </span>
            <span>{ position.out_count }</span>
          </div>
          <div className="post-date-row__count post-date-row__count--pending">
            <span>Pending: </span>
            <span>{ position.pending_count }</span>
          </div>
        </div>
        { !this.state.isLoading && this.state.isLoaded && (
          <PositionTable
            positions={this.state.positions}
            onClickEdit={(employer) => { this.showPositionModal(employer); }}
            // onFinishEdit
          />
        )}
        { this.state.editPositionData && (
          <ProfessionsContext.Consumer>
            {({ availableProfessions }) => (
              <PositionEditModal
                close={this.closePositionModal}
                {...this.state.editPositionData}
                availableProfessions={availableProfessions}
              />
            )}
          </ProfessionsContext.Consumer>
        )}
        { this.state.showEmailModal && (
          <EmailModal
            close={this.closeEmailModal}
            postDate={this.props.position.post_date}
          />
        )}
      </div>
    );
  }
}

PositionDateRow.propTypes = {
  position: PropTypes.shape({
    post_date: PropTypes.string.isRequired,
    in_count: PropTypes.string.isRequired,
    out_count: PropTypes.string.isRequired,
    pending_count: PropTypes.string.isRequired,
  }).isRequired,

  className: PropTypes.string.isRequired,
};

const StyledPositionDateRow = styled(PositionDateRow)`
  .post-date-row__stat {
    cursor: pointer;
    padding: 10px;
  }
  .post-date-row__stat:hover,
  .post-date-row__stat:focus {
    background-color: gray;
  }

  .post-date-row__stat > div {
    display: inline-block;
    width: 200px;
  }
  .post-date-row__count--in span:first-child {
    font-weight: bold;
  }

  .post-date-row__count--in span:first-child {
    color: green;
  }
  .post-date-row__count--out span:first-child {
    color: red;
  }
  .post-date-row__count--pending span:first-child {
    color: orange;
  }

  .post-date-row__post-email {
    margin-right: 20px;
  }
`;

export default StyledPositionDateRow;
