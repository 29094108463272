import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  Button,
  Input,
  Form,
} from 'semantic-ui-react';
import uuidv1 from 'uuid/v1';
import moment from 'moment-timezone';

import PortalApi from '../../../services/PortalApi';
import Utils from '../../../lib/utils';

const UserAccessForm = ({ className, userId, userAccesses }) => {
  const [existingAccesses, setExistingAccesses] = useState(userAccesses);
  const [newAccesses, setNewAccesses] = useState([]);
  const [isSaving, setIsSaving] = useState(false);
  const [isRevoking, setIsRevoking] = useState(false);

  const add = () => {
    setNewAccesses(prevAccesses => ([
      ...prevAccesses,
      {
        validFrom: '',
        validTo: '',
        uuid: uuidv1(),
      },
    ]));
  };

  const remove = uuid => setNewAccesses(
    prevAccesses => prevAccesses.filter(access => access.uuid !== uuid)
  );

  const handleChange = (uuid, key, value) => {
    setNewAccesses(prevAccesses => prevAccesses.map((access) => {
      if (access.uuid !== uuid) {
        return access;
      }
      return {
        ...access,
        [key]: value,
      };
    }));
  };

  const save = async () => {
    let isValid = true;

    const body = newAccesses.map((access) => {
      const validFrom = moment.tz(access.validFrom, 'Asia/Hong_Kong');
      const validTo = moment.tz(access.validTo, 'Asia/Hong_Kong');

      if (!validFrom.isValid() || !validTo.isValid()) {
        isValid = false;
        return {};
      }
      return {
        valid_from: validFrom.toISOString(),
        valid_to: validTo.toISOString(),
      };
    });

    if (!isValid) {
      Utils.toastAdminGeneralError(new Error('Date time format is invalid.'));
      return;
    }

    setIsSaving(true);

    try {
      const response = await PortalApi.admin.grantUserAccesses(userId, body);
      Utils.toastAdminGeneralSuccess('Granted user accesses.');

      setExistingAccesses(response);
      setNewAccesses([]);
    } catch (error) {
      if (error instanceof PortalApi.ApiError) {
        Utils.toastAdminApiError(error);
      } else {
        Utils.toastAdminGeneralError(error);
      }
    }

    setIsSaving(false);
  };

  const revokeAllAccess = async () => {
    setIsRevoking(true);

    try {
      await PortalApi.admin.revokeAllUserAccesses(userId);
      Utils.toastAdminGeneralSuccess('Revoked all user accesses.');
      setExistingAccesses([]);
    } catch (error) {
      if (error instanceof PortalApi.ApiError) {
        Utils.toastAdminApiError(error);
      } else {
        Utils.toastAdminGeneralError(error);
      }
    }

    setIsRevoking(false);
  };

  return (
    <div className={className}>
      <Button type="button" negative floated="right" loading={isRevoking} onClick={revokeAllAccess} content="Revoke All Accesses" />
      <h1>Access</h1>
      <ul>
        { existingAccesses.map((access) => {
          const validFrom = moment(access.valid_from);
          const validTo = moment(access.valid_to);
          const currentDate = moment();

          const isBetween = currentDate.isBetween(validFrom, validTo);
          const activeClass = isBetween ? 'active-access' : '';

          return (
            <li className={activeClass} key={access.id}>
              { validFrom.tz('Asia/Hong_Kong').format('YYYY-MM-DD HH:mm z') }
              { ' - ' }
              { validTo.tz('Asia/Hong_Kong').format('YYYY-MM-DD HH:mm z') }
            </li>
          );
        }) }
      </ul>
      <Form>
        <p>
          Please input YYYY-MM-DD HH:mm format in Hong Kong Time.
          Otherwise it may fail unexpectedly.
        </p>
        { newAccesses.map(access => (
          <Form.Group key={access.uuid}>
            <Form.Field width={4}>
              <Input
                type="text"
                required
                fluid
                placeholder="Valid From"
                label="Valid From"
                labelPosition="left"
                value={access.validFrom}
                onChange={(_, { value }) => { handleChange(access.uuid, 'validFrom', value); }}
              />
            </Form.Field>
            <Form.Field width={4}>
              <Input
                type="text"
                required
                fluid
                placeholder="Valid To"
                label="Valid To"
                labelPosition="left"
                value={access.validTo}
                onChange={(_, { value }) => { handleChange(access.uuid, 'validTo', value); }}
              />
            </Form.Field>
            <Button type="button" negative onClick={() => { remove(access.uuid); }} content="Remove" />
          </Form.Group>
        )) }
        <Form.Group>
          <Form.Button
            type="button"
            content="Add More"
            onClick={add}
            positive
          />
          <Form.Button
            type="submit"
            content="Save"
            loading={isSaving}
            onClick={save}
          />
        </Form.Group>
      </Form>
    </div>
  );
};

UserAccessForm.propTypes = {
  className: PropTypes.string.isRequired,

  userId: PropTypes.number.isRequired,
  userAccesses: PropTypes.arrayOf(PropTypes.shape({
    valid_from: PropTypes.string.isRequired,
    valid_to: PropTypes.string.isRequired,
  }).isRequired).isRequired,
};

const StyledUserAccessForm = styled(UserAccessForm)`
.active-access {
  color: green;
}
`;

export default StyledUserAccessForm;
