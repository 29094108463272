import { toast } from 'react-toastify';

export default {
  sleep: s => new Promise(resolve => setTimeout(resolve, s * 1000)),
  removeItemAt: (array, index) => ([
    ...array.slice(0, index),
    ...array.slice(index + 1),
  ]),
  toastAdminApiError: (apiError) => {
    toast.error(`${apiError.code}: ${apiError.message}`, {
      position: toast.POSITION.BOTTOM_CENTER,
      autoClose: 5000,
    });
  },
  toastAdminGeneralError: (error) => {
    toast.error(`${error.name}: ${error.message}`, {
      position: toast.POSITION.BOTTOM_CENTER,
      autoClose: 5000,
    });
  },
  toastAdminGeneralSuccess: (message) => {
    toast.success(message, {
      position: toast.POSITION.BOTTOM_CENTER,
      autoClose: 5000,
    });
  },
  // one level object only
  validateRequiredParams: (paramsObj, requiredFields) => (
    requiredFields.every(key => Boolean(paramsObj[key]))
  ),
};
