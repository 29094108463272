import React from 'react';
import PropTypes from 'prop-types';
import ReactTable from 'react-table';
import { Button } from 'semantic-ui-react';
import styled from 'styled-components';

class PositionTable extends React.PureComponent {
  render() {
    const { positions } = this.props;
    const columns = [
      {
        Header: '',
        width: 100,
        Cell: props => (
          <Button
            color="red"
            type="button"
            onClick={() => { this.props.onClickEdit(props.original); }}
          >
            Edit
          </Button>
        ),
      },
      {
        Header: '# ID',
        accessor: 'id',
        width: 100,
      },
      {
        Header: 'Source Employer',
        accessor: 'source_company_name',
        className: 'multi-line',
      },
      {
        id: 'employer',
        Header: 'Employer',
        accessor: position => (position.employer || {}).name,
        className: 'multi-line',
      },
      {
        id: 'sector',
        Header: 'Sector',
        accessor: position => (((position.employer || {}).segment || {}).sector || {}).name,
        className: 'multi-line',
      },
      {
        id: 'segment',
        Header: 'Segment',
        accessor: position => ((position.employer || {}).segment || {}).name,
        className: 'multi-line',
      },
      {
        Header: 'Position URL',
        accessor: 'position_url',
        Cell: props => (
          <a href={props.value} target="_blank" rel="noopener noreferrer">
            { props.value }
          </a>
        ),
      },
      {
        Header: 'Position Target',
        accessor: 'position_target',
        className: 'multi-line',
      },
      {
        id: 'profession',
        Header: 'Profession',
        accessor: position => (position.profession || {}).name,
      },
      {
        Header: 'Source',
        accessor: 'source',
        width: 100,
        className: 'multi-line',
      },
      {
        Header: 'Status',
        accessor: 'status',
        width: 100,
        className: 'multi-line',
      },
    ];

    return (
      <ReactTable
        data={positions}
        columns={columns}
        pageSize={positions.length}
        pageSizeOptions={[]}
        className={this.props.className}
      />
    );
  }
}

PositionTable.propTypes = {
  positions: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    source_company_name: PropTypes.string.isRequired,
    position_target: PropTypes.string.isRequired,
    position_url: PropTypes.string.isRequired,
    source: PropTypes.string.isRequired,
    status: PropTypes.oneOf(['IN', 'OUT', 'PENDING']),
    employer: PropTypes.shape({
      id: PropTypes.number.isRequired,
      segment_id: PropTypes.number,
      name: PropTypes.string.isRequired,
      company_url: PropTypes.string,
      segment: PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        sector: PropTypes.shape({
          id: PropTypes.number.isRequired,
          name: PropTypes.string.isRequired,
        }),
      }),
    }),
    profession: PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }),
  }).isRequired).isRequired,

  onClickEdit: PropTypes.func.isRequired,

  className: PropTypes.string.isRequired,
};

const StyledPositionTable = styled(PositionTable)`
  .multi-line {
    white-space: unset;
  }
`;

export default StyledPositionTable;
