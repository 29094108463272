import React from 'react';
import {
  Button,
  Input,
  Form,
} from 'semantic-ui-react';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';

import PortalApi from '../../../services/PortalApi';
import Utils from '../../../lib/utils';

class InstitutionForm extends React.PureComponent {
  state = {
    isSaving: false,
    ...this.props.institution,
  }

  handleSaveClick = async () => {
    const bodyParams = {
      name: this.state.name,
      name_short: this.state.name_short,
      name_chi: this.state.name_chi,
      sort_order: this.state.sort_order,
      website: this.state.website,
      cspe_id: this.state.cspe_id,
    };

    this.setState({
      isSaving: true,
    });

    const toastId = toast.info('Saving...', {
      position: toast.POSITION.BOTTOM_CENTER,
    });

    let institution;
    try {
      if (this.props.mode === 'create') {
        institution = await this.createInstitution(bodyParams);
      } else if (this.props.mode === 'edit') {
        institution = await this.editInstitution(bodyParams);
      }

      Utils.toastAdminGeneralSuccess(`Saved ${institution.name} (id: ${institution.id})`);
    } catch (error) {
      if (error instanceof PortalApi.ApiError) {
        Utils.toastAdminApiError(error);
      } else {
        Utils.toastAdminGeneralError(error);
      }
    }

    toast.dismiss(toastId);

    this.setState({
      isSaving: false,
    });
  }

  createInstitution = async (bodyParams) => {
    const institution = await PortalApi.admin.createInstitution(bodyParams);
    this.setState({
      ...InstitutionForm.DEFAULT_FORM_STATE,
    });

    return institution;
  }

  editInstitution = async (bodyParams) => {
    const institution = await PortalApi.admin.editInstitution(this.state.id, bodyParams);
    return institution;
  }

  handleStringFieldChange = (field, event) => {
    this.setState({ [field]: event.target.value });
  }

  render() {
    return (
      <Form>
        <Form.Field
          control={Input}
          label="Institution Name"
          placeholder="Institution Name"
          value={this.state.name}
          onChange={(event) => { this.handleStringFieldChange('name', event); }}
          required
        />
        <Form.Field
          control={Input}
          label="Name (short)"
          placeholder="Name (short)"
          value={this.state.name_short}
          onChange={(event) => { this.handleStringFieldChange('name_short', event); }}
        />
        <Form.Field
          control={Input}
          label="Name (Chinese)"
          placeholder="Name (Chinese)"
          value={this.state.name_chi}
          onChange={(event) => { this.handleStringFieldChange('name_chi', event); }}
        />
        <Form.Field
          control={Input}
          label="Sort Order"
          type="number"
          value={this.state.sort_order}
          onChange={(_, { value }) => { this.setState({ sort_order: value }); }}
          required
        />
        <Form.Field
          control={Input}
          label="Website"
          placeholder="Website"
          value={this.state.website || ''}
          onChange={(event) => { this.handleStringFieldChange('website', event); }}
        />
        <Form.Field
          control={Input}
          label="CSPE ID"
          placeholder="CSPE ID"
          value={this.state.cspe_id || ''}
          onChange={(event) => { this.handleStringFieldChange('cspe_id', event); }}
        />
        <Form.Field
          control={Button}
          content="Save"
          onClick={this.handleSaveClick}
          loading={this.state.isSaving}
        />
      </Form>
    );
  }
}

InstitutionForm.DEFAULT_FORM_STATE = {
  name: '',
  id: null,
  name_short: '',
  name_chi: '',
  sort_order: 99999,
  website: '',
  cspe_id: '',
};

InstitutionForm.propTypes = {
  mode: PropTypes.string.isRequired,
  institution: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    name_short: PropTypes.string,
    name_chi: PropTypes.string,
    sort_order: PropTypes.number,
    website: PropTypes.string,
    cspe_id: PropTypes.string,
  }),
};

InstitutionForm.defaultProps = {
  institution: InstitutionForm.DEFAULT_FORM_STATE,
};

export default InstitutionForm;
