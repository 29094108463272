import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { appActions } from '../../../actions';

import PositionDateRow from './position-date-row';
import AdminPageLayout from '../layout';

import PortalApi from '../../../services/PortalApi';

const ProfessionsContext = React.createContext();

class PositionPage extends React.PureComponent {
  state = {
    positions: [],
    availableProfessions: [],
  }

  async componentDidMount() {
    await this.loadPositionData();
    await this.loadAvailableProfessions();
    this.props.finishLoading();
  }

  loadAvailableProfessions = async () => {
    const professions = await PortalApi.admin.getProfessions();
    this.setState({
      availableProfessions: professions,
    });
  }

  loadPositionData = async () => {
    const positions = await PortalApi.admin.getPositions();
    this.setState({
      positions,
    });
  }

  render() {
    const { className } = this.props;
    const positionComponents = this.state.positions.map(position => (
      <PositionDateRow key={position.post_date} position={position} />
    ));
    return (
      <div className={className}>
        <AdminPageLayout>
          <ProfessionsContext.Provider
            value={{ availableProfessions: this.state.availableProfessions }}
          >
            <div className="main-content">
              { positionComponents }
            </div>
          </ProfessionsContext.Provider>
        </AdminPageLayout>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  finishLoading: () => dispatch(appActions.finishLoading()),
});

PositionPage.propTypes = {
  finishLoading: PropTypes.func.isRequired,
  className: PropTypes.string.isRequired,
};

const StyledPositionPage = styled(PositionPage)`
  .main-content {
    padding: 0;
  }
`;

export default connect(
  null,
  mapDispatchToProps,
)(StyledPositionPage);

export {
  ProfessionsContext,
};
