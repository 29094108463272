import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Modal } from 'semantic-ui-react';

import PortalApi from '../../services/PortalApi';

class MapModal extends React.PureComponent {
  state = {
    hk: 0,
    la: 0,
    sf: 0,
    ny: 0,
    london: 0,
  }

  async componentDidMount() {
    await this.loadStatistics();
  }

  loadStatistics = async () => {
    const response = await PortalApi.getLocationEmployerCount();
    this.setState({
      hk: (response.find(location => location.name === 'Hong Kong') || { count: 0 }).count,
      la: (response.find(location => location.name === 'Los Angeles') || { count: 0 }).count,
      sf: (response.find(location => location.name === 'San Francisco') || { count: 0 }).count,
      ny: (response.find(location => location.name === 'New York') || { count: 0 }).count,
      london: (response.find(location => location.name === 'London') || { count: 0 }).count,
    });
  }

  render() {
    const { className, close } = this.props;
    return (
      <Modal
        className={className}
        size="large"
        open
        closeOnDimmerClick
        onClose={close}
      >
        <div className="map-modal">
          <img
            src="/static/images/world-map.png"
            alt="map"
          />
          <div className="map-modal__point" title="Los Angeles" style={{ left: '8.5%', top: '31%' }}>{ this.state.la }</div>
          <div className="map-modal__point" title="San Francisco" style={{ left: '8.1%', top: '29%' }}>{ this.state.sf }</div>
          <div className="map-modal__point" title="Hong Kong" style={{ left: '82.2%', top: '39.8%' }}>{ this.state.hk }</div>
          <div className="map-modal__point" title="London" style={{ left: '45.2%', top: '18.5%' }}>{ this.state.london }</div>
          <div className="map-modal__point" title="New York" style={{ left: '23.1%', top: '26.2%' }}>{ this.state.ny }</div>
        </div>
      </Modal>
    );
  }
}

MapModal.propTypes = {
  close: PropTypes.func.isRequired,
  className: PropTypes.string.isRequired,
};

const StyledMapModal = styled(MapModal)`
  .map-modal {
    position: relative;
  }
  img {
    width: 100%;
  }
  .map-modal__point {
    position: absolute;
  }
  .map-modal__point::before {
    content: '';
    height: 5px;
    width: 5px;
    background-color: red;
    border-radius: 50%;
    display: inline-block;
    margin-right: 5px;
    transform: translateY(-50%);
  }
`;

export default StyledMapModal;
