const INITIAL_STATE = {
  isLoading: true,
  userId: null,
  userRole: null,
  error: null,
  currentProgram: null,
  currentProfession: null,
};

const app = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'START_LOADING':
      return {
        ...state,
        isLoading: true,
      };
    case 'FINISH_LOADING':
      return {
        ...state,
        isLoading: false,
      };
    case 'LOG_IN':
      return {
        ...state,
        userId: action.userId,
        userRole: action.role,
      };
    case 'LOG_OUT':
      return {
        ...state,
        userId: null,
      };
    case 'SET_ERROR_MODAL':
      return {
        ...state,
        error: action.error,
      };
    case 'SET_CURRENT_PROGRAM':
      return {
        ...state,
        currentProgram: action.program,
      };
    case 'SET_CURRENT_PROFESSION':
      return {
        ...state,
        currentProfession: action.profession,
      };
    default:
      return state;
  }
};

export default app;
