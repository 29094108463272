import React from 'react';
import {
  Button,
  Input,
  Form,
  Dropdown,
  Checkbox,
} from 'semantic-ui-react';
import PropTypes from 'prop-types';

import PortalApi from '../../../services/PortalApi';
import Utils from '../../../lib/utils';

class ProgramForm extends React.PureComponent {
  state = {
    ...this.props.program,
    isSaving: false,
    disciplineOptions: [],
    institutionOptions: [],
  }

  DEGREE_TYPE_OPTIONS = [
    { key: 'Associate', text: 'Associate', value: 'Associate' },
    { key: 'Associate Degree', text: 'Associate Degree', value: 'Associate Degree' },
    { key: 'Bachelor’s Degree', text: 'Bachelor’s Degree', value: 'Bachelor’s Degree' },
    { key: 'First-year-first-degree', text: 'First-year-first-degree', value: 'First-year-first-degree' },
    { key: 'Higher Diploma', text: 'Higher Diploma', value: 'Higher Diploma' },
    { key: 'Top-up Degree', text: 'Top-up Degree', value: 'Top-up Degree' },
    { key: 'UGC-funded Senior Year Degree', text: 'UGC-funded Senior Year Degree', value: 'UGC-funded Senior Year Degree' },
  ]

  PARENT_COURSE_TYPE_OPTIONS = [
    { key: 'Sub-degree', text: 'Sub-degree', value: 'Sub-degree'  },
    { key: 'Undergraduate', text: 'Undergraduate', value: 'Undergraduate' },
  ]

  ADMISSION_MODEL_OPTIONS = [
    { key: 'Direct Admission', text: 'Direct Admission', value: 'Direct Admission'  },
    { key: 'Faculty-based Admission', text: 'Faculty-based Admission', value: 'Faculty-based Admission' },
  ]

  FUNDING_TYPE_OPTIONS = [
    { key: 'NMTSS', text: 'NMTSS', value: 'NMTSS' },
    { key: 'Publicly-funded', text: 'Publicly-funded', value: 'Publicly-funded' },
    { key: 'Self-financing', text: 'Self-financing', value: 'Self-financing' },
    { key: 'SSSDP', text: 'SSSDP', value: 'SSSDP' },
    { key: 'UGC-funded', text: 'UGC-funded', value: 'UGC-funded' },
  ]

  async componentDidMount() {
    await this.loadDisciplines();
    await this.loadInstitutions();
  }

  loadDisciplines = async () => {
    const disciplineGroups = await PortalApi.admin.getDisciplineGroups();
    const disciplineOptions = disciplineGroups.map((disciplineGroup) => {
      const disciplineOptionsOneGroup = disciplineGroup.disciplines.map(discipline => ({
        value: discipline.id,
        key: discipline.id,
        text: `${disciplineGroup.name} - ${discipline.name}`,
      }));

      return disciplineOptionsOneGroup;
    }).flat();

    this.setState({
      disciplineOptions,
    });
  }

  loadInstitutions = async () => {
    const institutions = await PortalApi.admin.getInstitutions();
    this.setState({
      institutionOptions: institutions.map(institution => ({
        key: institution.id,
        text: `${institution.name_short} - ${institution.name_chi} - ${institution.name}`,
        value: institution.id,
      })),
    });
  }

  handleSaveClick = async () => {
    const bodyParams = {
      name: this.state.name,
      disciplines: this.state.disciplines,
      login_banner_url: this.state.login_banner_url || null,
      institution_id: this.state.institution_id,
      name_chi: this.state.name_chi || null,
      name_short: this.state.name_short || null,
      sort_order: this.state.sort_order,
      degree_type: this.state.degree_type || null,
      parent_course_type: this.state.parent_course_type || null,
      duration_of_study: this.state.duration_of_study || null,
      funding_type: this.state.funding_type || null,
      overseas_institution_name: this.state.overseas_institution_name || null,
      faculty: this.state.faculty || null,
      department: this.state.department || null,
      program_type: this.state.program_type || null,
      jupas_url: this.state.jupas_url || null,
      bigexam_url: this.state.bigexam_url || null,
      jupas_code: this.state.jupas_code || null,
      cspe_program_detail_url: this.state.cspe_program_detail_url || null,
      cspe_program_id: this.state.cspe_program_id || null,
      cspe_prov_id: this.state.cspe_prov_id || null,
      cspe_academic_year: this.state.cspe_academic_year || null,
      admission_model: this.state.admission_model || null,
      is_active: this.state.is_active,
      source: this.state.source || null,
    };

    this.setState({
      isSaving: true,
    });

    let program;
    try {
      if (this.props.mode === 'create') {
        program = await this.createProgram(bodyParams);
      } else if (this.props.mode === 'edit') {
        program = await this.editProgram(bodyParams);
      }
      Utils.toastAdminGeneralSuccess(`Saved ${program.name} (id: ${program.id})`);
    } catch (error) {
      if (error instanceof PortalApi.ApiError) {
        Utils.toastAdminApiError(error);
      } else {
        Utils.toastAdminGeneralError(error);
      }
    }

    this.setState({
      isSaving: false,
    });
  }

  createProgram = async (bodyParams) => {
    const program = await PortalApi.admin.createProgram(bodyParams);

    this.setState({
      ...ProgramForm.DEFAULT_FORM_STATE,
    });
    return program;
  }

  editProgram = async (bodyParams) => {
    const program = await PortalApi.admin.saveProgram(this.state.id, bodyParams);
    this.props.updateProgramDisciplines(this.state.disciplines);
    return program;
  }

  render() {
    return (
      <Form>
        <Form.Field
          control={Input}
          label="Program Name"
          placeholder="Program Name"
          value={this.state.name}
          onChange={(_, { value }) => { this.setState({ name: value }); }}
          required
        />
        <Form.Field
          control={Dropdown}
          fluid
          selection
          label="Discipline"
          placeholder="Discipline"
          value={this.state.disciplines.map(d => d.id)}
          search
          multiple
          lazyLoad
          options={this.state.disciplineOptions}
          onChange={(_, { value }) => { this.setState({ disciplines: value.map(id => ({ id })) }); }}
          required
        />
        <Form.Field
          control={Input}
          label="Login Banner URL (Link to an image)"
          placeholder="Login Banner URL (Link to an image)"
          value={this.state.login_banner_url || ''}
          onChange={(_, { value }) => { this.setState({ login_banner_url: value }); }}
        />
        <Form.Field
          control={Dropdown}
          fluid
          selection
          label="Institution"
          placeholder="Institution"
          value={this.state.institution_id}
          search
          lazyLoad
          options={this.state.institutionOptions}
          onChange={(_, { value }) => { this.setState({ institution_id: value }); }}
          required
        />
        <Form.Field
          control={Input}
          label="Program Name (Chinese)"
          placeholder="Program Name (Chinese)"
          value={this.state.name_chi || ''}
          onChange={(_, { value }) => { this.setState({ name_chi: value }); }}
        />
        <Form.Field
          control={Input}
          label="Program Name (short)"
          placeholder="Program Name (short)"
          value={this.state.name_short || ''}
          onChange={(_, { value }) => { this.setState({ name_short: value }); }}
        />
        <Form.Field
          control={Input}
          label="Sort Order"
          type="number"
          value={this.state.sort_order}
          onChange={(_, { value }) => { this.setState({ sort_order: value }); }}
          required
        />
        <Form.Dropdown
          label="Degree Type"
          placeholder="Degree Type"
          options={this.DEGREE_TYPE_OPTIONS}
          selection
          search
          value={this.state.degree_type || ''}
          onChange={(_, { value }) => { this.setState({ degree_type: value }); }}
        />
        <Form.Dropdown
          label="Parent Course Type"
          placeholder="Parent Course Type"
          options={this.PARENT_COURSE_TYPE_OPTIONS}
          selection
          search
          value={this.state.parent_course_type || ''}
          onChange={(_, { value }) => { this.setState({ parent_course_type: value }); }}
        />
        <Form.Field
          control={Input}
          label="Duration Of Study"
          placeholder="Duration Of Study"
          type="number"
          value={this.state.duration_of_study || ''}
          onChange={(_, { value }) => { this.setState({ duration_of_study: value }); }}
        />
        <Form.Dropdown
          label="Funding Type"
          placeholder="Funding Type"
          options={this.FUNDING_TYPE_OPTIONS}
          selection
          search
          value={this.state.funding_type || ''}
          onChange={(_, { value }) => { this.setState({ funding_type: value }); }}
        />
        <Form.Field
          control={Input}
          label="Overseas Institution Name"
          placeholder="Overseas Institution Name"
          value={this.state.overseas_institution_name || ''}
          onChange={(_, { value }) => { this.setState({ overseas_institution_name: value }); }}
        />
        <Form.Field
          control={Input}
          label="Faculty"
          placeholder="Faculty"
          value={this.state.faculty || ''}
          onChange={(_, { value }) => { this.setState({ faculty: value }); }}
        />
        <Form.Field
          control={Input}
          label="Department"
          placeholder="Department"
          value={this.state.department || ''}
          onChange={(_, { value }) => { this.setState({ department: value }); }}
        />
        <Form.Field
          control={Input}
          label="Program Type"
          placeholder="Program Type"
          value={this.state.program_type || ''}
          onChange={(_, { value }) => { this.setState({ program_type: value }); }}
        />
        <Form.Field
          control={Input}
          label="JUPAS URL"
          placeholder="JUPAS URL"
          value={this.state.jupas_url || ''}
          onChange={(_, { value }) => { this.setState({ jupas_url: value }); }}
        />
        <Form.Field
          control={Input}
          label="Big Exam URL"
          placeholder="Big Exam URL"
          value={this.state.bigexam_url || ''}
          onChange={(_, { value }) => { this.setState({ bigexam_url: value }); }}
        />
        <Form.Field
          control={Input}
          label="JUPAS code"
          placeholder="JUPAS code"
          value={this.state.jupas_code || ''}
          onChange={(_, { value }) => { this.setState({ jupas_code: value }); }}
        />
        <Form.Field
          control={Input}
          label="CSPE Program Detail URL (CSPE: Concourse for Self-financing Post-secondary Education)"
          placeholder="CSPE Program Detail URL (CSPE: Concourse for Self-financing Post-secondary Education)"
          value={this.state.cspe_program_detail_url || ''}
          onChange={(_, { value }) => { this.setState({ cspe_program_detail_url: value }); }}
        />
        <Form.Field
          control={Input}
          label="CSPE Program ID"
          placeholder="CSPE Program ID"
          value={this.state.cspe_program_id || ''}
          onChange={(_, { value }) => { this.setState({ cspe_program_id: value }); }}
        />
        <Form.Field
          control={Input}
          label="CSPE Prov ID"
          placeholder="CSPE Prov ID"
          value={this.state.cspe_prov_id || ''}
          onChange={(_, { value }) => { this.setState({ cspe_prov_id: value }); }}
        />
        <Form.Field
          control={Input}
          label="CSPE Academic Year"
          placeholder="CSPE Academic Year"
          value={this.state.cspe_academic_year || ''}
          onChange={(_, { value }) => { this.setState({ cspe_academic_year: value }); }}
        />
        <Form.Dropdown
          label="Admission Model"
          placeholder="Admission Model"
          options={this.ADMISSION_MODEL_OPTIONS}
          selection
          search
          value={this.state.admission_model || ''}
          onChange={(_, { value }) => { this.setState({ admission_model: value }); }}
        />
        <Form.Field
          control={Input}
          label="Source"
          placeholder="Source"
          value={this.state.source || ''}
          onChange={(_, { value }) => { this.setState({ source: value }); }}
        />
        <Form.Field
          control={Checkbox}
          label="Is Active?"
          checked={this.state.is_active}
          onChange={(_, { checked }) => { this.setState({ is_active: checked }); }}
        />
        <Form.Field
          control={Button}
          content="Save"
          loading={this.state.isSaving}
          onClick={this.handleSaveClick}
        />
      </Form>
    );
  }
}

ProgramForm.DEFAULT_FORM_STATE = {
  id: null,
  name: '',
  disciplines: [],
  login_banner_url: '',
  institution_id: null,
  name_chi: '',
  name_short: '',
  sort_order: 99999,
  degree_type: '',
  parent_course_type: '',
  duration_of_study: 4,
  funding_type: '',
  overseas_institution_name: '',
  faculty: '',
  department: '',
  program_type: '',
  jupas_url: '',
  bigexam_url: '',
  jupas_code: '',
  cspe_program_detail_url: '',
  cspe_program_id: '',
  cspe_prov_id: '',
  cspe_academic_year: '',
  admission_model: '',
  is_active: true,
  source: '',
};

ProgramForm.propTypes = {
  updateProgramDisciplines: PropTypes.func.isRequired,
  mode: PropTypes.string.isRequired,
  program: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string,
    login_banner_url: PropTypes.string,
    institution_id: PropTypes.number.isRequired,
    name_chi: PropTypes.string,
    name_short: PropTypes.string,
    sort_order: PropTypes.number,
    degree_type: PropTypes.string,
    parent_course_type: PropTypes.string,
    duration_of_study: PropTypes.number,
    funding_type: PropTypes.string,
    overseas_institution_name: PropTypes.string,
    faculty: PropTypes.string,
    department: PropTypes.string,
    program_type: PropTypes.string,
    jupas_url: PropTypes.string,
    bigexam_url: PropTypes.string,
    jupas_code: PropTypes.string,
    cspe_program_detail_url: PropTypes.string,
    cspe_program_id: PropTypes.string,
    cspe_prov_id: PropTypes.string,
    cspe_academic_year: PropTypes.string,
    admission_model: PropTypes.string,
    is_active: PropTypes.bool,
    source: PropTypes.string,
  }),
};

ProgramForm.defaultProps = {
  program: ProgramForm.DEFAULT_FORM_STATE,
};

export default ProgramForm;
