import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  Button,
  Input,
  Form,
  Dropdown,
} from 'semantic-ui-react';
import { toast } from 'react-toastify';
import uuidv1 from 'uuid/v1';

import PortalApi from '../../../services/PortalApi';
import Utils from '../../../lib/utils';

class RelatedSegmentsForm extends React.PureComponent {
  state = {
    assignments: this.props.relatedSegmentAssignments.map(assignment => ({
      segment_id: assignment.segment_id,
      seq: assignment.seq,
      uuid: uuidv1(), // use as key to render
    })),
    segmentOptions: [],
    isSaving: false,
  }

  async componentDidMount() {
    await this.loadSegments();
  }

  loadSegments = async () => {
    const segments = await PortalApi.admin.getSegments();
    this.setState({
      segmentOptions: segments.map(segment => ({
        key: segment.id,
        text: `${segment.sector.name} - ${segment.name}`,
        value: segment.id,
      })),
    });
  }

  saveRelatedSegments = async () => {
    const body = this.state.assignments.map((assignment => ({
      segment_id: assignment.segment_id,
      seq: assignment.seq,
    })));

    this.setState({
      isSaving: true,
    });

    await PortalApi.admin.saveProfessionRelatedSegments(this.props.professionId, body);

    this.setState({
      isSaving: false,
    });

    toast.success('Saved!', {
      position: toast.POSITION.BOTTOM_CENTER,
      autoClose: 5000,
    });
  }

  addRelatedSegment = () => {
    this.setState(prevState => ({
      assignments: [
        ...prevState.assignments,
        { segment_id: null, seq: 9999, uuid: uuidv1() },
      ],
    }));
  }

  removeRelatedSegment = (index) => {
    this.setState(prevState => ({
      assignments: Utils.removeItemAt(prevState.assignments, index),
    }));
  }

  handleAssignmentChange = (indexToChange, newSegmentId, newSeq) => {
    this.setState((prevState) => {
      const newAssignments = prevState.assignments.map((assignment, index) => {
        if (indexToChange === index) {
          return {
            segment_id: newSegmentId || assignment.segment_id,
            seq: newSeq || assignment.seq,
            uuid: assignment.uuid,
          };
        }
        return { ...assignment };
      });

      return {
        assignments: newAssignments,
      };
    });
  };

  render() {
    const segmentComponents = this.state.assignments.map((assignment, index) => (
      // eslint-disable-next-line react/no-array-index-key
      <Form.Group key={assignment.uuid}>
        <Form.Field width={6}>
          <Dropdown
            fluid
            placeholder="Segment"
            options={this.state.segmentOptions}
            selection
            required
            lazyLoad
            search
            value={assignment.segment_id}
            onChange={(_, { value }) => { this.handleAssignmentChange(index, value, null); }}
          />
        </Form.Field>
        <Form.Field width={2}>
          <Input
            defaultValue={assignment.seq}
            required
            placeholder="Sequence (Ordering)"
            fluid
            type="number"
            onChange={(_, { value }) => { this.handleAssignmentChange(index, null, value); }}
          />
        </Form.Field>
        <Button
          negative
          onClick={() => { this.removeRelatedSegment(index); }}
          type="button"
        >
          Remove
        </Button>
      </Form.Group>
    ));

    return (
      <div className={`${this.props.className} profession-segments`}>
        <h1>Related Segments</h1>
        <Form>
          { segmentComponents }
          <Form.Group>
            <Form.Field
              inline
              control={Button}
              positive
              onClick={this.addRelatedSegment}
              type="button"
            >
              Add More
            </Form.Field>
            <Form.Field
              inline
              control={Button}
              onClick={this.saveRelatedSegments}
              loading={this.state.isSaving}
              content="Save"
              type="submit"
            />
          </Form.Group>
        </Form>
      </div>
    );
  }
}

RelatedSegmentsForm.propTypes = {
  professionId: PropTypes.number.isRequired,
  relatedSegmentAssignments: PropTypes.arrayOf(PropTypes.shape({
    segment_id: PropTypes.number.isRequired,
    seq: PropTypes.number.isRequired,
  }).isRequired).isRequired,

  className: PropTypes.string.isRequired,
};

const StyledRelatedSegmentsForm = styled(RelatedSegmentsForm)`
`;

export default StyledRelatedSegmentsForm;
