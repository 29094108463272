import React from 'react';
import {
  Button,
  Input,
  Form,
  Checkbox,
} from 'semantic-ui-react';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';

import PortalApi from '../../../services/PortalApi';
import Utils from '../../../lib/utils';


class ProfessionForm extends React.PureComponent {
  state = {
    ...this.props.profession,
    availableResearchStatuses: [],
  }

  async componentDidMount() {
    await this.loadAvailableResearchStatuses();
  }

  loadAvailableResearchStatuses = async () => {
    const availableResearchStatuses = await PortalApi.admin.getAvailableResearchStatuses();
    this.setState({
      availableResearchStatuses,
    });
  }

  handleCreateClick = async () => {
    const bodyParams = {
      name: this.state.name,
      name_chinese: this.state.name_chinese,
      last_researched_at: this.state.last_researched_at || null,
      research_status_id: this.state.research_status_id,
      peer_reviewed_by: this.state.peer_reviewed_by || '',
      presented_to: this.state.presented_to || '',
      practitioner_review_by: this.state.practitioner_review_by || '',
      responsible_practitioners: this.state.responsible_practitioners || '',
      whatsapp_id: this.state.whatsapp_id || '',
      work_in_progress_by: this.state.work_in_progress_by || '',
      requires_secondary_school: this.state.requires_secondary_school,
      requires_higher_diploma_and_associate: this.state.requires_higher_diploma_and_associate,
      requires_bachelor: this.state.requires_bachelor,
      requires_master: this.state.requires_master,
      requires_doctorate: this.state.requires_doctorate,
    };

    const isValid = Utils.validateRequiredParams(bodyParams, ['name', 'research_status_id']);
    if (!isValid) {
      Utils.toastAdminGeneralError(new Error('Name and Research Status are required.'));
      return;
    }

    if (this.props.mode === 'create') {
      await this.createProfession(bodyParams);
    } else if (this.props.mode === 'edit') {
      await this.editProfession(bodyParams);
    }
  }

  createProfession = async (bodyParams) => {
    const toastId = toast.info('Saving...', {
      position: toast.POSITION.BOTTOM_CENTER,
    });

    try {
      const profession = await PortalApi.admin.createProfession(bodyParams);
      Utils.toastAdminGeneralSuccess(`Saved ${profession.name} (id: ${profession.id})`);
      this.setState({
        ...ProfessionForm.DEFAULT_FORM_STATE,
      });
    } catch (error) {
      if (error instanceof PortalApi.ApiError) {
        Utils.toastAdminApiError(error);
      } else {
        Utils.toastAdminGeneralError(error);
      }
    }
    toast.dismiss(toastId);
  }

  editProfession = async (bodyParams) => {
    const toastId = toast.info('Saving...', {
      position: toast.POSITION.BOTTOM_CENTER,
    });

    try {
      const profession = await PortalApi.admin.saveProfession(this.state.id, bodyParams);
      Utils.toastAdminGeneralSuccess(`Saved ${profession.name} (id: ${profession.id})`);
    } catch (error) {
      if (error instanceof PortalApi.ApiError) {
        Utils.toastAdminApiError(error);
      } else {
        Utils.toastAdminGeneralError(error);
      }
    }
    toast.dismiss(toastId);
  }
  
  
  handleStringFieldChange = (field, event) => {
    this.setState({ [field]: event.target.value });
  }

  render() {
    const researchStatusOptions = this.state.availableResearchStatuses.map(status => ({
      key: status.id,
      text: status.status_string,
      value: status.id,
    }));

    return (
      <Form>
        <Form.Field
          control={Input}
          label="Profession Name"
          placeholder="Profession Name"
          value={this.state.name}
          onChange={(event) => { this.handleStringFieldChange('name', event); }}
          required
        />
        <Form.Field
          control={Input}
          label="Name (Chinese)"
          placeholder="Name (Chinese)"
          value={this.state.name_chinese}
          onChange={(event) => { this.handleStringFieldChange('name_chinese', event); }}
        />
        <Form.Field
          control={Input}
          type="date"
          label="Last Researched At"
          placeholder="Last Researched At"
          value={this.state.last_researched_at || ''}
          onChange={(_, { value }) => { this.setState({ last_researched_at: value }); }}
        />
        <Form.Dropdown
          label="Research Status"
          placeholder="Research Status"
          options={researchStatusOptions}
          selection
          search
          value={this.state.research_status_id}
          required
          onChange={(_, { value }) => { this.setState({ research_status_id: value }); }}
        />
        <Form.Field
          control={Input}
          label="Peer Review By"
          placeholder="Peer Review By"
          value={this.state.peer_reviewed_by}
          onChange={(event) => { this.handleStringFieldChange('peer_reviewed_by', event); }}
        />
        <Form.Field
          control={Input}
          label="Presented To"
          placeholder="Presented To"
          value={this.state.presented_to}
          onChange={(event) => { this.handleStringFieldChange('presented_to', event); }}
        />
        <Form.Field
          control={Input}
          label="Practitioner Review By"
          placeholder="Practitioner Review By"
          value={this.state.practitioner_review_by}
          onChange={(event) => { this.handleStringFieldChange('practitioner_review_by', event); }}
        />
        <Form.Field
          control={Input}
          label="Responsible Practitioners"
          placeholder="Responsible Practitioners"
          value={this.state.responsible_practitioners}
          onChange={(event) => { this.handleStringFieldChange('responsible_practitioners', event); }}
        />
        <Form.Field
          control={Input}
          label="Work In Progress By"
          placeholder="Work In Progress By"
          value={this.state.work_in_progress_by}
          onChange={(event) => { this.handleStringFieldChange('work_in_progress_by', event); }}
        />
        <p>
           Sufficient qualification for at least 1 position in this profession
        </p>
        <Form.Field
          control={Checkbox}
          label="Secondary School"
          checked={this.state.requires_secondary_school}
          onChange={(_, { checked }) => { this.setState({ requires_secondary_school: checked }); }}
        />
        <Form.Field
          control={Checkbox}
          label="Higher Diploma And Associate"
          checked={this.state.requires_higher_diploma_and_associate}
          onChange={(_, { checked }) => { this.setState({ requires_higher_diploma_and_associate: checked }); }}
        />
        <Form.Field
          control={Checkbox}
          label="Bachelor"
          checked={this.state.requires_bachelor}
          onChange={(_, { checked }) => { this.setState({ requires_bachelor: checked }); }}
        />
        <Form.Field
          control={Checkbox}
          label="Master"
          checked={this.state.requires_master}
          onChange={(_, { checked }) => { this.setState({ requires_master: checked }); }}
        />
        <Form.Field
          control={Checkbox}
          label="Ph.D"
          checked={this.state.requires_doctorate}
          onChange={(_, { checked }) => { this.setState({ requires_doctorate: checked }); }}
        />
        <Form.Field
          control={Button}
          content="Save"
          onClick={this.handleCreateClick}
        />
      </Form>
    );
  }
}

ProfessionForm.DEFAULT_FORM_STATE = {
  name: '',
  name_chinese: '',
  last_researched_at: '',
  research_status_id: null,
  peer_reviewed_by: '',
  presented_to: '',
  practitioner_review_by: '',
  responsible_practitioners: '',
  whatsapp_id: '',
  work_in_progress_by: '',
  requires_secondary_school: false,
  requires_higher_diploma_and_associate: false,
  requires_bachelor: false,
  requires_master: false,
  requires_doctorate: false,
};

ProfessionForm.propTypes = {
  mode: PropTypes.string.isRequired,
  profession: PropTypes.shape({}),
};

ProfessionForm.defaultProps = {
  profession: ProfessionForm.DEFAULT_FORM_STATE,
};

export default ProfessionForm;
