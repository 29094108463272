import React from 'react';
import {
  Button,
  Input,
  Form,
  Checkbox,
} from 'semantic-ui-react';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';

import PortalApi from '../../../services/PortalApi';
import Utils from '../../../lib/utils';


class EmployerForm extends React.PureComponent {
  state = {
    ...this.props.employer,
    availableSegments: [],
    isSaving: false,
  }

  async componentDidMount() {
    const segments = await PortalApi.admin.getSegments();
    this.setState({
      availableSegments: segments,
    });
  }

  validateParams = params => (
    ['segment_id', 'name', 'company_url', 'seq'].every(key => Boolean(params[key]))
  )

  handleCreateClick = async () => {
    const bodyParams = {
      segment_id: this.state.segmentId,
      name: this.state.name,
      company_url: this.state.companyUrl,
      brand_name: this.state.brandName,
      show_on_portal_type: this.state.showOnPortalType,
      is_mnc: this.state.isMnc,
      seq: this.state.seq,
      company_url_status: this.state.companyUrlStatus,
    };

    if (!this.validateParams(bodyParams)) {
      toast.error('Please complete the information', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 2000,
      });
      return;
    }

    this.setState({
      isSaving: true,
    });

    if (this.props.mode === 'create') {
      await this.createEmployer(bodyParams);
    } else if (this.props.mode === 'edit') {
      await this.editEmployer(bodyParams);
    }

    this.setState({
      isSaving: false,
    });
  }

  createEmployer = async (bodyParams) => {
    try {
      const employer = await PortalApi.admin.createEmployer(bodyParams);
      Utils.toastAdminGeneralSuccess(`Saved ${employer.name} (id: ${employer.id})`);
      this.setState({
        ...EmployerForm.DEFAULT_FORM_STATE,
      });
    } catch (error) {
      if (error instanceof PortalApi.ApiError) {
        Utils.toastAdminApiError(error);
      } else {
        Utils.toastAdminGeneralError(error);
      }
    }
  }

  editEmployer = async (bodyParams) => {
    try {
      const employer = await PortalApi.admin.saveEmployer(this.state.id, bodyParams);
      Utils.toastAdminGeneralSuccess(`Saved ${employer.name} (id: ${employer.id})`);
    } catch (error) {
      if (error instanceof PortalApi.ApiError) {
        Utils.toastAdminApiError(error);
      } else {
        Utils.toastAdminGeneralError(error);
      }
    }
  }

  render() {
    const segmentOptions = this.state.availableSegments.map(segment => ({
      key: segment.id,
      text: `${segment.sector.name} - ${segment.name}`,
      value: segment.id,
    }));

    const showOnPortalTypeOptions = [
      {
        key: 'Hide',
        text: 'Hide',
        value: 'Hide',
      },
      {
        key: 'Always',
        text: 'Always',
        value: 'Always',
      },
      {
        key: 'More',
        text: 'More',
        value: 'More',
      },
    ];

    return (
      <Form>
        <Form.Field
          control={Input}
          label="Employer Name"
          placeholder="Employer Name"
          value={this.state.name}
          onChange={(_, { value }) => { this.setState({ name: value }); }}
          required
        />
        <Form.Dropdown
          label="Segment"
          placeholder="Segment"
          // this cause form UI very slow
          options={segmentOptions}
          fluid
          clearable
          selection
          required
          search
          value={this.state.segmentId}
          onChange={(_, { value }) => { this.setState({ segmentId: value }); }}
          lazyLoad
        />
        <Form.Dropdown
          label="Show On Portal"
          placeholder="Show On Portal"
          options={showOnPortalTypeOptions}
          fluid
          selection
          required
          value={this.state.showOnPortalType}
          onChange={(_, { value }) => { this.setState({ showOnPortalType: value }); }}
        />
        <Form.Field
          control={Input}
          label="Company URL"
          placeholder="Company URL"
          value={this.state.companyUrl}
          onChange={(_, { value }) => { this.setState({ companyUrl: value }); }}
          required
        />
        <Form.Field
          control={Input}
          label="Brand Name"
          placeholder="Brand Name"
          value={this.state.brandName}
          onChange={(_, { value }) => { this.setState({ brandName: value }); }}
        />
        <Form.Field
          control={Checkbox}
          label="Is MNC?"
          checked={this.state.isMnc}
          onChange={(_, { checked }) => { this.setState({ isMnc: checked }); }}
        />
        <Form.Field
          control={Checkbox}
          label="Is Deadlink?"
          checked={this.state.companyUrlStatus}
          onChange={(_, { checked }) => { this.setState({ companyUrlStatus: checked }); }}
        />
        <Form.Field
          control={Input}
          label="Sequence"
          type="number"
          value={this.state.seq}
          onChange={(_, { value }) => { this.setState({ seq: value }); }}
          required
        />
        <Form.Field
          control={Button}
          content="Save"
          onClick={this.handleCreateClick}
          loading={this.state.isSaving}
        />
      </Form>
    );
  }
}

EmployerForm.DEFAULT_FORM_STATE = {
  name: '',
  segmentId: null,
  companyUrl: '',
  brandName: '',
  showOnPortalType: 'Hide',
  isMnc: false,
  companyUrlStatus: false,
  seq: '99999',
};

EmployerForm.propTypes = {
  mode: PropTypes.string.isRequired,
  employer: PropTypes.shape({}),
};

EmployerForm.defaultProps = {
  employer: EmployerForm.DEFAULT_FORM_STATE,
};

export default EmployerForm;
