import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { appActions } from '../../../actions';

import AdminPageLayout from '../layout';
import InstitutionForm from './form';

import PortalApi from '../../../services/PortalApi';

class InstitutionEditPage extends React.PureComponent {
  state = {
    institution: null,
  }

  async componentDidMount() {
    await this.loadInstitution();
    this.props.finishLoading();
  }

  loadInstitution = async () => {
    const institution = await PortalApi.admin.getInstitution(this.props.match.params.id);
    this.setState({
      institution,
    });
  }

  render() {
    const { className } = this.props;

    if (!this.state.institution) {
      return null;
    }

    return (
      <div className={className}>
        <AdminPageLayout>
          <div className="main-content">
            <h1>{ this.state.institution.name }</h1>
            <InstitutionForm mode="edit" institution={this.state.institution} />
          </div>
        </AdminPageLayout>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  finishLoading: () => dispatch(appActions.finishLoading()),
});

InstitutionEditPage.propTypes = {
  finishLoading: PropTypes.func.isRequired,
  className: PropTypes.string.isRequired,

  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }).isRequired,
};

const StyledInstitutionEditPage = styled(InstitutionEditPage)`
`;

export default connect(
  null,
  mapDispatchToProps,
)(StyledInstitutionEditPage);
