/* eslint-disable jsx-a11y/label-has-for */
import React from 'react';
import {
  Button,
  Input,
  Form,
  Card,
  TextArea,
} from 'semantic-ui-react';
import PropTypes from 'prop-types';
import uuidv1 from 'uuid/v1';

import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/semantic-ui.css';

import PortalApi from '../../../services/PortalApi';
import Utils from '../../../lib/utils';

class ProgramRelatedContacts extends React.PureComponent {
  state = {
    titleOptions: [],
    existingContacts: [],
    existingContactOptions: [],
    relatedContacts: [],
    isSaving: false,
  }

  async componentDidMount() {
    await this.loadAllContacts();
    await this.loadRelatedContacts();
    await this.loadContactRelationOptions();
  }

  loadAllContacts = async () => {
    const contacts = await PortalApi.admin.getContacts();
    const contactOptions = contacts.map(contact => ({ value: contact.id, text: `${contact.id} - ${contact.name} (${contact.email})` }));
    this.setState({
      existingContacts: contacts,
      existingContactOptions: contactOptions,
    });
  }

  loadRelatedContacts = async () => {
    const relatedContacts = await PortalApi.admin.getProgramRelatedContacts(this.props.programId);
    const parsedRelatedContacts = relatedContacts.map((contact) => {
      const title = contact.contact_relations[0] ? contact.contact_relations[0].relation : '';
      return {
        ...contact,
        relation: title,
        uuid: uuidv1(),
      };
    });

    this.setState({
      relatedContacts: parsedRelatedContacts,
    });
  }

  loadContactRelationOptions = async () => {
    const existingRelationOptions = await PortalApi.admin.getContactRelationRelationOptions();
    this.setState({ titleOptions: existingRelationOptions.program });
  }

  handleAddTitle = (_, { value }) => {
    this.setState(prevState => ({
      titleOptions: [{ text: value, value }, ...prevState.titleOptions],
    }));
  }

  handleSelectContact = (uuid, selectedContactId) => {
    const selectedContact = this.state.existingContacts.find(contact => contact.id === selectedContactId);
    this.setState(prevState => ({
      relatedContacts: prevState.relatedContacts.map((contact) => {
        if (contact.uuid === uuid) {
          return {
            id: selectedContactId,
            uuid,
            ...selectedContact,
          };
        }
        return { ...contact };
      }),
    }));
  }

  handleChange = (uuid, key, value) => {
    this.setState(prevState => ({
      relatedContacts: prevState.relatedContacts.map((contact) => {
        if (contact.uuid === uuid) {
          return {
            ...contact,
            [key]: value,
          };
        }
        return { ...contact };
      }),
    }));
  }

  remove = (uuid) => {
    this.setState(prevState => ({
      relatedContacts: prevState.relatedContacts.filter(c => c.uuid !== uuid),
    }));
  }

  add = () => {
    this.setState(prevState => ({
      relatedContacts: [
        ...prevState.relatedContacts,
        {
          id: '',
          uuid: uuidv1(),
        },
      ],
    }));
  }

  save = async () => {
    const body = this.state.relatedContacts.map(contact => ({
      id: contact.id,
      name: contact.name,
      salutation: contact.salutation,
      email: contact.email,
      source: contact.source,
      source_link: contact.source_link,
      whatsapp_number: contact.whatsapp_number,
      phone_number: contact.phone_number,
      remarks: contact.remarks,
      relation: contact.relation,
    }));

    const isValid = body.every(contact => Utils.validateRequiredParams(contact, ['name', 'salutation', 'email', 'relation']));
    if (!isValid) {
      Utils.toastAdminGeneralError(new Error('Name, Salutation, Email and Relation are required'));
      return;
    }

    this.setState({
      isSaving: true,
    });

    try {
      const response = await PortalApi.admin.saveProgramRelatedContacts(this.props.programId, body);
      Utils.toastAdminGeneralSuccess('Saved Related Contacts!');
      this.setState({
        relatedContacts: response.map(contact => ({
          ...contact,
          relation: contact.contact_relations[0] ? contact.contact_relations[0].relation : '',
          uuid: uuidv1(),
        })),
      });
      await this.loadAllContacts(); // refersh the contact options
    } catch (error) {
      if (error instanceof PortalApi.ApiError) {
        Utils.toastAdminApiError(error);
      } else {
        Utils.toastAdminGeneralError(error);
      }
    }

    this.setState({
      isSaving: false,
    });
  }

  renderContacts = () => {
    const inputConfigs = [
      { name: 'name', label: 'Name', isRequired: true },
      { name: 'salutation', label: 'Salutation', isRequired: true },
      { name: 'email', label: 'Email', isRequired: true },
      { name: 'phone_number', label: 'Phone Number' },
      { name: 'whatsapp_number', label: 'Whatsapp Number' },
      { name: 'source', label: 'Source Name' },
      { name: 'source_link', label: 'Source Link' },
      {
        name: 'remarks', label: 'Remarks', control: TextArea, fluid: null,
      },
    ];
    return this.state.relatedContacts.map(contact => (
      <Card color="blue" key={contact.uuid}>
        <Card.Content>
          <Form size="small">
            <Form.Dropdown
              options={this.state.existingContactOptions}
              placeholder="New Contact"
              search
              selection
              fluid
              clearable
              value={contact.id || null}
              onChange={(_, { value }) => { this.handleSelectContact(contact.uuid, value); }}
            />
            <Form.Dropdown
              options={this.state.titleOptions}
              label="Title"
              placeholder="Title"
              search
              selection
              fluid
              allowAdditions
              required
              value={contact.relation || ''}
              onAddItem={this.handleAddTitle}
              onChange={(_, { value }) => { this.handleChange(contact.uuid, 'relation', value); }}
            />
            { inputConfigs.map(inputConfig => (
              ['phone_number', 'whatsapp_number'].includes(inputConfig.name)
                ? (
                  <Form.Field key={inputConfig.name}>
                    <label htmlFor={inputConfig.name}>{inputConfig.label}</label>
                    <PhoneInput
                      placeholder={inputConfig.label}
                      inputProps={{ id: inputConfig.name }}
                      inputStyle={{ paddingLeft: '45px' }}
                      onlyCountries={['hk', 'gb', 'us', 'cn', 'sg']}
                      country="hk"
                      value={contact[inputConfig.name] || ''}
                      onChange={(phone) => { this.handleChange(contact.uuid, inputConfig.name, phone); }}
                    />
                  </Form.Field>
                ) : (
                  <Form.Field
                    key={inputConfig.name}
                    type={inputConfig.type || 'text'}
                    id={inputConfig.name}
                    label={inputConfig.hideLabel ? undefined : inputConfig.label}
                    placeholder={inputConfig.label}
                    control={inputConfig.control || Input}
                    required={!!inputConfig.isRequired}
                    fluid={inputConfig.fluid === null ? undefined : true}
                    value={contact[inputConfig.name] || ''}
                    onChange={(_, { value }) => { this.handleChange(contact.uuid, inputConfig.name, value); }}
                  />
                )
            ))}
            <Button
              negative
              onClick={() => { this.remove(contact.uuid); }}
              type="button"
              content="Remove"
            />
          </Form>
        </Card.Content>
      </Card>
    ));
  }

  render() {
    return (
      <div>
        <h1>Related Contacts</h1>
        <Card.Group>
          { this.renderContacts() }
        </Card.Group>
        <Button
          positive
          onClick={this.add}
          type="button"
          content="Add More"
        />
        <Button
          style={{ marginTop: '10px' }}
          content="Save Related Contacts"
          type="submit"
          onClick={this.save}
          loading={this.state.isSaving}
        />
      </div>
    );
  }
}

ProgramRelatedContacts.propTypes = {
  programId: PropTypes.number.isRequired,
};

export default ProgramRelatedContacts;
