import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { appActions } from '../../../actions';

import AdminPageLayout from '../layout';
import SectorForm from './form';
import ExtraInfoForm from '../components/extra-info-form/index';
import RelatedContacts from './related-contacts';

import PortalApi from '../../../services/PortalApi';

class SectorEditPage extends React.PureComponent {
  state = {
    sector: null,
  }

  async componentDidMount() {
    await this.loadSector();
    this.props.finishLoading();
  }

  loadSector = async () => {
    const sector = await PortalApi.admin.getSector(this.props.match.params.id);
    this.setState({
      sector,
    });
  }

  render() {
    const { className } = this.props;

    if (!this.state.sector) {
      return null;
    }

    return (
      <div className={className}>
        <AdminPageLayout>
          <div className="main-content">
            <h1>{ this.state.sector.name }</h1>
            <SectorForm mode="edit" sector={this.state.sector} />
            <hr />
            <RelatedContacts
              sectorId={this.state.sector.id}
            />
            <hr />
            <ExtraInfoForm
              extraInfoGroupId={this.state.sector.extra_info_group_id}
              extraInfos={this.state.sector.extra_infos}
            />
          </div>
        </AdminPageLayout>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  finishLoading: () => dispatch(appActions.finishLoading()),
});

SectorEditPage.propTypes = {
  finishLoading: PropTypes.func.isRequired,
  className: PropTypes.string.isRequired,

  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }).isRequired,
};

const StyledSectorEditPage = styled(SectorEditPage)`
  .profession-list {
    margin-bottom: 20px;
  }
`;

export default connect(
  null,
  mapDispatchToProps,
)(StyledSectorEditPage);
