import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import uuidv1 from 'uuid/v1';
import {
  Button,
  Form,
} from 'semantic-ui-react';
import { toast } from 'react-toastify';

import Utils from '../../../lib/utils';
import PortalApi from '../../../services/PortalApi';

class Disciplines extends React.PureComponent {
  ACTION_EDIT = 'edit'

  ACTION_DESTROY = 'destroy'

  ACTION_CREATE = 'create'

  state = {
    disciplines: this.props.disciplines.map(disciplines => ({
      ...disciplines,
      action: this.ACTION_EDIT,
      uuid: uuidv1(), // use as key to render
    })),
    isSaving: false,
  }

  handleChange = (uuid, newValueObj) => {
    this.setState((prevState) => {
      const newDisciplines = prevState.disciplines.map((discipline) => {
        if (uuid === discipline.uuid) {
          return {
            ...discipline,
            ...newValueObj,
          };
        }
        return { ...discipline };
      });

      return {
        disciplines: newDisciplines,
      };
    });
  }

  addItem = () => {
    this.setState(prevState => ({
      disciplines: [
        ...prevState.disciplines,
        {
          name: '',
          seq: 9999,
          programs: [],
          uuid: uuidv1(),
          action: this.ACTION_CREATE,
        },
      ]
    }))
  }

  save = async () => {
    if (this.state.disciplines.some(discipline => !discipline.name || !discipline.seq )) {
      toast.error('All disciplines must have name and sequence.', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 2000,
      });
      return;
    }

    const bodyParams = this.state.disciplines.map(discipline => ({
      action: discipline.action,
      id: discipline.id,
      seq: Number(discipline.seq),
      name: discipline.name,
    }));

    this.setState({
      isSaving: true,
    });

    try {
      await PortalApi.admin.saveDisciplines(this.props.disciplineGroupId, bodyParams);
      toast.success('Saved disciplines!', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 5000,
      });
    } catch (error) {
      if (error instanceof PortalApi.ApiError) {
        Utils.toastAdminApiError(error);
      }
    }

    this.setState({
      isSaving: false,
    });
  }

  removeItem(uuid) {
    this.setState((prevState) => {
      const disciplineToDestroy = prevState.disciplines.find(discipline => discipline.uuid === uuid);
      const newDisciplines = [...prevState.disciplines.filter(discipline => discipline.uuid !== uuid)];

      // remove a new item, so no action needed on server side
      if (!disciplineToDestroy.id) {
        return {
          disciplines: newDisciplines,
        };
      }

      newDisciplines.push({
        ...disciplineToDestroy,
        action: this.ACTION_DESTROY,
      });
      return {
        disciplines: newDisciplines,
      };
    });
  }

  render() {
    const disciplineComponents = this.state.disciplines
      .filter(discipline => discipline.action !== this.ACTION_DESTROY)
      .map((discipline) => {
      return (
        <Form.Group key={discipline.uuid}>
          <Form.Input
            width={4}
            placeholder="Discipline Name"
            value={discipline.name}
            onChange={(_, { value }) => { this.handleChange(discipline.uuid, { name: value }); }}
          />
          <Form.Input
            width={2}
            placeholder="Sequence (Ordering)"
            value={discipline.seq}
            onChange={(_, { value }) => { this.handleChange(discipline.uuid, { seq: value }); }}
            type="number"
          />
          <Form.Field width={4}>
          { discipline.action === this.ACTION_EDIT ? (
            <p>Programs: { discipline.programs.map(p => p.name).join(', ') }</p>
          ) : (
            <p>New Discipline</p>
          ) }
          </Form.Field>
          <Button
            negative
            onClick={() => { this.removeItem(discipline.uuid); }}
            type="button"
            content="Remove"
            compact
            disabled={discipline.programs.length > 0}
            title={discipline.programs.length > 0 ? "Cannot remove discipline when some programs are using it." : ""}
          />
        </Form.Group>
      )
    });

    return (
      <Form className={this.props.className}>
        { disciplineComponents }
        <Button
          positive
          onClick={this.addItem}
          type="button"
          content="Add More"
        />
        <Button
          onClick={this.save}
          loading={this.state.isSaving}
          type="submit"
          content="Save"
        />
      </Form>
    );
  }
}

Disciplines.propTypes = {
  disciplineGroupId: PropTypes.number.isRequired,
  disciplines: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    seq: PropTypes.number.isRequired,
    programs: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired).isRequired,
  })).isRequired,

  className: PropTypes.string.isRequired,
};

const StyledDisciplines = styled(Disciplines)`
.ui.button:disabled {
  pointer-events: auto !important;
}
`;

export default StyledDisciplines;
