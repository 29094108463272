import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { appActions } from '../../../actions';

import AdminPageLayout from '../layout';
import UserForm from './form';
import UserAccessForm from './user-access-form';

import PortalApi from '../../../services/PortalApi';

class UserEditPage extends React.PureComponent {
  state = {
    user: null,
  }

  async componentDidMount() {
    await this.loadSegment();
    this.props.finishLoading();
  }

  loadSegment = async () => {
    const user = await PortalApi.admin.getUser(this.props.match.params.id);
    this.setState({
      user,
    });
  }

  render() {
    const { className } = this.props;

    if (!this.state.user) {
      return null;
    }

    return (
      <div className={className}>
        <AdminPageLayout>
          <div className="main-content">
            <h1>{ this.state.user.name }</h1>
            <UserForm mode="edit" user={this.state.user} />
            <hr />
            <UserAccessForm
              userId={this.state.user.id}
              userAccesses={this.state.user.user_accesses}
            />
          </div>
        </AdminPageLayout>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  finishLoading: () => dispatch(appActions.finishLoading()),
});

UserEditPage.propTypes = {
  finishLoading: PropTypes.func.isRequired,
  className: PropTypes.string.isRequired,

  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }).isRequired,
};

const StyledUserEditPage = styled(UserEditPage)`
`;

export default connect(
  null,
  mapDispatchToProps,
)(StyledUserEditPage);
