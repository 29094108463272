import React from 'react';
import {
  Button,
  Input,
  Form,
  Dropdown,
} from 'semantic-ui-react';
import PropTypes from 'prop-types';
import uuidv1 from 'uuid/v1';

import PortalApi from '../../../services/PortalApi';
import Utils from '../../../lib/utils';

class DisciplineRelatedSectors extends React.PureComponent {
  state = {
    sectorOptions: [],
    relatedSectors: [],
    isSaving: false,
  }

  async componentDidMount() {
    await this.loadRelatedSectors();
  }

  loadRelatedSectors = async () => {
    const allSectors = await PortalApi.admin.getSectors();
    const relatedSectors = await PortalApi.admin.getDisciplineRelatedSectors(this.props.disciplineId);

    this.setState({
      sectorOptions: allSectors.map(sector => ({
        key: sector.id,
        value: sector.id,
        text: sector.name,
      })),
      relatedSectors: relatedSectors.map(sector => ({
        ...sector,
        uuid: uuidv1(),
      })),
    });
  }

  handleChange = (uuid, key, value) => {
    this.setState(prevState => ({
      relatedSectors: prevState.relatedSectors.map((sector) => {
        if (sector.uuid === uuid) {
          return {
            ...sector,
            [key]: value,
          };
        }
        return { ...sector };
      }),
    }));
  }

  remove = (uuid) => {
    this.setState(prevState => ({
      relatedSectors: prevState.relatedSectors.filter(s => s.uuid !== uuid),
    }));
  }

  add = () => {
    this.setState(prevState => ({
      relatedSectors: [
        ...prevState.relatedSectors,
        {
          id: null,
          seq: 9999,
          uuid: uuidv1(),
        },
      ],
    }));
  }

  save = async () => {
    const body = this.state.relatedSectors.map(sector => ({
      id: sector.id,
      seq: sector.seq,
    }));

    const isValid = body.every(sector => Utils.validateRequiredParams(sector, ['id', 'seq']));
    if (!isValid) {
      Utils.toastAdminGeneralError(new Error('Sector and Sequence are required'));
      return;
    }

    this.setState({
      isSaving: true,
    });

    try {
      const response = await PortalApi.admin.saveDisciplineRelatedSectors(this.props.disciplineId, body);
      Utils.toastAdminGeneralSuccess('Saved Related Sectors!');
      this.setState({
        relatedSectors: response.map(sector => ({
          id: sector.id,
          seq: sector.seq,
          uuid: uuidv1(),
        })),
      });
    } catch (error) {
      if (error instanceof PortalApi.ApiError) {
        Utils.toastAdminApiError(error);
      } else {
        Utils.toastAdminGeneralError(error);
      }
    }

    this.setState({
      isSaving: false,
    });
  }

  renderSectors = () => {
    const components = this.state.relatedSectors.map(sector => (
      <div key={sector.uuid}>
        <Form.Group>
          <Form.Field
            control={Dropdown}
            inline
            width={4}
            fluid
            options={this.state.sectorOptions}
            placeholder="Sector"
            selection
            required
            lazyLoad
            search
            value={sector.id}
            onChange={(_, { value }) => { this.handleChange(sector.uuid, 'id', value); }}
          />
          <Form.Field width={2}>
            <Input
              required
              fluid
              placeholder="Sequence (Ordering)"
              label="Sequence"
              labelPosition="left"
              onChange={(_, { value }) => { this.handleChange(sector.uuid, 'seq', value); }}
              type="number"
              value={sector.seq}
            />
          </Form.Field>
          <Button
            negative
            onClick={() => { this.remove(sector.uuid); }}
            type="button"
            content="Remove"
          />
        </Form.Group>
      </div>
    ));

    return (
      <div>
        { components }
        <Button
          positive
          onClick={this.add}
          type="button"
          content="Add More"
        />
      </div>
    );
  }

  render() {
    return (
      <Form>
        <h1>Related Sectors</h1>
        { this.renderSectors() }
        <Button
          style={{ marginTop: '10px' }}
          content="Save Related Sectors"
          type="submit"
          onClick={this.save}
          loading={this.state.isSaving}
        />
      </Form>
    );
  }
}

DisciplineRelatedSectors.propTypes = {
  disciplineId: PropTypes.number.isRequired,
};

export default DisciplineRelatedSectors;
