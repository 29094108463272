import React from 'react';
import {
  Button,
  Input,
  Form,
  Dropdown,
} from 'semantic-ui-react';
import PropTypes from 'prop-types';

import PortalApi from '../../../services/PortalApi';
import Utils from '../../../lib/utils';


class UserForm extends React.PureComponent {
  state = {
    isSaving: false,
    ...this.props.user,
    email1: (this.props.user.user_emails[0] || {}).email || '',
    email2: (this.props.user.user_emails[1] || {}).email || '',
    programOptions: [],
  }

  ACCESS_SCOPE_OPTIONS = [
    { key: 'Portal', text: 'Portal', value: 'Portal' },
    { key: 'Usage Guide', text: 'Usage Guide', value: 'Usage Guide' },
    { key: 'Positions', text: 'Positions', value: 'Positions' },
    { key: 'Employers', text: 'Employers', value: 'Employers' },
    { key: 'Discipline Groups', text: 'Discipline Groups', value: 'Discipline Groups' },
    { key: 'Disciplines', text: 'Disciplines', value: 'Disciplines' },
    { key: 'Programs', text: 'Programs', value: 'Programs' },
    { key: 'Professions', text: 'Professions', value: 'Professions' },
    { key: 'Sectors', text: 'Sectors', value: 'Sectors' },
    { key: 'Segments', text: 'Segments', value: 'Segments' },
    { key: 'Users', text: 'Users', value: 'Users' },
    { key: 'Institution', text: 'Institution', value: 'Institution' },
    { key: 'Contacts', text: 'Contacts', value: 'Contacts' },
    { key: 'Contact Tag', text: 'Contact Tag', value: 'Contact Tag' },
  ]

  async componentDidMount() {
    await this.loadSegments();
  }

  loadSegments = async () => {
    const programs = await PortalApi.admin.getPrograms();

    this.setState({
      programOptions: programs.map(program => ({
        key: program.id,
        text: `${program.name} - ${program.name}`,
        value: program.id,
      })),
    });
  }

  handleSaveClick = async () => {
    const body = {
      name: this.state.name,
      email: this.state.email1,
      secondary_email: this.state.email2,
      program_id: this.state.program_id || null,
      role: this.state.role,
      access_scope: this.state.access_scope.join(',') || '',
    };

    if (!Utils.validateRequiredParams(body, ['name', 'email', 'role'])) {
      Utils.toastAdminGeneralError(new Error('Name, Email and role are required.'));
      return;
    }

    this.setState({
      isSaving: true,
    });

    if (this.props.mode === 'create') {
      await this.create(body);
    } else if (this.props.mode === 'edit') {
      await this.edit(body);
    }

    this.setState({
      isSaving: false,
    });
  }

  create = async (body) => {
    try {
      const response = await PortalApi.admin.createUser(body);
      Utils.toastAdminGeneralSuccess(`Created user ${response.name} (id: ${response.id})`);

      this.setState({
        ...UserForm.DEFAULT_FORM_STATE,
      });
    } catch (error) {
      if (error instanceof PortalApi.ApiError) {
        Utils.toastAdminApiError(error);
      } else {
        Utils.toastAdminGeneralError(error);
      }
    }
  }

  edit = async (body) => {
    try {
      const response = await PortalApi.admin.editUser(this.props.user.id, body);
      Utils.toastAdminGeneralSuccess(`Updated user ${response.name}.`);
    } catch (error) {
      if (error instanceof PortalApi.ApiError) {
        Utils.toastAdminApiError(error);
      } else {
        Utils.toastAdminGeneralError(error);
      }
    }
  }

  render() {
    const roleOptions = [
      { key: 'student', text: 'student', value: 'student' },
      { key: 'admin', text: 'admin', value: 'admin' },
    ];
    return (
      <Form>
        <Form.Field
          control={Input}
          label="User Name"
          placeholder="User Name"
          value={this.state.name}
          onChange={(_, { value }) => { this.setState({ name: value }); }}
          required
        />
        <Form.Dropdown
          fluid
          placeholder="Role"
          label="Role"
          options={roleOptions}
          selection
          required
          lazyLoad
          search
          value={this.state.role}
          onChange={(_, { value }) => { this.setState({ role: value }); }}
        />
        <Form.Field
          control={Input}
          label="Email"
          placeholder="Email"
          value={this.state.email1}
          onChange={(_, { value }) => { this.setState({ email1: value }); }}
          required
        />
        <Form.Field
          control={Input}
          label="Secondary Email"
          placeholder="Secondary Email"
          value={this.state.email2}
          onChange={(_, { value }) => { this.setState({ email2: value }); }}
        />
        <Form.Dropdown
          fluid
          clearable
          placeholder="Program"
          label="Program"
          options={this.state.programOptions}
          selection
          lazyLoad
          search
          value={this.state.program_id}
          onChange={(_, { value }) => { this.setState({ program_id: value }); }}
        />
        <Form.Field
          control={Dropdown}
          fluid
          selection
          label="Access Scope"
          placeholder="Access Scope"
          value={this.state.access_scope.toString().split(',') || ''}
          search
          multiple
          lazyLoad
          options={this.ACCESS_SCOPE_OPTIONS}
          onChange={(_, { value }) => { this.setState({ access_scope: value }); }}
        />
        <Form.Field
          control={Button}
          content="Save"
          type="submit"
          onClick={this.handleSaveClick}
          loading={this.state.isSaving}
        />
      </Form>
    );
  }
}

UserForm.DEFAULT_FORM_STATE = {
  name: '',
  id: null,
  email1: '',
  email2: '',
  program_id: null,
  role: 'student',
  user_emails: [],
  access_scope: '',
};

UserForm.propTypes = {
  mode: PropTypes.string.isRequired,

  // can't add isRequired to child
  // but other people can https://stackoverflow.com/a/46124192
  user: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    program_id: PropTypes.number,
    role: PropTypes.string,
    user_emails: PropTypes.arrayOf(PropTypes.shape({
      user_id: PropTypes.number.isRequired,
      email: PropTypes.string.isRequired,
    }).isRequired),
    access_scope: PropTypes.string,
  }),
};

UserForm.defaultProps = {
  user: UserForm.DEFAULT_FORM_STATE,
};

export default UserForm;
