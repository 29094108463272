import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  Button,
  Input,
  Form,
  Dropdown,
} from 'semantic-ui-react';
import { toast } from 'react-toastify';
import uuidv1 from 'uuid/v1';
import PortalApi from '../../../services/PortalApi';
import Utils from '../../../lib/utils';

class ProfessionTabForm extends React.PureComponent {
  state = {
    assignments: this.props.professionTabAssignments.map(assignment => ({
      profession_tab_id: assignment.profession_tab_id,
      seq: assignment.seq,
      uuid: uuidv1(), // use as key to render
    })),
    tabOptions: [],
    newProfessionTabName: '',
    isSavingProfessionTabAssignment: false,
    isCreatingProfessionTab: false,
  }

  async componentDidMount() {
    await this.loadProfessionTabs();
  }

  loadProfessionTabs = async () => {
    const professionTabs = await PortalApi.admin.getProfessionTabs();
    this.setState({
      tabOptions: professionTabs.map(tab => ({
        value: tab.id,
        key: tab.id,
        text: tab.name,
      })),
    });
  }

  createProfessionTab = async () => {
    const name = this.state.newProfessionTabName;
    if (!name) {
      toast.error('Profession tab name cannot be blank.', {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 2000,
      });
      return;
    }

    this.setState({
      isCreatingProfessionTab: true,
    });

    const params = { name };

    const professionTab = await PortalApi.admin.createProfessionTab(params);

    toast.success(`Saved ${professionTab.name} (id: ${professionTab.id})`, {
      position: toast.POSITION.BOTTOM_CENTER,
      autoClose: 5000,
    });
    this.setState(prevState => ({
      newProfessionTabName: '',
      isCreatingProfessionTab: false,
      tabOptions: [
        ...prevState.tabOptions,
        { value: professionTab.id, key: professionTab.id, text: professionTab.name },
      ],
    }));
  }

  addProfessionTabAssignment = () => {
    this.setState(prevState => ({
      assignments: [
        ...prevState.assignments,
        { profession_tab_id: null, seq: 9999, uuid: uuidv1() },
      ],
    }));
  }

  saveProfessionTabAssignment = async () => {
    const body = this.state.assignments.map((assignment => ({
      profession_tab_id: assignment.profession_tab_id,
      seq: assignment.seq,
    })));

    this.setState({
      isSavingProfessionTabAssignment: true,
    });

    await PortalApi.admin.saveProfessionTabAssignments(this.props.professionId, body);

    this.setState({
      isSavingProfessionTabAssignment: false,
    });

    toast.success('Saved!', {
      position: toast.POSITION.BOTTOM_CENTER,
      autoClose: 5000,
    });
  }

  handleAssignmentChange = (indexToChange, newProfessionTabId, newSeq) => {
    this.setState((prevState) => {
      const newAssignments = prevState.assignments.map((assignment, index) => {
        if (indexToChange === index) {
          return {
            profession_tab_id: newProfessionTabId || assignment.profession_tab_id,
            seq: newSeq || assignment.seq,
            uuid: assignment.uuid,
          };
        }
        return { ...assignment };
      });

      return {
        assignments: newAssignments,
      };
    });
  }

  removeTabAssignment = (index) => {
    this.setState(prevState => ({
      assignments: Utils.removeItemAt(prevState.assignments, index),
    }));
  }

  renderTabs = () => {
    const tabComponents = this.state.assignments.map((assignment, index) => (
      <Form.Group key={assignment.uuid}>
        <Form.Field width={6}>
          <Dropdown
            fluid
            placeholder="Tab"
            options={this.state.tabOptions}
            selection
            required
            lazyLoad
            search
            value={assignment.profession_tab_id}
            onChange={(_, { value }) => { this.handleAssignmentChange(index, value, null); }}
          />
        </Form.Field>
        <Form.Field width={2}>
          <Input
            defaultValue={assignment.seq}
            required
            placeholder="Sequence (Ordering)"
            fluid
            type="number"
            onChange={(_, { value }) => { this.handleAssignmentChange(index, null, value); }}
          />
        </Form.Field>
        <Button
          negative
          onClick={() => { this.removeTabAssignment(index); }}
          type="button"
        >
          Remove
        </Button>
      </Form.Group>
    ));

    return (
      <Form>
        { tabComponents }
        <Form.Group>
          <Form.Field
            inline
            control={Button}
            positive
            onClick={this.addProfessionTabAssignment}
            type="button"
          >
            Add More
          </Form.Field>
          <Form.Field
            inline
            control={Button}
            onClick={this.saveProfessionTabAssignment}
            loading={this.state.isSavingProfessionTabAssignment}
            content="Save"
            type="submit"
          />
        </Form.Group>
      </Form>
    );
  }

  renderCreateProfessionTab = () => (
    <div>
      <h2>Create New Tab</h2>
      <Form>
        <Form.Group>
          <Form.Field
            inline
            width={4}
            control={Input}
            value={this.state.newProfessionTabName}
            onChange={(_, { value }) => { this.setState({ newProfessionTabName: value }); }}
          />
          <Form.Field
            inline
            control={Button}
            onClick={this.createProfessionTab}
            loading={this.state.isCreatingProfessionTab}
            content="Create"
            type="submit"
          />
        </Form.Group>
      </Form>
    </div>
  )

  render() {
    return (
      <div className={`${this.props.className} profession-tabs`}>
        <h1>Tabs</h1>
        { this.renderTabs() }
        { this.renderCreateProfessionTab() }
      </div>
    );
  }
}

ProfessionTabForm.propTypes = {
  professionId: PropTypes.number.isRequired,
  professionTabAssignments: PropTypes.arrayOf(PropTypes.shape({
    profession_tab_id: PropTypes.number.isRequired,
  }).isRequired).isRequired,

  className: PropTypes.string.isRequired,
};

const StyledProfessionTabForm = styled(ProfessionTabForm)`
`;

export default StyledProfessionTabForm;
