import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Modal,
  Button,
  Form,
  Input,
} from 'semantic-ui-react';

import PortalApi from '../../../services/PortalApi';
import Utils from '../../../lib/utils';

const ProgramDuplicateModal = ({ id }) => {
  const [name, setName] = useState('');
  const [open, setOpen] = useState(false);

  const handleDuplicateProgram = async () => {
    const body = {
      name,
    };

    try {
      const program = await PortalApi.admin.duplicateProgram(id, body);
      Utils.toastAdminGeneralSuccess(`Created ${program.name} (id: ${program.id})!`);
      setOpen(false);
    } catch (error) {
      if (error instanceof PortalApi.ApiError) {
        Utils.toastAdminApiError(error);
      } else {
        Utils.toastAdminGeneralError(error);
      }
    }
  };

  const triggerButton = (
    <Button positive floated="right" onClick={() => { setOpen(true); }}>
      Duplicate This Program
    </Button>
  );

  return (
    <Modal open={open} trigger={triggerButton} onClose={() => { setOpen(false); }}>
      <Modal.Header>Duplicate This Program To A New Program</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <p>All sectors, professions, questions will be duplicated.</p>
          <Form>
            <Form.Field
              value={name}
              control={Input}
              label="New Program Name"
              onChange={(_, { value }) => { setName(value); }}
              id="duplicate-modal-program-name"
            />
            <Form.Button
              type="button"
              content="Duplicate"
              onClick={handleDuplicateProgram}
              positive
            />
          </Form>
        </Modal.Description>
      </Modal.Content>
    </Modal>
  );
};

ProgramDuplicateModal.propTypes = {
  id: PropTypes.number.isRequired,
};

export default ProgramDuplicateModal;
