import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { appActions } from '../../../actions';

import AdminPageLayout from '../layout';
import DisciplineGroupForm from './form';
import Disciplines from './disciplines';

import PortalApi from '../../../services/PortalApi';

class DisciplineGroupEditPage extends React.PureComponent {
  state = {
    disciplineGroup: null,
  }

  async componentDidMount() {
    this.props.finishLoading();
    await this.loadDisciplineGroup();
  }

  loadDisciplineGroup = async () => {
    const disciplineGroup = await PortalApi.admin.getDisciplineGroup(this.props.match.params.id);
    this.setState({
      disciplineGroup,
    });
  }

  render() {
    const { className } = this.props;

    if (!this.state.disciplineGroup) {
      return (
        <div className={className}>
          <AdminPageLayout>loading...</AdminPageLayout>
        </div>
      );
    }

    return (
      <div className={className}>
        <AdminPageLayout>
          <div className="main-content">
            <h1>{ this.state.disciplineGroup.name }</h1>
            <DisciplineGroupForm
              mode="edit"
              disciplineGroup={this.state.disciplineGroup}
            />
            <hr />
            <h1>Disciplines</h1>
            <Disciplines
              disciplineGroupId={this.state.disciplineGroup.id}
              disciplines={this.state.disciplineGroup.disciplines}
            />
          </div>
        </AdminPageLayout>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  finishLoading: () => dispatch(appActions.finishLoading()),
});

DisciplineGroupEditPage.propTypes = {
  finishLoading: PropTypes.func.isRequired,
  className: PropTypes.string.isRequired,

  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }).isRequired,
};

const StyledDisciplineGroupEditPage = styled(DisciplineGroupEditPage)`
`;

export default connect(
  null,
  mapDispatchToProps,
)(StyledDisciplineGroupEditPage);
