import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Modal } from 'semantic-ui-react';

const AboutThisPortalModal = ({ className, close }) => (
  <Modal
    className={className}
    size="large"
    open
    closeOnDimmerClick
    onClose={close}
  >
    <iframe
      src="https://docs.google.com/document/d/e/2PACX-1vSTK_Tl5QBICNb6R3zVj28oAq0yXBmKeBq_O2uFErX1muDebRDVJG6O02QY2yj3s-yNau6GRBCMvW2q/pub?embedded=true"
      scrolling="yes"
      title="acknowledgement"
      frameBorder="0"
      height="100%"
    />
  </Modal>
);

AboutThisPortalModal.propTypes = {
  close: PropTypes.func.isRequired,
  className: PropTypes.string.isRequired,
};


const StyledAboutThisPortalModal = styled(AboutThisPortalModal)`
  width: 60% !important;
  min-height: 80%;
  overflow-y: auto;

  iframe {
    width: 100%;
    height: 100%;
    position: absolute;
  }
`;

export default StyledAboutThisPortalModal;
