import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import store from 'store';

import { appActions } from '../actions';
import config from '../config';

import LoginBanner from '../components/login-banner';
import MainApp from './main-app';

import PortalApi from '../services/PortalApi';
import Analytics from '../services/analytics';

import './styles.css';

class AppPage extends React.PureComponent {
  state = {
    loginBannerUrl: null,
  }

  async componentDidMount() {
    const { match } = this.props;
    const {
      programId,
      professionId,
    } = match.params;
    const appSettingsResponse = await PortalApi.getSettings({
      program_id: programId,
      profession_id: professionId,
    });
    this.setState({
      loginBannerUrl: appSettingsResponse.login_banner_url || config.defaultLoginBanner,
    });

    const accessToken = store.get(config.storage.keys.accessToken);
    if (!accessToken) {
      this.props.finishLoading();
      return;
    }
    await this.initializeApp();
  }

  redirectToHome = (message) => {
    let count = 3;
    const run = () => {
      this.props.setErrorModal({
        title: 'Error',
        message: `${message} Redirecting you in ${count} seconds...`,
      });
      setTimeout(() => {
        count -= 1;
        if (count > 0) {
          run();
        } else {
          window.location.href = '/';
        }
      }, 1000);
    };
    run();
  }

  initializeApp = async () => {
    const { match } = this.props;
    const {
      programId,
      professionId,
    } = match.params;
    try {
      const response = await PortalApi.getTestAccess({
        program_id: programId,
        profession_id: professionId,
      });

      if (!response.program) {
        this.redirectToHome('You don’t have access to this program.');
        return;
      }
      if (!response.profession) {
        this.redirectToHome('You don’t have access to this profession.');
        return;
      }

      this.props.setCurrentProgram(response.program);
      this.props.setCurrentProfession(response.profession);

      const uriProgram = response.program.id ? `/programs/${response.program.id}/${response.program.slug}` : '';
      const uriProfession = response.profession.id ? `/professions/${response.profession.id}/${response.profession.slug}` : '';
      const finalUri = `${uriProgram}${uriProfession}`;
      const currentUri = `/${window.location.pathname.substr(1)}`;
      if (currentUri !== finalUri) {
        const { history } = this.props;
        history.replace(finalUri);
      }
      Analytics.setUser(response.user_id);
      this.props.logIn(response.user_id, response.role);
    } catch (error) {
      // no access, will refresh
    }
  }

  render() {
    return (
      <div className="root">
        { !this.props.app.userId && (
          <LoginBanner
            loginBannerUrl={this.state.loginBannerUrl}
            initializeApp={this.initializeApp}
          />
        )}
        { this.props.app.userId && (
          <MainApp />
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  app: state.app,
});

const mapDispatchToProps = dispatch => ({
  logIn: (userId, role) => dispatch(appActions.logIn(userId, role)),
  finishLoading: () => dispatch(appActions.finishLoading()),
  setCurrentProgram: program => dispatch(appActions.setCurrentProgram(program)),
  setCurrentProfession: profession => dispatch(appActions.setCurrentProfession(profession)),
  setErrorModal: error => dispatch(appActions.setErrorModal(error)),
});

AppPage.propTypes = {
  app: PropTypes.shape({
    userId: PropTypes.number,
  }).isRequired,

  logIn: PropTypes.func.isRequired,
  finishLoading: PropTypes.func.isRequired,
  setCurrentProgram: PropTypes.func.isRequired,
  setCurrentProfession: PropTypes.func.isRequired,
  setErrorModal: PropTypes.func.isRequired,

  match: PropTypes.shape({
    params: PropTypes.shape({
      professionId: PropTypes.string,
      professionSlug: PropTypes.string,
      programId: PropTypes.string,
      programSlug: PropTypes.string,
    }),
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AppPage);
