import React from 'react';
import PropTypes from 'prop-types';

import { Field } from 'formik';

// Formik does not work with Semantic UI React Select (AFAIK, I couldn't make it to work)
// So I have to manually use the className and option tag
// This will also have to give up the "search" ability
const SelectField = ({ fieldName, options }) => (
  <Field name={fieldName} id={fieldName} as="select" className="ui search selection dropdown">
    { options.map(option => (
      <option key={option.key} value={option.value}>
        { option.text }
      </option>
    )) }
  </Field>
);

SelectField.propTypes = {
  fieldName: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
};

export default SelectField;
