import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import PortalApi from '../../../services/PortalApi';

class AdminNav extends React.PureComponent {
  state = {
    accessScope: [],
    loadingUser: true,
  }

  async componentDidMount() {
    const response = await PortalApi.getTestAccess({});
    await this.loadAccessScope(response);
  }

  loadAccessScope = async (response) => {
    const {
      access_scope: accessScope
    } = await PortalApi.admin.getUser(response.user_id);
    
    this.setState({
      loadingUser: false,
      accessScope: accessScope === "" ? this.state.accessScope : accessScope.split(",").map(function (value) {
        return value.trim();
      })
    })
  }

  render() {
    if (this.state.loadingUser === true) {
      return (
        <div style={{ paddingLeft: "10px", paddingBottom: "50px" }}></div>
      );
    } else {
      return (      
        <div style={{ paddingLeft: "10px", paddingBottom: "50px" }}>
          { (this.state.accessScope.includes('Portal') || this.state.accessScope.length===0) && (
            <span>
              <Link
                to="/"
              >
                Portal
              </Link>
              <span> | </span>
            </span>
          )}
          { (this.state.accessScope.includes('Usage Guide')  || this.state.accessScope.length===0) && (
            <span>
              <a
                rel="noopener noreferrer"
                target="_blank"
                href="https://docs.google.com/document/d/10SJe2QknBp46iNDgxyQPUCoVdiBXfm-TV48boXg-T1s/edit"
              >
                Usage Guide
              </a>
              <span> | </span>
            </span>
          )}
          { (this.state.accessScope.includes('Positions') || this.state.accessScope.length===0) && (
            <span>
              <Link
                to="/admin/positions"
              >
                Positions
              </Link>
              <span> | </span>
            </span>
          )}
          { (this.state.accessScope.includes('Employers') || this.state.accessScope.length===0) && (
            <span>
              <Link
                to="/admin/employers"
              >
                Employers
              </Link>
              <span> | </span>
            </span>
          )}
          { (this.state.accessScope.includes('Discipline Groups') || this.state.accessScope.length===0) && (
            <span>
              <Link
                to="/admin/discipline-groups"
              >
                Discipline Groups
              </Link>
              <span> | </span>
            </span>
          )}
          { (this.state.accessScope.includes('Disciplines') || this.state.accessScope.length===0) && (
            <span>
              <Link
                to="/admin/disciplines"
              >
                Disciplines
              </Link>
              <span> | </span>
            </span>
          )}
          { (this.state.accessScope.includes('Programs') || this.state.accessScope.length===0) && (
            <span>
              <Link
                to="/admin/programs"
              >
                Programs
              </Link>
              <span> | </span>
            </span>
          )}
          { (this.state.accessScope.includes('Professions') || this.state.accessScope.length===0) && (
            <span>
              <Link
                to="/admin/professions"
              >
                Professions
              </Link>
              <span> | </span>
            </span>
          )}
          { (this.state.accessScope.includes('Sectors') || this.state.accessScope.length===0) && (
            <span>
              <Link
                to="/admin/sectors"
              >
                Sectors
              </Link>
              <span> | </span>
            </span>
          )}
          { (this.state.accessScope.includes('Segments') || this.state.accessScope.length===0) && (
            <span>
              <Link
                to="/admin/segments"
              >
                Segments
              </Link>
              <span> | </span>
            </span>
          )}
          { (this.state.accessScope.includes('Users') || this.state.accessScope.length===0) && (
            <span>
              <Link
                to="/admin/users"
              >
                Users
              </Link>
              <span> | </span>
            </span>
          )}
          { (this.state.accessScope.includes('Institution') || this.state.accessScope.length===0) && (
            <span>
              <Link
                to="/admin/institutions"
              >
                Institution
              </Link>
              <span> | </span>
            </span>
          )}
          { (this.state.accessScope.includes('Contacts') || this.state.accessScope.length===0) && (
            <span>
              <Link
                to="/admin/contacts"
              >
                Contacts
              </Link>
              <span> | </span>
            </span>
          )}
          { (this.state.accessScope.includes('Contact Tags') || this.state.accessScope.length===0) && (
            <span>
              <Link
                to="/admin/contact-tags"
              >
                Contact Tags
              </Link>
              <span> | </span>
            </span>
          )}
        </div>
      );
    }
  }
}

AdminNav.propTypes = {
};

const StyledAdminNav = styled(AdminNav)`
  margin: 20px 0 50px;
  padding: 0 10px;

  a {
    display: inline-block;
  }
`;

export default StyledAdminNav;
