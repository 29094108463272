import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Modal } from 'semantic-ui-react';

import SectorModalContent from '../sector-modal-content';

import PortalApi from '../../services/PortalApi';

class SectorSegmentModal extends React.PureComponent {
  state = {
    selectedSectorId: null,
    sectors: [],
  }

  async componentDidMount() {
    await this.loadSectors();
  }

  loadSectors = async () => {
    const response = await PortalApi.getSectors();
    this.setState((prevState) => {
      const newSelectedSectorId = prevState.selectedSectorId || response[0].id;
      return {
        sectors: response,
        selectedSectorId: newSelectedSectorId,
      };
    });
  }

  changeSelectedSector = (sectorId) => {
    this.setState({
      selectedSectorId: sectorId,
    });
  }

  renderSectorColumn = () => {
    const sectorComponents = this.state.sectors.map((sector) => {
      const className = this.state.selectedSectorId === sector.id
        ? 'sector-segment-modal__sector-item sector-segment-modal__sector-item--active'
        : 'sector-segment-modal__sector-item';
      return (
        <li key={sector.id} className={className}>
          <button
            onClick={() => { this.changeSelectedSector(sector.id); }}
            className="button--no-style"
            type="button"
          >
            { sector.name }
          </button>
        </li>
      );
    });
    return (
      <div className="sector-segment-modal__sector-column">
        <ul>
          { sectorComponents }
        </ul>
      </div>
    );
  }

  render() {
    const { close, className } = this.props;
    return (
      <Modal
        className={className}
        size="fullscreen"
        open
        closeOnDimmerClick
        onClose={close}
      >
        <div className="modal--sector-segment">
          <div className="sector-segment-modal__scroll-container">
            { this.renderSectorColumn() }
            { this.state.selectedSectorId && (
              <SectorModalContent sectorId={this.state.selectedSectorId} />
            )}
          </div>
        </div>
      </Modal>
    );
  }
}

SectorSegmentModal.propTypes = {
  close: PropTypes.func.isRequired,
  className: PropTypes.string.isRequired,
};

const StyledSectorSegmentModal = styled(SectorSegmentModal)`
  width: 80% !important;

  &.ui.modal {
    display: flex !important;
  }

  * {
    color: #ffffff;
  }
  .sector-segment-modal__sector-column > h1 {
    margin: 10px;
  }
  ul {
    margin: 0;
    padding: 0;
  }

  .modal--sector-segment {
    height: 90vh;
    overflow-y: auto;
    /* min-height: 100%; */
    width: 100%;
  }
  .sector-segment-modal__scroll-container {
    display: flex;
    min-height: 100%;
  }
  .sector-segment-modal__sector-column {
    background-color: #245436;
    flex-basis: 25%;
    padding: 20px 0;
  }

  .sector-segment-modal__sector-item {
    list-style-type: none;
    line-height: 1.5;
  }
  .sector-segment-modal__sector-item button {
    padding: 0 10px;
    display: block;
    text-align: left;
    width: 100%;
  }
  .sector-segment-modal__sector-item--active button {
    background-color: #006500;
  }
`;

export default StyledSectorSegmentModal;
