import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import ProfessionTags from './profession-tags';
import ProfessionGroup from './profession-group';

class ProfessionColumn extends React.PureComponent {


  render() {
    const { className, professionsByGroupAndTag } = this.props;
    return (
      <div className={className}>
        <div className="profession-nav">
          <button
            type="button"
            className="profession-nav__button profession-nav__button--active button--no-style"
            onClick={() => {}}
          >
            <h1 className="profession-nav__text profession-nav__text--active">
              <span className="profession-nav__keyword">Profession</span>
              {' '}
              open to this major
              <span className="profession-nav__remarks-link">
                Why matter?
              </span>
            </h1>
          </button>
        </div>
        <ProfessionGroup
          selectedGroup={this.props.selectedGroup}
          changeSelectedGroup={this.props.changeSelectedGroup}
        />

        { professionsByGroupAndTag[this.props.selectedGroup] && (
          <ProfessionTags
            professionsByTag={professionsByGroupAndTag[this.props.selectedGroup]}
            selectedGroup={this.props.selectedGroup}
          />
        )}
      </div>
    );
  }
}
ProfessionColumn.propTypes = {
  professionsByGroupAndTag: PropTypes.shape({}).isRequired,
  className: PropTypes.string.isRequired,
  changeSelectedGroup: PropTypes.func.isRequired,
  selectedGroup: PropTypes.string.isRequired,
};

const StyledProfessionColumn = styled(ProfessionColumn)`
  background: #233e6b;
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 400px;

  @media only screen and (max-width: 768px) {
    width: 100%;
    display: block;
  }
`;

export default StyledProfessionColumn;
