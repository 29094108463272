import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Modal,
  Form,
  Input,
} from 'semantic-ui-react';
import debounce from 'lodash.debounce';
import styled from 'styled-components';

import PortalApi from '../../../services/PortalApi';
import EmployerForm from '../employer-edit/form';

class PositionEditModal extends React.PureComponent {
  constructor(props) {
    super(props);

    this.searchEmployers = debounce(this.searchEmployers, 300);

    this.state = {
      employerId: this.props.employer_id,
      sourceCompanyName: this.props.source_company_name,
      positionTarget: this.props.position_target,
      positionUrl: this.props.position_url,
      status: this.props.status,
      professionId: this.props.profession_id,

      employerOptions: this.props.employer
        ? this.generateEmployerOptions([this.props.employer])
        : [],
    };
  }

  handleEmployerSearchChange = (_, { searchQuery }) => {
    this.searchEmployers(searchQuery);
  }

  searchEmployers = async (searchQuery) => {
    if (!searchQuery) {
      return;
    }

    const employers = await PortalApi.admin.searchEmployers({
      name: searchQuery,
    });

    if (employers.length > 100) {
      this.setState({
        employerOptions: this.generateEmployerOptions(employers.slice(0, 100)),
      });
    } else {
      this.setState({
        employerOptions: this.generateEmployerOptions(employers),
      });
    }
  }

  save = async () => {
    const body = {
      employer_id: this.state.employerId,
      source_company_name: this.state.sourceCompanyName,
      position_target: this.state.positionTarget,
      position_url: this.state.positionUrl,
      status: this.state.status,
      profession_id: this.state.professionId,
    };

    const updatedPosition = await PortalApi.admin.patchPosition(this.props.id, body);
    this.props.close(updatedPosition);
  }

  generateEmployerOptions = employers => (
    employers.map(e => ({
      key: e.id,
      text: `${e.id}. ${e.name}`,
      value: e.id,
    }))
  )

  render() {
    const statusOptions = [
      {
        key: 'IN',
        text: 'IN',
        value: 'IN',
      },
      {
        key: 'OUT',
        text: 'OUT',
        value: 'OUT',
      },
      {
        key: 'PENDING',
        text: 'PENDING',
        value: 'PENDING',
      },
    ];

    const professionOptions = this.props.availableProfessions.map(p => ({
      key: p.id,
      text: `${p.id}. ${p.name}`,
      value: p.id,
    }));

    return (
      <Modal
        open
        className={this.props.className}
        size="fullscreen"
        onClose={this.props.close}
      >
        <Modal.Header>
          Edit Position
        </Modal.Header>
        <Modal.Content className="position-edit__content">
          <div className="position-edit__new-employer">
            <h1>Create New Employer</h1>
            <EmployerForm mode="create" />
          </div>
          <div className="position-edit__divider" />
          <div className="position-edit__edit-position">
            <h1>Edit Position</h1>
            <Form>
              <Form.Field
                control={Input}
                label="Position Target"
                placeholder="Position Target"
                value={this.state.positionTarget}
                onChange={(_, { value }) => { this.setState({ positionTarget: value }); }}
                required
                autoFocus
              />
              <Form.Field
                control={Input}
                label="Position URL"
                placeholder="Position URL"
                value={this.state.positionUrl}
                onChange={(_, { value }) => { this.setState({ positionUrl: value }); }}
                required
              />
              <Form.Field
                control={Input}
                label="Source Company Name"
                placeholder="Source Company Name"
                value={this.state.sourceCompanyName}
                onChange={(_, { value }) => { this.setState({ sourceCompanyName: value }); }}
                required
              />
              <Form.Dropdown
                label="Profession"
                placeholder="Profession"
                value={this.state.professionId}
                onChange={(_, { value }) => { this.setState({ professionId: value }); }}
                options={professionOptions}
                fluid
                selection
                clearable
                search
                lazyLoad
              />
              <Form.Dropdown
                label="Employer"
                placeholder="Employer"
                value={this.state.employerId}
                onChange={(_, { value }) => { this.setState({ employerId: value }); }}
                onSearchChange={this.handleEmployerSearchChange}
                options={this.state.employerOptions}
                fluid
                selection
                clearable
                search
                lazyLoad
              />
              <Form.Dropdown
                label="Status"
                placeholder="Status"
                value={this.state.status}
                options={statusOptions}
                fluid
                selection
                required
                onChange={(_, { value }) => { this.setState({ status: value }); }}
              />
            </Form>
          </div>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={this.props.close} negative>
            Close
          </Button>
          <Button
            onClick={this.save}
            positive
            labelPosition="right"
            icon="checkmark"
            content="Save"
          />
        </Modal.Actions>
      </Modal>
    );
  }
}

PositionEditModal.propTypes = {
  className: PropTypes.string.isRequired,

  id: PropTypes.number.isRequired,
  employer_id: PropTypes.number,
  source_company_name: PropTypes.string,
  position_target: PropTypes.string,
  position_url: PropTypes.string,
  status: PropTypes.string.isRequired,
  profession_id: PropTypes.number,
  employer: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  }),

  availableProfessions: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired).isRequired,

  close: PropTypes.func.isRequired,
};

PositionEditModal.defaultProps = {
  employer: null,
  employer_id: null,
  profession_id: null,
  source_company_name: '',
  position_target: '',
  position_url: '',
};


const StyledPositionEditModal = styled(PositionEditModal)`
.position-edit__content {
  display: flex !important;
  flex-direction: row;
}
.position-edit__new-employer {
  width: 40%;
}
.position-edit__divider {
    border-right: 1px solid #000000;
    margin-right: 1%;
    margin-left: 1%;
}
.position-edit__edit-position {
  width: 58%;
}
`;

export default StyledPositionEditModal;
