import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Modal,
  Input,
} from 'semantic-ui-react';
import { toast } from 'react-toastify';

import PortalApi from '../../../services/PortalApi';

class EmailModal extends React.PureComponent {
  state = {
    emails: '',
    message: 'loading...',
  }

  async componentDidMount() {
    await this.loadDailyEmailInfo();
  }

  loadDailyEmailInfo = async () => {
    const response = await PortalApi.admin.getDailyEmailInfo(this.props.postDate);

    const { completed: completedCount } = response.position_counts;
    const { incomplete: incompleteCount } = response.position_counts;
    const message = incompleteCount
      ? `WARNING: There are ${incompleteCount} job records not classified properly. Are you sure to send out the ${completedCount} job records?`
      : `Are you sure to send out the ${completedCount} job records?`;
    this.setState({
      emails: response.emails.join(','),
      message,
    });
  }

  send = async () => {
    await PortalApi.admin.sendDailyEmail({
      post_date: this.props.postDate,
      emails: this.state.emails.split(','),
    });
    toast.success('Sent daily emails!', {
      position: toast.POSITION.BOTTOM_CENTER,
      autoClose: 2000,
    });
    this.props.close();
  }

  render() {
    return (
      <Modal open onClose={this.props.close}>
        <Modal.Header>
          Send Daily Email
        </Modal.Header>
        <Modal.Content>
          <p>{ this.state.message }</p>
          <Input
            fluid
            value={this.state.emails}
            onChange={(_, { value }) => { this.setState({ emails: value }); }}
          />
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={this.props.close} negative>
            Close
          </Button>
          <Button
            onClick={this.send}
            positive
            labelPosition="right"
            icon="checkmark"
            content="Send"
          />
        </Modal.Actions>
      </Modal>
    );
  }
}

EmailModal.propTypes = {
  postDate: PropTypes.string.isRequired,
  close: PropTypes.func.isRequired,
};

export default EmailModal;
