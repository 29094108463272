import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import styled from 'styled-components';

import ProfessionModal from './modals/profession';
import { appActions } from '../actions';
import Utils from '../services/Utils';

// TODO change to pure function if not needed
class ProfessionTags extends React.PureComponent {
  state = {
    professionModalProfessionId: null,
  }

  closeProfessionModal = () => {
    this.setState({ professionModalProfessionId: null });
  }

  render() {
    const { className, professionsByTag } = this.props;
    const { id: programId, slug: programSlug } = this.props.app.currentProgram;
    const { id: currentProfessionId } = (this.props.app.currentProfession || {});

    const tagComponents = professionsByTag.map((tag) => {
      const professionComponents = tag.professions.map((profession) => {
        const activeClass = profession.id === currentProfessionId ? 'profession-tag__profession-box--active' : '';
        const tbaClass = profession.is_tba ? 'profession-tag__profession-link--tba' : '';

        const url = Utils.convertToUrl({
          programId,
          programSlug,
          professionId: profession.id,
          professionSlug: profession.slug,
        });
        return (
          <div
            key={profession.id}
            className={`profession-tag__profession-box ${activeClass}`}
          >
            <Link
              to={url}
              className={`profession-tag__profession-link ${tbaClass}`}
              onClick={() => {this.props.selectedGroup!=="faculty" && this.props.setCurrentProfession(profession);}}
            >
              { profession.name }
            </Link>
            <button
              type="button"
              className="profession-tag__extra-info-button button--no-style"
              onClick={() => { this.setState({ professionModalProfessionId: profession.id }); }}
            >
              ...
            </button>
          </div>
        );
      });
      return (
        <div className="profession-tag__tag" key={tag.tagName}>
          <h4 className="profession-tag__title">{ tag.tagName }</h4>
          <div className="profession-tag__profession-container">
            { professionComponents }
          </div>
        </div>
      );
    });
    return (
      <div className={`${className} profession-tag__scroll-wrapper`}>
        <div className="profession-tag__tags-container">
          { tagComponents }
        </div>

        { this.state.professionModalProfessionId && (
          <ProfessionModal
            professionId={this.state.professionModalProfessionId}
            close={this.closeProfessionModal}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  app: state.app,
});

const mapDispatchToProps = dispatch => ({
  setCurrentProfession: profession => dispatch(appActions.setCurrentProfession(profession)),
});

ProfessionTags.propTypes = {
  app: PropTypes.shape({
    currentProgram: PropTypes.shape({
      id: PropTypes.number.isRequired,
      slug: PropTypes.string.isRequired,
    }).isRequired,
    currentProfession: PropTypes.shape({
      id: PropTypes.number.isRequired,
    }),
  }).isRequired,
  setCurrentProfession: PropTypes.func.isRequired,

  professionsByTag: PropTypes.arrayOf(PropTypes.shape({
    tagName: PropTypes.string,
    professions: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    })).isRequired,
  })).isRequired,

  className: PropTypes.string.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      professionId: PropTypes.string,
      professionSlug: PropTypes.string,
      programId: PropTypes.string,
      programSlug: PropTypes.string,
    }),
  }).isRequired,

  selectedGroup: PropTypes.string.isRequired,
};

const StyledProfessionTags = styled(ProfessionTags)`
  margin-top: 20px;
  flex: 1;
  position: relative;

  .profession-tag__tags-container {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    overflow-y: auto;
  }

  @media only screen and (max-width: 768px) {
    .profession-tag__tags-container {
      position: relative;
      padding-bottom: 20px;
    }
  }

  .profession-tag__tag {
    margin-bottom: 20px;
  }

  .profession-tag__profession-box {
    padding: 2px 50px;
  }
  .profession-tag__profession-box--active {
    background-color: #4472c4;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .profession-tag__profession-box--active .profession-tag__profession-link {
    pointer-events: none;
  }
  .profession-tag__title {
    font-size: 14px;
    margin-left: 10px;
    color: #ffffff;
  }
  .profession-tag__profession-link {
    color: #ffffff;
  }
  .profession-tag__profession-link--tba {
    color: #b7b7b7;
  }
  .profession-tag__extra-info-button {
    color: #ffffff;
    margin-left: 2px;
  }
`;


export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(StyledProfessionTags));
