import React from 'react';
import {
  Button,
  Input,
  Form,
  Dropdown,
} from 'semantic-ui-react';
import PropTypes from 'prop-types';
import uuidv1 from 'uuid/v1';

import PortalApi from '../../../services/PortalApi';
import Utils from '../../../lib/utils';

class DisciplineTags extends React.PureComponent {
  state = {
    professionOptions: [],

    disciplineTags: this.props.disciplineTags.map(tag => ({
      name: tag.name,
      seq: tag.seq,
      profession_assignments: tag.discipline_tag_profession_assignments.map(assignment => ({
        profession_id: assignment.profession_id,
        seq: assignment.seq,
        uuid: uuidv1(),
      })),
      uuid: uuidv1(),
    })),

    isSaving: false,
  }

  async componentDidMount() {
    await this.loadProfessions();
  }

  loadProfessions = async () => {
    const professions = await PortalApi.admin.getProfessions();
    this.setState({
      professionOptions: professions.map(profession => ({
        key: profession.id,
        value: profession.id,
        text: profession.name,
      })),
    });
  }

  save = async () => {
    const body = this.state.disciplineTags.map(tag => ({
      name: tag.name,
      seq: tag.seq,
      profession_assignments: tag.profession_assignments.map(assignment => ({
        profession_id: assignment.profession_id,
        seq: assignment.seq,
      })),
    }));

    const isValid = body.every(tag =>
      !!tag.name &&
      !!tag.seq &&
      tag.profession_assignments.every(assignment =>
        !!assignment.profession_id &&
        !!assignment.seq
      )
    );

    if (!isValid) {
      Utils.toastAdminGeneralError(new Error('Tag name, profession and sequence are required'));
      return;
    }

    this.setState({
      isSaving: true,
    });

    try {
      await PortalApi.admin.saveDisciplineTags(this.props.disciplineId, body);
      Utils.toastAdminGeneralSuccess('Saved Discipline Tags!');
    } catch (error) {
      if (error instanceof PortalApi.ApiError) {
        Utils.toastAdminApiError(error);
      } else {
        Utils.toastAdminGeneralError(error);
      }
    }

    this.setState({
      isSaving: false,
    });
  }

  removeDisciplineTag = (uuid) => {
    this.setState(prevState => ({
      disciplineTags: [...prevState.disciplineTags.filter(tag => tag.uuid !== uuid)],
    }));
  }

  addDisciplineTag = () => {
    this.setState(prevState => ({
      disciplineTags: [
        ...prevState.disciplineTags,
        {
          name: '',
          seq: 9999,
          profession_assignments: [],
          uuid: uuidv1(),
        }
      ],
    }));
  }

  handleDisciplineTagChange = (uuid, key, value) => {
    this.setState(prevState => ({
      disciplineTags: prevState.disciplineTags.map((tag) => {
        if (tag.uuid === uuid) {
          return {
            ...tag,
            [key]: value,
          };
        }
        return { ...tag };
      }),
    }));
  }

  handleProfessionAssignmentChange = (disciplineTagUuid, assignmentUuid, key, value) => {
    this.setState(prevState => ({
      disciplineTags: [...prevState.disciplineTags.map((tag) => {
        if (tag.uuid !== disciplineTagUuid) {
          return tag;
        }
        return {
          ...tag,
          profession_assignments: tag.profession_assignments.map((assignment) => {
            if (assignment.uuid === assignmentUuid) {
              return {
                ...assignment,
                [key]: value,
              };
            }
            return assignment;
          }),
        };
      })],
    }));
  }

  removeProfessionAssignment = (disciplineTagUuid, assignmentUuid) => {
    this.setState(prevState => ({
      disciplineTags: [...prevState.disciplineTags.map((tag) => {
        if (tag.uuid !== disciplineTagUuid) {
          return tag;
        }
        return {
          ...tag,
          profession_assignments: tag.profession_assignments
            .filter(assignment => assignment.uuid !== assignmentUuid),
        };
      })],
    }));
  }

  addProfessionAssignment = (disciplineTagUuid) => {
    this.setState(prevState => ({
      disciplineTags: [...prevState.disciplineTags.map((tag) => {
        if (tag.uuid !== disciplineTagUuid) {
          return tag;
        }
        return {
          ...tag,
          profession_assignments: [
            ...tag.profession_assignments,
            {
              profession_id: null,
              seq: 9999,
              uuid: uuidv1(),
            },
          ],
        };
      })],
    }));
  }

  renderProfessionAssignments = (disciplineTagUuid, professionAssignments) => {
    const components = professionAssignments.map(assignment => (
      <Form.Group key={assignment.uuid}>
        <Form.Field width={4}>
          <Dropdown
            fluid
            required
            placeholder="Profession"
            options={this.state.professionOptions}
            selection
            lazyLoad
            search
            value={assignment.profession_id}
            onChange={(_, { value }) => { this.handleProfessionAssignmentChange(disciplineTagUuid, assignment.uuid, 'profession_id', value); }}
          />
        </Form.Field>
        <Form.Field
          control={Input}
          width={2}
          required
          placeholder="Sequence (Ordering)"
          onChange={(_, { value }) => { this.handleProfessionAssignmentChange(disciplineTagUuid, assignment.uuid, 'seq', value); }}
          type="number"
          value={assignment.seq}
        />
        <Button
          negative
          onClick={() => { this.removeProfessionAssignment(disciplineTagUuid, assignment.uuid); }}
          type="button"
          content="Remove"
        />
      </Form.Group>
    ));
    return (
      <div style={{ marginLeft: '50px' }}>
        { components }
        <Form.Group>
          <Button
            positive
            onClick={() => { this.addProfessionAssignment(disciplineTagUuid); }}
            type="button"
            content="Add More Profession"
          />
        </Form.Group>
      </div>
    );
  }

  renderDisciplineTags = () => {
    const components = this.state.disciplineTags.map(tag => (
      <div key={tag.uuid}>
        <Form.Group key={tag.uuid}>
          <Form.Field width={4}>
            <Input
              fluid
              required
              placeholder="Discipline Tag Name"
              label="Discipline Tag"
              labelPosition="left"
              onChange={(_, { value }) => { this.handleDisciplineTagChange(tag.uuid, 'name', value); }}
              value={tag.name}
            />
          </Form.Field>
          <Form.Field width={2}>
            <Input
              required
              fluid
              placeholder="Sequence (Ordering)"
              label="Sequence"
              labelPosition="left"
              onChange={(_, { value }) => { this.handleDisciplineTagChange(tag.uuid, 'seq', value); }}
              type="number"
              value={tag.seq}
            />
          </Form.Field>
          <Button
            negative
            onClick={() => { this.removeDisciplineTag(tag.uuid); }}
            type="button"
            content="Remove"
          />
        </Form.Group>
        { this.renderProfessionAssignments(tag.uuid, tag.profession_assignments) }
      </div>
    ));
    return (
      <div>
        { components }
        <Button
          positive
          onClick={this.addDisciplineTag}
          type="button"
          content="Add More Discipline Tag"
        />
      </div>
    );
  }

  render() {
    return (
      <Form>
        <h1>Discipline Tags</h1>
        { this.renderDisciplineTags() }
        <Button
          style={{ marginTop: '10px' }}
          content="Save All Discipline Tags"
          type="submit"
          onClick={this.save}
          loading={this.state.isSaving}
        />
      </Form>
    );
  }
}

DisciplineTags.propTypes = {
  disciplineId: PropTypes.number.isRequired,

  disciplineTags: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    seq: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    discipline_tag_profession_assignments: PropTypes.arrayOf(PropTypes.shape({
      profession_id: PropTypes.number.isRequired,
    }).isRequired).isRequired,
  }).isRequired).isRequired,
};

export default DisciplineTags;
