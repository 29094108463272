import React from 'react';
import {
  Button,
  Input,
  Form,
} from 'semantic-ui-react';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';

import PortalApi from '../../../services/PortalApi';
import Utils from '../../../lib/utils';

class SectorForm extends React.PureComponent {
  state = {
    isSaving: false,
    ...this.props.sector,
    availableResearchStatuses: [],
  }

  async componentDidMount() {
    await this.loadAvailableResearchStatuses();
  }

  loadAvailableResearchStatuses = async () => {
    const availableResearchStatuses = await PortalApi.admin.getAvailableResearchStatuses();
    this.setState({
      availableResearchStatuses,
    });
  }

  handleSaveClick = async () => {
    const bodyParams = {
      name: this.state.name,
      last_researched_at: this.state.last_researched_at || null,
      research_status_id: this.state.research_status_id,
      peer_reviewed_by: this.state.peer_reviewed_by || '',
      presented_to: this.state.presented_to || '',
      practitioner_review_by: this.state.practitioner_review_by || '',
      responsible_practitioners: this.state.responsible_practitioners || '',
      whatsapp_id: this.state.whatsapp_id || '',
      work_in_progress_by: this.state.work_in_progress_by || '',
    };

    this.setState({
      isSaving: true,
    });

    const toastId = toast.info('Saving...', {
      position: toast.POSITION.BOTTOM_CENTER,
    });

    let sector;
    try {
      if (this.props.mode === 'create') {
        sector = await this.createSector(bodyParams);
      } else if (this.props.mode === 'edit') {
        sector = await this.editSector(bodyParams);
      }

      Utils.toastAdminGeneralSuccess(`Saved ${sector.name} (id: ${sector.id})`);
    } catch (error) {
      if (error instanceof PortalApi.ApiError) {
        Utils.toastAdminApiError(error);
      } else {
        Utils.toastAdminGeneralError(error);
      }
    }

    toast.dismiss(toastId);

    this.setState({
      isSaving: false,
    });
  }

  createSector = async (bodyParams) => {
    const sector = await PortalApi.admin.createSector(bodyParams);
    this.setState({
      ...SectorForm.DEFAULT_FORM_STATE,
    });

    return sector;
  }

  editSector = async (bodyParams) => {
    const sector = await PortalApi.admin.editSector(this.state.id, bodyParams);
    return sector;
  }

  handleStringFieldChange = (field, event) => {
    this.setState({ [field]: event.target.value });
  }

  render() {
    const researchStatusOptions = this.state.availableResearchStatuses.map(status => ({
      key: status.id,
      text: status.status_string,
      value: status.id,
    }));

    return (
      <Form>
        <Form.Field
          control={Input}
          label="Sector Name"
          placeholder="Sector Name"
          value={this.state.name}
          onChange={(event) => { this.handleStringFieldChange('name', event); }}
          required
        />
        <Form.Field
          control={Input}
          type="date"
          label="Last Researched At"
          placeholder="Last Researched At"
          value={this.state.last_researched_at || ''}
          onChange={(_, { value }) => { this.setState({ last_researched_at: value }); }}
        />
        <Form.Dropdown
          label="Research Status"
          placeholder="Research Status"
          options={researchStatusOptions}
          selection
          search
          value={this.state.research_status_id}
          required
          onChange={(_, { value }) => { this.setState({ research_status_id: value }); }}
        />
        <Form.Field
          control={Input}
          label="Peer Review By"
          placeholder="Peer Review By"
          value={this.state.peer_reviewed_by}
          onChange={(event) => { this.handleStringFieldChange('peer_reviewed_by', event); }}
        />
        <Form.Field
          control={Input}
          label="Presented To"
          placeholder="Presented To"
          value={this.state.presented_to}
          onChange={(event) => { this.handleStringFieldChange('presented_to', event); }}
        />
        <Form.Field
          control={Input}
          label="Practitioner Review By"
          placeholder="Practitioner Review By"
          value={this.state.practitioner_review_by}
          onChange={(event) => { this.handleStringFieldChange('practitioner_review_by', event); }}
        />
        <Form.Field
          control={Input}
          label="Responsible Practitioners"
          placeholder="Responsible Practitioners"
          value={this.state.responsible_practitioners}
          onChange={(event) => { this.handleStringFieldChange('responsible_practitioners', event); }}
        />
        <Form.Field
          control={Input}
          label="Work In Progress By"
          placeholder="Work In Progress By"
          value={this.state.work_in_progress_by}
          onChange={(event) => { this.handleStringFieldChange('work_in_progress_by', event); }}
        />
        <Form.Field
          control={Button}
          content="Save"
          onClick={this.handleSaveClick}
          loading={this.state.isSaving}
        />
      </Form>
    );
  }
}

SectorForm.DEFAULT_FORM_STATE = {
  name: '',
  id: null,
  last_researched_at: '',
  research_status_id: null,
  peer_reviewed_by: '',
  presented_to: '',
  practitioner_review_by: '',
  responsible_practitioners: '',
  whatsapp_id: '',
  work_in_progress_by: '',
};

SectorForm.propTypes = {
  mode: PropTypes.string.isRequired,
  sector: PropTypes.shape({}),
};

SectorForm.defaultProps = {
  sector: SectorForm.DEFAULT_FORM_STATE,
};

export default SectorForm;
