import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Button, Confirm } from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';

import { appActions } from '../../../actions';

import AdminPageLayout from '../layout';
import SegmentForm from './form';
import ExtraInfoForm from '../components/extra-info-form/index';
import RelatedContacts from './related-contacts';

import PortalApi from '../../../services/PortalApi';
import Utils from '../../../lib/utils';

class SegmentEditPage extends React.PureComponent {
  state = {
    segment: null,
    isConfirmDeleteOpen: false,
  }

  async componentDidMount() {
    await this.loadSegment();
    this.props.finishLoading();
  }

  loadSegment = async () => {
    const segment = await PortalApi.admin.getSegment(this.props.match.params.id);
    this.setState({
      segment,
    });
  }

  deleteSegment = async () => {
    try {
      await PortalApi.admin.deleteSegment(this.state.segment.id);
      Utils.toastAdminGeneralSuccess(`Deleted segment ${this.state.segment.name}!`);
      this.props.history.push('/admin/segments');
    } catch (error) {
      if (error instanceof PortalApi.ApiError) {
        Utils.toastAdminApiError(error);
      }
    }
  }

  toggleConfirmDeleteModalOpen = (isOpen) => {
    this.setState({
      isConfirmDeleteOpen: isOpen,
    });
  }

  render() {
    const { className } = this.props;

    if (!this.state.segment) {
      return null;
    }

    return (
      <div className={className}>
        <AdminPageLayout>
          <div className="main-content">
            <Button negative floated="right" onClick={() => { this.toggleConfirmDeleteModalOpen(true); }}>
              Delete Segment
            </Button>
            <h1>{ this.state.segment.name }</h1>
            <SegmentForm mode="edit" segment={this.state.segment} />
            <hr />
            <RelatedContacts
              segmentId={this.state.segment.id}
            />
            <hr />
            <ExtraInfoForm
              extraInfoGroupId={this.state.segment.extra_info_group_id}
              extraInfos={this.state.segment.extra_infos}
            />
          </div>
        </AdminPageLayout>
        <Confirm
          open={this.state.isConfirmDeleteOpen}
          onCancel={() => { this.toggleConfirmDeleteModalOpen(false); }}
          onConfirm={this.deleteSegment}
        />
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  finishLoading: () => dispatch(appActions.finishLoading()),
});

SegmentEditPage.propTypes = {
  finishLoading: PropTypes.func.isRequired,
  className: PropTypes.string.isRequired,

  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }).isRequired,

  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

const StyledSegmentEditPage = styled(SegmentEditPage)`
  .profession-list {
    margin-bottom: 20px;
  }
`;

export default withRouter(connect(
  null,
  mapDispatchToProps,
)(StyledSegmentEditPage));
