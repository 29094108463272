import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

class ProfessionGroup extends React.PureComponent {
  render() {
    const { className } = this.props;
    return (
      <div className={className} data-active-group={this.props.selectedGroup}>
        <div className="profession-group profession-group--default">
          <button
            type="button"
            className="button--no-style profession-group__button profession-group__button--group"
            onClick={() => { this.props.changeSelectedGroup('default'); }}
          >
            Those default to this major
          </button>
        </div>
        <div className="profession-group profession-group--faculty">
          <button
            type="button"
            className="button--no-style profession-group__button profession-group__button--group"
            onClick={() => { this.props.changeSelectedGroup('faculty'); }}
          >
            Those servicing the related sectors
          </button>
        </div>
        <div className="profession-group profession-group--all_by_tags">
          <button
            type="button"
            className="button--no-style profession-group__button profession-group__button--group"
            onClick={() => { this.props.changeSelectedGroup('all_by_tags'); }}
          >
            Those open to all majors
          </button>
        </div>
      </div>
    );
  }
}

ProfessionGroup.propTypes = {
  selectedGroup: PropTypes.string.isRequired,
  changeSelectedGroup: PropTypes.func.isRequired,
  className: PropTypes.string.isRequired,
};

const StyledProfessionGroup = styled(ProfessionGroup)`
  display: flex;
  * {
    color: #ffffff;
  }
  .profession-group {
    flex: 100px 0;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }
  .profession-group--default > .profession-group__button--group,
  .profession-group--all_by_tags > .profession-group__button--group {
    flex: 1;
    display: flex;
  }
  .profession-group--faculty {
    flex: 1;
  }
  .profession-group--faculty > .profession-group__button--group {
    width: 100%;
  }
  .profession-group__button {
    text-align: left;
  }
  .profession-group--faculty > .profession-group__button {
    padding: 10px 10px 0;
  }

  &[data-active-group="default"] .profession-group--default,
  &[data-active-group="faculty"] .profession-group--faculty,
  &[data-active-group="all_by_tags"] .profession-group--all_by_tags,
  .profession-group:hover {
    background-color: #4472c4;
  }
  .profession-group__button--group {
    padding: 10px;
  }
  .profession-group__button--group:hover {
  }
`;

export default StyledProfessionGroup;
