import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { appActions } from '../../../actions';
import PortalApi from '../../../services/PortalApi';

import AdminPageLayout from '../layout';
import ContactForm from './form';

class ContactEditPage extends React.PureComponent {
  state = {
    contact: null,
  }

  async componentDidMount() {
    this.props.finishLoading();
    await this.loadContact();
  }

  loadContact = async () => {
    const contact = await PortalApi.admin.getContact(this.props.match.params.id);
    this.setState({
      contact,
    });
  }

  render() {
    const { className } = this.props;

    return (
      <div className={className}>
        <AdminPageLayout>
          <div className="main-content">
            <h1>Update Contact</h1>
            { this.state.contact && (
              <ContactForm mode="edit" id={Number(this.props.match.params.id)} contactData={this.state.contact} />
            ) }
          </div>
        </AdminPageLayout>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  finishLoading: () => dispatch(appActions.finishLoading()),
});

ContactEditPage.propTypes = {
  finishLoading: PropTypes.func.isRequired,
  className: PropTypes.string.isRequired,

  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }).isRequired,
};

const StyledContactEditPage = styled(ContactEditPage)`
`;

export default connect(
  null,
  mapDispatchToProps,
)(StyledContactEditPage);
