import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Modal } from 'semantic-ui-react';

const AboutThisPortalModal = ({ className, close }) => (
  <Modal
    className={className}
    size="large"
    open
    closeOnDimmerClick
    onClose={close}
  >
    <h1>About this portal</h1>
    {/* eslint-disable max-len */}
    <p>
      Welcome to FDMT employer altas.
    </p>

    <p>
      This portal is intended to help recent graduates recognize the diverse professions they can pursue and the respective employers of each profession.
    </p>

    <p>
      Under each profession, you will be able to explore the industry overview, leading companies in this field and career options.
    </p>

    <p>
      Employers were sourced by in-house research as well as from acknowledged public job portals across various cities in Asia and the US to date
      <br />
      • HK: JobsDB, CTgoodjobs, eFinancialCareers, Workin.hk
      <br />
      • Singapore: Contact Singapore
      <br />
      • San Francisco, New York: Dice, Glassdoor, Monster
      <br />
      • London: Indeed, reed.co.uk
    </p>

    <p>
      Everyday FDMT monitors these portals and recognizes jobs that
      <br />
      • Require less than 3 years of relevant work experience, and
      <br />
      • Require ‘Degree’, ‘Master’, ‘Undergraduate of Universities’, ‘Diploma or Degree’, ‘Tertiary Education’, and
      <br />
      • Are not related to Wealth Management or tutoring, and
      <br />
      • Hired directly by employers (non-agency companies)
    </p>

    <p>
      Among those jobs, FDMT further highlights employers that
      <br />
      • Are listed in the HKSE Main Board, or
      <br />
      • Are multinational with offices in more than 2 countries other than Hong Kong, or
      <br />
      • Have over 100 employees, or
      <br />
      • Have more than 10 years of history, or
      <br />
      • Have at least 2 clients/ project partners in the past 3 years that meet the first 2 requirements, or
      <br />
      • Belong to Professional bodies, or
      <br />
      • Have earned qualified awards in the past 3 years, or
      <br />
      • Is a money lending business listed as Existing Money Lenders Licensees under the Hong Kong Money Lenders Ordinance
    </p>

    <p>
      Our list of employers came from 30+ sectors which are further broken down to 240+ segments. Information are sorted according to company size and reputation in the field.
    </p>
    {/* eslint-enable max-len */}
  </Modal>
);

AboutThisPortalModal.propTypes = {
  close: PropTypes.func.isRequired,
  className: PropTypes.string.isRequired,
};


const StyledAboutThisPortalModal = styled(AboutThisPortalModal)`
  width: 60% !important;
  padding: 25px 15px;
  overflow-y: auto;

  p {
    line-height: 1.5;
  }
`;

export default StyledAboutThisPortalModal;
