import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Modal } from 'semantic-ui-react';

import { appActions } from '../../actions';

class ProgramModal extends React.PureComponent {
  state = {
    selectedDisciplineId: (
      ((
        this.props.disciplineGroups[0] || { disciplines: [] }
      ).disciplines[0] || {})
    ).id,
  }

  handleProgramClick = (newProgram) => {
    this.props.close();

    if (this.props.app.currentProgram.id !== newProgram.id) {
      this.props.setCurrentProgram(newProgram);
      this.props.setCurrentProfession(null);
    }
  }

  renderDisciplines = () => {
    const disciplineGroupComponents = this.props.disciplineGroups.map((disciplineGroup) => {
      const disciplineComponents = disciplineGroup.disciplines.map(discipline => (
        <button
          key={discipline.id}
          type="button"
          onClick={() => { this.setState({ selectedDisciplineId: discipline.id }); }}
          className="discipline button--no-style"
        >
          { discipline.name }
        </button>
      ));
      return (
        <details className="discipline-group" key={disciplineGroup.id}>
          <summary>{ disciplineGroup.name }</summary>
          { disciplineComponents }
        </details>
      );
    });
    return (
      <div className="program-modal__scroll-wrapper program-modal__scroll-wrapper--disciplines">
        <div>
          { this.props.app.userRole === 'admin' && (
            <Link className="admin-link" to="/admin">Admin</Link>
          ) }
          { disciplineGroupComponents }
        </div>
      </div>
    );
  }

  renderPrograms = () => {
    const { selectedDisciplineId } = this.state;
    let programs = [];
    this.props.disciplineGroups.forEach((disciplineGroup) => {
      const discipline = disciplineGroup.disciplines.find(d => d.id === selectedDisciplineId);
      if (discipline) {
        ({ programs } = discipline);
      }
    });
    const programComponents = programs.map((program) => {
      const url = `/programs/${program.id}/${program.slug}`;
      return (
        <Link
          key={program.id}
          to={url}
          className="program"
          onClick={() => { this.handleProgramClick(program); }}
        >
          <span style={{ color: 'aquamarine' }}>{ (program.institution || {}).name_short }</span>
          &nbsp;
          <span style={{ color: 'white' }}>{ program.name }</span>
        </Link>
      );
    });

    return (
      <div className="program-modal__scroll-wrapper program-modal__scroll-wrapper--programs">
        <div>
          { programComponents }
        </div>
      </div>
    );
  }

  render() {
    const { className } = this.props;
    return (
      <Modal
        className={className}
        size="large"
        open
        closeOnDimmerClick
        onClose={this.props.close}
      >
        <div className="modal--program">
          { this.renderDisciplines() }
          { this.renderPrograms() }
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = state => ({
  app: state.app,
});

const mapDispatchToProps = dispatch => ({
  setCurrentProgram: program => dispatch(appActions.setCurrentProgram(program)),
  setCurrentProfession: profession => dispatch(appActions.setCurrentProfession(profession)),
});

ProgramModal.propTypes = {
  app: PropTypes.shape({
    currentProgram: PropTypes.shape({
      id: PropTypes.number.isRequired,
    }).isRequired,
    userRole: PropTypes.string.isRequired,
  }).isRequired,
  disciplineGroups: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    disciplines: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequire,
      programs: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        slug: PropTypes.string.isRequired,
      }).isRequired).isRequired,
    }).isRequired).isRequired,
  })).isRequired,
  className: PropTypes.string.isRequired,
  setCurrentProgram: PropTypes.func.isRequired,
  setCurrentProfession: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
};

const StyledProgramModal = styled(ProgramModal)`
  width: 95% !important;
  height: 500px !important;

  &.ui.modal {
    display: flex !important;
  }

  * {
    color: #ffffff;
  }
  .modal--program {
    display: flex !important;
    flex: 1;
  }
  .program-modal__scroll-wrapper {
    display: flex;
  }

  .program-modal__scroll-wrapper--disciplines {
    width: 300px;
    background-color: #696969;
  }
  .program-modal__scroll-wrapper--programs {
    flex: 1;
    background-color: #808080;
  }

  .program-modal__scroll-wrapper > div {
    overflow-y: auto;
    width: 100%;
    margin: 20px;
  }

  .admin-link {
    display: block;
  }
  .admin-link,
  .discipline-group {
    margin-bottom: 10px;
  }
  .discipline-group summary {
    line-height: 1.5;
    cursor: pointer;
  }

  .discipline {
    display: block;
    line-height: 1.5;
    text-align: left;
    width: 100%;
    margin-left: 15px;
  }
  .discipline:hover {
    text-decoration: underline;
  }

  .program {
    display: block;
    width: 100%;
    line-height: 1.5;
  }
  .discipline-group summary:hover,
  .admin-link:hover,
  .program:hover {
    color: inherit;
    text-decoration: underline;
  }
`;

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(StyledProgramModal);
