import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Employer from './employer';
import CvCl from './cv-cl';

class ContentColumn extends React.PureComponent {
  state = {
    selectedType: 'employer',
  }

  componentDidMount() {
    //
  }

  renderContentNav = () => {
    const { selectedType } = this.state;
    const commonButtonClass = 'profession-nav__button button--no-style';
    const employerButtonClass = selectedType === 'employer'
      ? `${commonButtonClass} profession-nav__button--active`
      : commonButtonClass;

    const cvclButtonClass = selectedType === 'cv-cl'
      ? `${commonButtonClass} profession-nav__button--active`
      : commonButtonClass;
    return (
      <div className="profession-nav">
        <button
          type="button"
          className={employerButtonClass}
          onClick={() => { this.setState({ selectedType: 'employer' }); }}
        >
          <h1 className="profession-nav__text">
            <span className="profession-nav__keyword">Employers</span>
            {' '}
            hiring this profession
            <span className="profession-nav__remarks-link">
              Why matter?
            </span>
          </h1>
        </button>
        <button
          type="button"
          className={cvclButtonClass}
          onClick={() => { this.setState({ selectedType: 'cv-cl' }); }}
        >
          <h1 className="profession-nav__text profession-nav__text">
            <span className="profession-nav__keyword">CV and cover letter</span>
            {' '}
            best examples
            <span className="profession-nav__remarks-link">
              How?
            </span>
          </h1>
        </button>
      </div>
    );
  }

  render() {
    const { className } = this.props;
    return (
      <div className={className}>
        { this.renderContentNav() }
        { this.state.selectedType === 'employer' && (
          <Employer
            relatedSectors={this.props.relatedSectors}  
            selectedGroup={this.props.selectedGroup} 
          />
        )}
        { this.state.selectedType === 'cv-cl' && (
          <CvCl />
        )}
      </div>
    );
  }
}

ContentColumn.propTypes = {
  className: PropTypes.string.isRequired,
  relatedSectors: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
  })).isRequired,
  selectedGroup: PropTypes.string.isRequired,
};

const StyledContentColumn = styled(ContentColumn)`
  width: calc(100vw - 400px);
  display: flex;
  flex-direction: column;
  @media only screen and (max-width: 768px) {
    display: block;
    width: 100%;
  }
  .profession-nav .profession-nav__button {
    display: inline-block;
    vertical-align: top;
    width: auto;
  }
  @media only screen and (max-width: 768px) {
    .profession-nav .profession-nav__button {
      width: 100%;
      display: block;
    }
  }
`;

export default StyledContentColumn;
