import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Tab } from 'semantic-ui-react';

import { appActions } from '../../../actions';

import AdminPageLayout from '../layout';
import DisciplineForm from './form';
import DisciplineProfessions from './professions';

import PortalApi from '../../../services/PortalApi';
import DisciplineRelatedSectors from './related-sectors';
import DisciplineTags from './discipline-tags';

class DisciplineEditPage extends React.PureComponent {
  state = {
    discipline: null,
  }

  async componentDidMount() {
    this.props.finishLoading();
    await this.loadDiscipline();
  }

  loadDiscipline = async () => {
    const discipline = await PortalApi.admin.getDiscipline(this.props.match.params.id);
    this.setState({
      discipline,
    });
  }

  render() {
    const { className } = this.props;

    if (!this.state.discipline) {
      return (
        <div className={className}>
          <AdminPageLayout>loading...</AdminPageLayout>
        </div>
      );
    }

    const tabPanes = [
      {
        menuItem: 'Professions',
        render: () => (
          <DisciplineProfessions
            disciplineId={this.state.discipline.id}
            professionAssignments={this.state.discipline.profession_assignments}
          />
        ),
      },
      {
        menuItem: 'Discipline Tags',
        render: () => (
          <DisciplineTags
            disciplineId={this.state.discipline.id}
            disciplineTags={this.state.discipline.discipline_tags}
          />
        ),
      },
      {
        menuItem: 'Related Sectors',
        render: () => (
          <DisciplineRelatedSectors
            disciplineId={this.state.discipline.id}
          />
        ),
      },
    ];

    return (
      <div className={className}>
        <AdminPageLayout>
          <div className="main-content">
            <h1>{ this.state.discipline.name }</h1>
            <DisciplineForm
              discipline={this.state.discipline}
            />
            <hr />
            <Tab panes={tabPanes} />
          </div>
        </AdminPageLayout>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  finishLoading: () => dispatch(appActions.finishLoading()),
});

DisciplineEditPage.propTypes = {
  finishLoading: PropTypes.func.isRequired,
  className: PropTypes.string.isRequired,

  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }).isRequired,
};

const StyledDisciplineEditPage = styled(DisciplineEditPage)`
`;

export default connect(
  null,
  mapDispatchToProps,
)(StyledDisciplineEditPage);
