import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { Segment, Loader, Dimmer } from 'semantic-ui-react';

import PortalApi from '../services/PortalApi';

class CvCl extends React.PureComponent {
  state = {
    curriculumVitae: null,
    coverLetter: null,
    isLoading: true,
  }

  async componentDidMount() {
    await this.loadCvCl();
  }

  async componentDidUpdate(prevProps) {
    if (!this.props.app.currentProfession) {
      return;
    }
    if (this.props.app.currentProfession.id !== (prevProps.app.currentProfession || {}).id) {
      this.loadCvCl();
    }
  }

  loadCvCl = async () => {
    this.setState({
      isLoading: true,
    });
    const { id: professionId } = this.props.app.currentProfession;
    const response = await PortalApi.getCvCl(professionId);
    this.setState({
      curriculumVitae: response.curriculum_vitae,
      coverLetter: response.cover_letter,
      isLoading: false,
    });
  }

  render() {
    const { className } = this.props;
    const { isLoading, curriculumVitae, coverLetter } = this.state;
    return (
      <div className={`cv-cl ${className}`}>
        <Segment className="no-style" basic>
          { isLoading && (
            <Dimmer inverted active className="loading">
              <Loader />
            </Dimmer>
          )}
          <div className="cv-cl__scroll-wrapper">
            <div className="cv-cl__cv">
              <h1>CV</h1>
              {/* eslint-disable-next-line react/no-danger */}
              <div dangerouslySetInnerHTML={{ __html: curriculumVitae }} />
            </div>
            <div className="cv-cl__cl">
              <h1>Cover Letter</h1>
              {/* eslint-disable-next-line react/no-danger */}
              <div dangerouslySetInnerHTML={{ __html: coverLetter }} />
            </div>
          </div>
        </Segment>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  app: state.app,
});

CvCl.propTypes = {
  app: PropTypes.shape({
    currentProfession: PropTypes.shape({
      id: PropTypes.number.isRequired,
    }),
  }).isRequired,

  className: PropTypes.string.isRequired,
};

const StyledCvCl = styled(CvCl)`
  display: flex;
  flex-direction: column;
  flex: 1;

  h1 {
    font-size: 24px;
  }

  .ui.basic.segment.no-style {
    flex: 1;
    background-color: #4472c4;
    color: #ffffff;
    position: relative;
    height: 100%;
  }

  .cv-cl__scroll-wrapper {
    position: absolute;
    width: 100%;
    top: 0;
    bottom: 0;
    padding: 15px 20px;
    overflow-y: auto;
  }
  .cv-cl__cv {
    margin-bottom: 20px;
  }
@media only screen and (max-width: 768px) {
  .cv-cl__scroll-wrapper {
    position: relative;
  }
}
`;

export default connect(mapStateToProps)(StyledCvCl);
