import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import ReactTable from 'react-table';
import { Link } from 'react-router-dom';

import { appActions } from '../../../actions';

import AdminPageLayout from '../layout';
import SegmentForm from '../segment-edit/form';

import PortalApi from '../../../services/PortalApi';
import Utils from '../../../services/Utils';

class SegmentIndexPage extends React.PureComponent {
  state = {
    segments: [],
  }

  async componentDidMount() {
    await this.loadSectors();
    this.props.finishLoading();
  }

  loadSectors = async () => {
    const segments = await PortalApi.admin.getSegments();
    this.setState({
      segments,
    });
  }

  renderSegmentList = () => {
    const { segments } = this.state;

    const columns = [
      {
        Header: '# ID',
        accessor: row => row.id.toString(),
        id: 'id',
        width: 100,
        Cell: ({ value: id }) => (
          <Link className="edit-link" to={`/admin/segments/${id}`}>
            {id}
          </Link>
        ),
      },
      {
        Header: 'Name',
        accessor: 'name',
        width: 500,
      },
      {
        Header: 'Sector',
        id: 'sector-name',
        accessor: row => row.sector.name,
        width: 500,
        Cell: cell => (
          <Link to={`/admin/sectors/${cell.original.sector.id}`}>
            {cell.original.sector.name}
          </Link>
        ),
      },
      {
        Header: 'Last Researched At',
        accessor: 'last_researched_at',
        width: 300,
      },
      {
        Header: 'Research Status',
        id: 'research_status_string',
        accessor: row => row.research_status.status_string,
        width: 500,
      },
    ];

    return (
      <div className="segment-list">
        <ReactTable
          data={segments}
          columns={columns}
          defaultPageSize={25}
          pageSizeOptions={[10, 25, 50, 100]}
          filterable
          defaultFilterMethod={Utils.reactTableFilterInsensitive}
        />
      </div>
    );
  }

  render() {
    const { className } = this.props;

    return (
      <div className={className}>
        <AdminPageLayout>
          <div className="main-content">
            { this.renderSegmentList() }
            <hr />
            <div className="create-segment">
              <h1>Create New Segment</h1>
              <SegmentForm mode="create" />
            </div>
          </div>
        </AdminPageLayout>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  finishLoading: () => dispatch(appActions.finishLoading()),
});

SegmentIndexPage.propTypes = {
  finishLoading: PropTypes.func.isRequired,
  className: PropTypes.string.isRequired,
};

const StyledSegmentIndexPage = styled(SegmentIndexPage)`
  .profession-list {
    margin-bottom: 20px;
  }
`;

export default connect(
  null,
  mapDispatchToProps,
)(StyledSegmentIndexPage);
