import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { appActions } from '../../../actions';

import AdminPageLayout from '../layout';
import ContactTagForm from './form';

class ContactTagNewPage extends React.PureComponent {
  async componentDidMount() {
    this.props.finishLoading();
  }

  render() {
    const { className } = this.props;

    return (
      <div className={className}>
        <AdminPageLayout>
          <div className="main-content">
            <h1>Create New Contact Tag</h1>
            <ContactTagForm mode="create" />
          </div>
        </AdminPageLayout>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  finishLoading: () => dispatch(appActions.finishLoading()),
});

ContactTagNewPage.propTypes = {
  finishLoading: PropTypes.func.isRequired,
  className: PropTypes.string.isRequired,
};

const StyledContactTagNewPage = styled(ContactTagNewPage)`
`;

export default connect(
  null,
  mapDispatchToProps,
)(StyledContactTagNewPage);
