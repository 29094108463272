import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

class EmployerLocation extends React.PureComponent {
  componentDidMount() {
  }

  render() {
    const { className } = this.props;
    const locationComponents = this.props.locations.map((location) => {
      const buttonClass = location.id === this.props.selectedLocationId
        ? 'employer-location__button employer-location__button--active'
        : 'employer-location__button';
      return (
        <button
          type="button"
          onClick={() => { this.props.changeSelectedLocation(location.id); }}
          className={`button--no-style ${buttonClass}`}
          key={location.id}
        >
          {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
          { location.name } ({ location.count })
        </button>
      );
    });
    return (
      <div className={`employer-location ${className}`}>
        { locationComponents }
      </div>
    );
  }
}

EmployerLocation.propTypes = {
  locations: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    count: PropTypes.number.isRequired,
  }).isRequired).isRequired,
  changeSelectedLocation: PropTypes.func.isRequired,
  selectedLocationId: PropTypes.number,
  className: PropTypes.string.isRequired,
};

EmployerLocation.defaultProps = {
  selectedLocationId: null,
};

const StyledEmployerLocation = styled(EmployerLocation)`
  background-color: #233e6b;

  .employer-location__button {
    padding: 10px;
    color: #ffffff;
  }
  .employer-location__button--active {
    background: #4472c4;
    pointer-events: none;
  }
`;

export default StyledEmployerLocation;
