import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Analytics from '../services/analytics';

class EmployerList extends React.PureComponent {
  state = {
    isShowMore: false,
  }

  showMore = () => {
    this.setState({
      isShowMore: true,
    });
  }

  hideMore = () => {
    this.setState({
      isShowMore: false,
    });
  }

  renderEmployer = (employer) => {
    const jobExampleComponents = employer.job_examples.map(jobExample => (
      <Analytics.ReactGA.OutboundLink
        key={jobExample}
        target="_blank"
        rel="noopener noreferrer"
        to={jobExample}
        className="employer-list__job-example-link"
        eventLabel={jobExample}
      >
        (Job Example)
      </Analytics.ReactGA.OutboundLink>
    ));
    return (
      <li className="employer-list__item" key={employer.id}>
        <Analytics.ReactGA.OutboundLink
          className="employer-list__link"
          target="_blank"
          rel="noopener noreferrer"
          to={employer.company_url}
          eventLabel={employer.company_url}
        >
          { employer.name }
        </Analytics.ReactGA.OutboundLink>
        { jobExampleComponents }
      </li>
    );
  }

  render() {
    const { className } = this.props;

    const isAvailable = !!(this.props.employers.always.length + this.props.employers.more.length);
    const isDisplayShowMoreButton = !this.state.isShowMore && !!this.props.employers.more.length;

    const alwaysComponents = (
      <ul className="employer-list__list">
        { this.props.employers.always.map(employer => this.renderEmployer(employer)) }
      </ul>
    );
    const moreComponents = (
      <ul className="employer-list__list">
        { this.props.employers.more.map(employer => this.renderEmployer(employer)) }
      </ul>
    );
    return (
      <div className={className}>
        { !isAvailable && this.props.showNotAvailableText && (
          <p className="employer-list__available">Not available.</p>
        )}
        { alwaysComponents }
        { isDisplayShowMoreButton && (
          <button
            type="button"
            className="button--no-style employer-list__more-button"
            onClick={this.showMore}
          >
            Show More
          </button>
        )}
        { this.state.isShowMore && (
          <React.Fragment>
            { moreComponents }
            <button
              type="button"
              className="button--no-style employer-list__more-button"
              onClick={this.hideMore}
            >
              Show Less
            </button>
          </React.Fragment>
        )}
      </div>
    );
  }
}

EmployerList.propTypes = {
  employers: PropTypes.shape({
    always: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      company_url: PropTypes.string.isRequired,
      job_examples: PropTypes.arrayOf(PropTypes.string).isRequired,
    }).isRequired).isRequired,
    more: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      company_url: PropTypes.string.isRequired,
      job_examples: PropTypes.arrayOf(PropTypes.string).isRequired,
    }).isRequired).isRequired,
  }).isRequired,
  showNotAvailableText: PropTypes.bool,
  className: PropTypes.string.isRequired,
};

EmployerList.defaultProps = {
  showNotAvailableText: true,
};

const StyledEmployerList = styled(EmployerList)`
.employer-list__available {
  margin-left: 30px;
}
.employer-list__list {
  list-style-type: none;
  margin: 0 0 10px;
  padding-left: 30px;
}
.employer-list__item {
  line-height: 1.5;
}
.employer-list__link:hover {
  text-decoration: underline;
}
.employer-list__job-example-link {
  margin: 0 2px;
  display: inline-block;
}
.employer-list__more-button {
  font-size: 10px;
  text-decoration: underline;
}
`;

export default StyledEmployerList;
