import React from 'react';
import {
  Button,
  Input,
  Form,
  Dropdown,
} from 'semantic-ui-react';
import PropTypes from 'prop-types';
import uuidv1 from 'uuid/v1';

import PortalApi from '../../../services/PortalApi';
import Utils from '../../../lib/utils';

class ProgramTags extends React.PureComponent {
  state = {
    professionOptions: [],

    programTags: this.props.programTags.map(tag => ({
      name: tag.name,
      seq: tag.seq,
      profession_assignments: tag.program_tag_profession_assignments.map(assignment => ({
        profession_id: assignment.profession_id,
        seq: assignment.seq,
        uuid: uuidv1(),
      })),
      uuid: uuidv1(),
    })),

    isSaving: false,
    isPrefillingTags: false,
  }

  async componentDidMount() {
    await this.loadProfessions();
  }

  loadProfessions = async () => {
    const professions = await PortalApi.admin.getProfessions();
    this.setState({
      professionOptions: professions.map(profession => ({
        key: profession.id,
        value: profession.id,
        text: profession.name,
      })),
    });
  }

  /* Get template tags from selected disciplines and prefill the options */
  prefillTags = async () => {
    this.setState({
      isPrefillingTags: true,
    });
    const disciplineTags = await PortalApi.admin.getTagsOfDisciplines({
      disciplineIds: this.props.programDisciplines.map(discipline => discipline.id),
    });

    this.setState((prevState) => {
      const newProgramTags = [...prevState.programTags];

      disciplineTags.forEach((tag) => {
        const existingTag = this.state.programTags.find(obj => obj.name === tag.name);
        if (existingTag == null) { // new tag
          newProgramTags.push({
            name: tag.name,
            seq: 9999,
            profession_assignments: tag.discipline_tag_profession_assignments.map(assignment => ({
              profession_id: assignment.profession_id,
              seq: 9999,
              uuid: uuidv1(),
            })),
            uuid: uuidv1(),
          });
        } else { // for existing tag (tag with same names merge professions)
          tag.discipline_tag_profession_assignments.forEach((assignment) => {
            const idx = existingTag.profession_assignments.findIndex(a => a.profession_id === assignment.profession_id);
            if (idx === -1) { // new profession
              existingTag.profession_assignments.push({
                profession_id: assignment.profession_id,
                seq: 9999,
                uuid: uuidv1(),
              });
            }
          });
        }
      });
      return {
        programTags: newProgramTags,
      };
    });

    this.setState({
      isPrefillingTags: false,
    });
  }

  save = async () => {
    const body = this.state.programTags.map(tag => ({
      name: tag.name,
      seq: tag.seq,
      profession_assignments: tag.profession_assignments.map(assignment => ({
        profession_id: assignment.profession_id,
        seq: assignment.seq,
      })),
    }));

    const isValid = body.every(tag =>
      !!tag.name &&
      !!tag.seq &&
      tag.profession_assignments.every(assignment =>
        !!assignment.profession_id &&
        !!assignment.seq
      )
    );

    if (!isValid) {
      Utils.toastAdminGeneralError(new Error('Tag name, profession and sequence are required'));
      return;
    }

    this.setState({
      isSaving: true,
    });

    try {
      await PortalApi.admin.saveProgramTags(this.props.programId, body);
      Utils.toastAdminGeneralSuccess('Saved Program Tags!');
    } catch (error) {
      if (error instanceof PortalApi.ApiError) {
        Utils.toastAdminApiError(error);
      } else {
        Utils.toastAdminGeneralError(error);
      }
    }

    this.setState({
      isSaving: false,
    });
  }

  removeProgramTag = (uuid) => {
    this.setState(prevState => ({
      programTags: [...prevState.programTags.filter(tag => tag.uuid !== uuid)],
    }));
  }

  addProgramTag = () => {
    this.setState(prevState => ({
      programTags: [
        ...prevState.programTags,
        {
          name: '',
          seq: 9999,
          profession_assignments: [],
          uuid: uuidv1(),
        }
      ],
    }));
  }

  handleProgramTagChange = (uuid, key, value) => {
    this.setState(prevState => ({
      programTags: prevState.programTags.map((tag) => {
        if (tag.uuid === uuid) {
          return {
            ...tag,
            [key]: value,
          };
        }
        return { ...tag };
      }),
    }));
  }

  handleProfessionAssignmentChange = (programTagUuid, assignmentUuid, key, value) => {
    this.setState(prevState => ({
      programTags: [...prevState.programTags.map((tag) => {
        if (tag.uuid !== programTagUuid) {
          return tag;
        }
        return {
          ...tag,
          profession_assignments: tag.profession_assignments.map((assignment) => {
            if (assignment.uuid === assignmentUuid) {
              return {
                ...assignment,
                [key]: value,
              };
            }
            return assignment;
          }),
        };
      })],
    }));
  }

  removeProfessionAssignment = (programTagUuid, assignmentUuid) => {
    this.setState(prevState => ({
      programTags: [...prevState.programTags.map((tag) => {
        if (tag.uuid !== programTagUuid) {
          return tag;
        }
        return {
          ...tag,
          profession_assignments: tag.profession_assignments
            .filter(assignment => assignment.uuid !== assignmentUuid),
        };
      })],
    }));
  }

  addProfessionAssignment = (programTagUuid) => {
    this.setState(prevState => ({
      programTags: [...prevState.programTags.map((tag) => {
        if (tag.uuid !== programTagUuid) {
          return tag;
        }
        return {
          ...tag,
          profession_assignments: [
            ...tag.profession_assignments,
            {
              profession_id: null,
              seq: 9999,
              uuid: uuidv1(),
            },
          ],
        };
      })],
    }));
  }

  renderProfessionAssignments = (programTagUuid, professionAssignments) => {
    const components = professionAssignments.map(assignment => (
      <Form.Group key={assignment.uuid}>
        <Form.Field width={4}>
          <Dropdown
            fluid
            required
            placeholder="Profession"
            options={this.state.professionOptions}
            selection
            lazyLoad
            search
            value={assignment.profession_id}
            onChange={(_, { value }) => { this.handleProfessionAssignmentChange(programTagUuid, assignment.uuid, 'profession_id', value); }}
          />
        </Form.Field>
        <Form.Field
          control={Input}
          width={2}
          required
          placeholder="Sequence (Ordering)"
          onChange={(_, { value }) => { this.handleProfessionAssignmentChange(programTagUuid, assignment.uuid, 'seq', value); }}
          type="number"
          value={assignment.seq}
        />
        <Button
          negative
          onClick={() => { this.removeProfessionAssignment(programTagUuid, assignment.uuid); }}
          type="button"
          content="Remove"
        />
      </Form.Group>
    ));
    return (
      <div style={{ marginLeft: '50px' }}>
        { components }
        <Form.Group>
          <Button
            positive
            onClick={() => { this.addProfessionAssignment(programTagUuid); }}
            type="button"
            content="Add More Profession"
          />
        </Form.Group>
      </div>
    );
  }

  renderProgramTags = () => {
    const components = this.state.programTags.map(tag => (
      <div key={tag.uuid}>
        <Form.Group key={tag.uuid}>
          <Form.Field width={4}>
            <Input
              fluid
              required
              placeholder="Program Tag Name"
              label="Program Tag"
              labelPosition="left"
              onChange={(_, { value }) => { this.handleProgramTagChange(tag.uuid, 'name', value); }}
              value={tag.name}
            />
          </Form.Field>
          <Form.Field width={2}>
            <Input
              required
              fluid
              placeholder="Sequence (Ordering)"
              label="Sequence"
              labelPosition="left"
              onChange={(_, { value }) => { this.handleProgramTagChange(tag.uuid, 'seq', value); }}
              type="number"
              value={tag.seq}
            />
          </Form.Field>
          <Button
            negative
            onClick={() => { this.removeProgramTag(tag.uuid); }}
            type="button"
            content="Remove"
          />
        </Form.Group>
        { this.renderProfessionAssignments(tag.uuid, tag.profession_assignments) }
      </div>
    ));
    return (
      <div>
        { components }
        <Button
          positive
          onClick={this.addProgramTag}
          type="button"
          content="Add More Program Tag"
        />
      </div>
    );
  }

  render() {
    return (
      <Form>
        <h1>Program-specific Tags</h1>
        <Button
          content="Load tags from disciplines"
          type="submit"
          onClick={this.prefillTags}
          loading={this.state.isPrefillingTags}
        />
        <br />
        <br />
        { this.renderProgramTags() }
        <Button
          style={{ marginTop: '10px' }}
          content="Save All Program Tags"
          type="submit"
          onClick={this.save}
          loading={this.state.isSaving}
        />
      </Form>
    );
  }
}

ProgramTags.propTypes = {
  programId: PropTypes.number.isRequired,

  programTags: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    seq: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    program_tag_profession_assignments: PropTypes.arrayOf(PropTypes.shape({
      profession_id: PropTypes.number.isRequired,
    }).isRequired).isRequired,
  }).isRequired).isRequired,

  programDisciplines: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired).isRequired,
};

export default ProgramTags;
