import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Form, Button, Input } from 'semantic-ui-react';
import { toast } from 'react-toastify';

import { appActions } from '../../../actions';
import PortalApi from '../../../services/PortalApi';

import AdminPageLayout from '../layout';
import EmployerForm from './form';

class EmployerEditPage extends React.PureComponent {
  state = {
    employer: null,
    newAlias: '',
    locations: [],
    inLocationIds: [],
  }

  async componentDidMount() {
    await this.loadEmployer();
    await this.loadAllLocations();

    this.props.finishLoading();
  }

  loadEmployer = async () => {
    const employer = await PortalApi.admin.getEmployer(this.props.match.params.id);
    this.setState({
      employer: {
        id: employer.id,
        name: employer.name,
        segmentId: employer.segment_id,
        companyUrl: employer.company_url,
        brandName: employer.brand_name,
        showOnPortalType: employer.show_on_portal_type,
        isMnc: employer.is_mnc,
        seq: employer.seq,
        companyUrlStatus: employer.company_url_status,
      },
      aliases: employer.employer_name_aliases,
      inLocationIds: employer.employer_location_assignments
        .map(assignment => assignment.location_id),
    });
  }

  loadAllLocations = async () => {
    const locations = await PortalApi.admin.getAvailableLocations();
    this.setState({
      locations,
    });
  }

  saveLocations = async () => {
    const toastId = toast.info('Saving...', {
      position: toast.POSITION.BOTTOM_CENTER,
    });

    const params = this.state.inLocationIds;
    await PortalApi.admin.saveEmployerLocations(this.state.employer.id, params);

    toast.dismiss(toastId);
    toast.success('Saved!', {
      position: toast.POSITION.BOTTOM_CENTER,
      autoClose: 5000,
    });
  }

  removeLocation = (id) => {
    this.setState((prevState) => {
      const newIds = prevState.inLocationIds.filter(inId => inId !== id);
      return {
        inLocationIds: newIds,
      };
    });
  }

  addLocation = (id) => {
    this.setState(prevState => ({
      inLocationIds: [...prevState.inLocationIds, id],
    }));
  }

  createAlias = async () => {
    const toastId = toast.info('Saving...', {
      position: toast.POSITION.BOTTOM_CENTER,
    });

    const params = { name: this.state.newAlias };
    const newAliases = await PortalApi.admin.createEmployerAlias(this.state.employer.id, params);
    this.setState(prevState => ({
      aliases: [
        ...prevState.aliases,
        newAliases,
      ],
      newAlias: '',
    }));

    toast.dismiss(toastId);
    toast.success('Saved!', {
      position: toast.POSITION.BOTTOM_CENTER,
      autoClose: 5000,
    });
  }

  renderLocations = () => {
    const locationIdsIn = this.state.inLocationIds;
    const locationsIn = this.state.locations
      .filter(location => locationIdsIn.includes(location.id));
    const locationsOut = this.state.locations
      .filter(location => !locationIdsIn.includes(location.id));

    const componentsIn = locationsIn.map(location => (
      <li key={location.id}>
        <span>
          <Button
            negative
            compact
            onClick={() => { this.removeLocation(location.id); }}
          >
            Remove
          </Button>
        </span>
        <span> - </span>
        <span>{ location.name }</span>
      </li>
    ));

    const componentsOut = locationsOut.map(location => (
      <li key={location.id}>
        <span>
          <Button compact positive onClick={() => { this.addLocation(location.id); }}>Add</Button>
        </span>
        <span> - </span>
        <span>{ location.name }</span>
      </li>
    ));

    return (
      <div className="locations">
        <h1>Location</h1>
        <ul>
          { componentsIn }
        </ul>
        <ul>
          { componentsOut }
        </ul>

        <Form>
          <Form.Field
            control={Button}
            onClick={this.saveLocations}
            content="Save"
          />
        </Form>
      </div>
    );
  }

  renderAliases = () => {
    const { aliases } = this.state;
    const aliasComponents = aliases.map(alias => (
      <li key={alias.id}>
        { alias.name }
      </li>
    ));

    return (
      <div>
        <h1>Employer Name Aliases</h1>
        <ul>
          { aliasComponents }
        </ul>
        <Form>
          <Form.Field
            inline
            control={Input}
            value={this.state.newAlias}
            onChange={(_, { value }) => { this.setState({ newAlias: value }); }}
            placeholder="New Alias"
          />
          <Form.Field
            inline
            control={Button}
            onClick={this.createAlias}
            content="Save"
          />
        </Form>
      </div>
    );
  }

  render() {
    const { className } = this.props;

    if (!this.state.employer) {
      return (
        <div className={className}>
          <AdminPageLayout>loading...</AdminPageLayout>
        </div>
      );
    }

    return (
      <div className={className}>
        <AdminPageLayout>
          <div className="main-content">
            <h1>{ this.state.employer.name }</h1>
            <EmployerForm
              mode="edit"
              employer={this.state.employer}
            />
            <hr />
            { this.renderLocations() }
            <hr />
            { this.renderAliases() }
          </div>
        </AdminPageLayout>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  finishLoading: () => dispatch(appActions.finishLoading()),
});

EmployerEditPage.propTypes = {
  className: PropTypes.string.isRequired,

  finishLoading: PropTypes.func.isRequired,

  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }).isRequired,
};

const StyledEmployerEditPage = styled(EmployerEditPage)`
.locations ul {
  padding-top: 0;
  padding-bottom: 0;
}
.locations li {
  padding: 5px 0;
}
`;


export default connect(
  null,
  mapDispatchToProps,
)(StyledEmployerEditPage);
