import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import EmployerListWithLocalMnc from './employer-list-with-local-mnc';
import SectorModal from './modals/sector';

class EmployerSegment extends React.PureComponent {
  state = {
    showSectorModal: false,
  }

  closeSectorModal = () => {
    this.setState({
      showSectorModal: false,
    });
  }

  showSectorModal = () => {
    this.setState({
      showSectorModal: true,
    });
  }

  render() {
    const { className } = this.props;
    return (
      <div className={className}>
        <h1 className="employer__segment">{ this.props.segmentName }</h1>
        <button
          type="button"
          className="button--no-style employer__sector-button"
          onClick={this.showSectorModal}
        >
          {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
          In { this.props.sectorName } sector
        </button>
        <EmployerListWithLocalMnc
          mnc={this.props.mnc}
          local={this.props.local}
        />
        { this.state.showSectorModal && (
          <SectorModal
            sectorId={this.props.sectorId}
            close={this.closeSectorModal}
          />
        )}
      </div>
    );
  }
}

EmployerSegment.propTypes = {
  mnc: PropTypes.shape({}).isRequired,
  local: PropTypes.shape({}).isRequired,
  sectorId: PropTypes.number.isRequired,
  segmentName: PropTypes.string.isRequired,
  sectorName: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
};

const StyledEmployerSegment = styled(EmployerSegment)`
  padding: 15px 20px;
  .employer__segment {
    margin: 0;
    font-size: 24px;
  }
  .employer__sector-button {
    text-decoration: underline;
    margin-bottom: 10px;
  }
`;

export default StyledEmployerSegment;
