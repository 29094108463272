import React from 'react';
import {
  Button,
  Form,
  Input,
} from 'semantic-ui-react';
import { Formik, Field } from 'formik';
import PropTypes from 'prop-types';

import PortalApi from '../../../services/PortalApi';
import Utils from '../../../lib/utils';


class ContactTagForm extends React.PureComponent {
  handleSubmit = async (values, { setSubmitting }) => {
    setSubmitting(true);

    const params = {
      name: values.name,
    };

    try {
      if (this.props.mode === 'create') {
        await this.createContactTag(params);
      } else if (this.props.mode === 'edit') {
        await this.updateContactTag(params);
      }
    } catch (error) {
      if (error instanceof PortalApi.ApiError) {
        Utils.toastAdminApiError(error);
      } else {
        Utils.toastAdminGeneralError(error);
      }
    }

    setSubmitting(false);
  }

  createContactTag = async (params) => {
    const contactTag = await PortalApi.admin.createContactTag(params);
    Utils.toastAdminGeneralSuccess(`Created contact tag id: ${contactTag.id}`);
  }

  updateContactTag = async (params) => {
    const contactTag = await PortalApi.admin.updateContactTag(this.props.id, params);
    Utils.toastAdminGeneralSuccess(`Updated contact tag id: ${contactTag.id}`);
  }

  render() {
    return (
      <Formik
        initialValues={this.props.contactTagData}
        onSubmit={this.handleSubmit}
      >
        {({ handleSubmit, isSubmitting }) => (
          <Form>
            <Field name="name">
              {({
                field,
              }) => (
                <Form.Field
                  type="text"
                  id="name"
                  label="Name"
                  placeholder="Name"
                  control={Input}
                  required
                  fluid
                  {...field}
                />
              )}
            </Field>

            <Form.Field control={Button} type="submit" disabled={isSubmitting} loading={isSubmitting} onClick={handleSubmit} content="Save" />
          </Form>
        )}
      </Formik>
    );
  }
}

ContactTagForm.propTypes = {
  mode: PropTypes.string.isRequired,
  contactTagData: PropTypes.shape({}),
  id: PropTypes.number,
};

ContactTagForm.defaultProps = {
  contactTagData: {
    name: '',
  },
  id: null,
};

export default ContactTagForm;
