import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Image } from 'semantic-ui-react';
import * as firebase from 'firebase/app';
import store from 'store';

import { appActions } from '../actions';

import PortalApi from '../services/PortalApi';
import config from '../config';

import firebaseApp from '../lib/firebase';

class LoginBanner extends React.PureComponent {
  handleLoginBannerClick = async () => {
    const provider = new firebase.auth.GoogleAuthProvider();
    provider.setCustomParameters({ prompt: 'select_account' });
    try {
      await firebaseApp.auth().signInWithPopup(provider);
      this.props.startLoading();
      const idTokenResult = await firebaseApp.auth().currentUser.getIdTokenResult();
      const { token: googleIdToken } = idTokenResult;

      try {
        const result = await PortalApi.postToken({
          id_token: googleIdToken,
        });
        const { access_token: accessToken } = result;

        store.set(config.storage.keys.accessToken, accessToken);
        this.props.initializeApp();
      } catch (error) {
        if (error instanceof PortalApi.ApiError) {
          this.props.setErrorModal({
            title: 'Error',
            message: error.message,
          });
        }
        this.props.finishLoading();
      }
    } catch (err) {
      if (err.code !== 'auth/popup-closed-by-user' && err.code !== 'auth/cancelled-popup-request') {
        // TODO
        console.error(err);
      }
    }
  }

  render() {
    const { loginBannerUrl } = this.props;
    return (
      <React.Fragment>
        <Image
          onClick={this.handleLoginBannerClick}
          className="login__banner"
          src={loginBannerUrl}
          fluid
        />
        <style>
          {`
            .login__banner {
              cursor: pointer;
            }
          `}
        </style>
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  startLoading: () => dispatch(appActions.startLoading()),
  finishLoading: () => dispatch(appActions.finishLoading()),
  setErrorModal: error => dispatch(appActions.setErrorModal(error)),
});

LoginBanner.propTypes = {
  initializeApp: PropTypes.func.isRequired,
  startLoading: PropTypes.func.isRequired,
  finishLoading: PropTypes.func.isRequired,
  setErrorModal: PropTypes.func.isRequired,
  loginBannerUrl: PropTypes.string,
};

LoginBanner.defaultProps = {
  loginBannerUrl: null,
};

export default connect(
  null,
  mapDispatchToProps,
)(LoginBanner);
